import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import { BsQuestionCircle } from "react-icons/bs";
import { auth } from "firebase";
import { CHECK_MMS, GET_MMS } from "../query/Bonus";
import { CommaFormatted } from "../utilities";
import Loader from "../components/Loader";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  background: linear-gradient(
    to left,
    ${(props) => props.theme.subColor},
    ${(props) => props.theme.mainColor}
  );
  height: 150px;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
`;

const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  padding-bottom: 0px;
`;

const Title = styled.div`
  color: white;
  font-weight: 800;
  font-size: 18px;
  margin-right: 8px;
`;

const Touchable = styled.div`
  color: white;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const BoxRow = styled.div`
  width: 100%;
  display: flex;
  z-index: 15;
  flex-direction: row;
  padding: 12px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: white;
  border-radius: 12px;
  color: ${(props) => props.theme.darkBlueColor};
  box-shadow: ${(props) => props.theme.boxShadow};
  margin: 0px 8px;
  width: 100%;
  min-height: 80px;
`;
const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
`;

const BoxTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Figure = styled.h4`
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  width: 100%;
`;

const Suffix = styled.span`
  font-weight: 400;
  font-size: 12px;
  margin: 0px 4px;
`;
const Subtitle = styled.h4`
  font-weight: 600;
  font-size: 14px;
  color: white;
  margin-top: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  padding-top: 60px;
  align-items: center;
`;

const CBRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const DateText = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
`;
const RowName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;

const NewRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SlideUp = keyframes`
    0%{
      opacity: 0;
      transform: translate(-50%, 100%);
    }
    100%{
      opacity: 1;
      transform: translate(-50%, -150%);
    }
`;
const NotValid = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -150%);
  width: 100%;
  max-width: 400px;
  background-color: ${(props) => props.theme.errorRedColor};
  padding: 20px;
  color: white;
  text-align: center;
  animation: ${SlideUp} 0.4s ease-in;
  font-size: 16px;
  z-index: 8;
`;

const NotValidButton = styled.span`
  text-decoration: underline;
  font-weight: 800;
  margin-top: 8px;
`;
const EmptyText = styled.h4`
  ${(props) => props.theme.title};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
export default ({ history }) => {
  let date = new Date();
  const { dictionary } = useContext(LanguageStore);
  const { data, loading, error } = useQuery(GET_MMS, {
    variables: {
      id: auth().currentUser.uid,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
  });

  console.log(error);
  const checkMonth = (month) => {
    switch (parseInt(month)) {
      case 1:
        return "JANUARY";
      case 2:
        return "FEBRUARY";
      case 3:
        return "MARCH";
      case 4:
        return "APRIL";
      case 5:
        return "MAY";
      case 6:
        return "JUNE";
      case 7:
        return "JULY";
      case 8:
        return "AUGUST";
      case 9:
        return "SEPTEMBER";
      case 10:
        return "OCTOBER";
      case 11:
        return "NOVEMBER";
      default:
        return "DECEMBER";
    }
  };
  return (
    <Wrapper>
      <Header>
        <BackWrapper>
          <Back
            color="white"
            onClick={() => {
              history.goBack();
            }}
          />
        </BackWrapper>
        <TitleRow>
          <Title>{dictionary.mmsReward}</Title>
          <Touchable>
            <BsQuestionCircle size={18} />
          </Touchable>
        </TitleRow>
        <TitleRow>
          <Subtitle>{dictionary.mmsR}</Subtitle>
        </TitleRow>
        <BoxRow>
          <Box>
            <BoxTitle>{dictionary.totMain}</BoxTitle>
            <Figure>
              {!loading &&
              data &&
              data.mms_bonus_total_quantity_aggregate.aggregate.sum
                .total_quantity
                ? data.mms_bonus_total_quantity_aggregate.aggregate.sum
                    .total_quantity
                : 0}
            </Figure>
          </Box>
          <Box>
            <BoxTitle>{dictionary.thisMonthB}</BoxTitle>
            <Figure>
              {!loading &&
              data &&
              data.mms_bonus_total_quantity_aggregate.aggregate.sum
                .total_quantity
                ? data.users_by_pk.user_country.currency +
                  " " +
                  data.mms_bonus_total_quantity_aggregate.aggregate.sum.bonus
                : 0}
            </Figure>
          </Box>
        </BoxRow>
      </Header>
      <ContentWrapper>
        {loading && <Loader />}
        {!loading &&
          data &&
          (data.mms_record.length > 0 ? (
            data.mms_record.map((record, index) => {
              return (
                <CBRow key={index}>
                  <NewRow>
                    <DateText>
                      {checkMonth(record.month) + " " + record.year}
                    </DateText>
                    <DateText>{record.pv}PV * 10% =</DateText>
                  </NewRow>
                  <NewRow>
                    <RowName>x{record.total_quantity} boxes</RowName>
                    <RowName>
                      +{" "}
                      {data.users_by_pk.user_country.currency +
                        " " +
                        record.bonus}
                    </RowName>
                  </NewRow>
                </CBRow>
              );
            })
          ) : (
            <EmptyText>{dictionary.noRecord}</EmptyText>
          ))}
      </ContentWrapper>
      {!loading && data && data.mms_qualify.length === 0 && (
        <NotValid
          onClick={() => {
            history.push("/monthly_maintenance");
          }}
        >
          {dictionary.mmsRewardalert} <br />{" "}
          <NotValidButton>{dictionary.mainSalesNow}</NotValidButton>
        </NotValid>
      )}
      {!loading &&
        data &&
        data.mms_qualify.length > 0 &&
        data.mms_qualify[0].can_get !== true && (
          <NotValid
            onClick={() => {
              history.push("/monthly_maintenance");
            }}
          >
            {dictionary.mmsRewardalert}
            <br /> <NotValidButton>{dictionary.mainSalesNow}</NotValidButton>
          </NotValid>
        )}
    </Wrapper>
  );
};
