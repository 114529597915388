import React, { useState, useContext, createContext } from "react";

const ProfileStore = createContext(null);

export const ProfileProvider = (props) => {
  const context = useContext(ProfileStore);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accountSetup, setAccountSetup] = useState(false);
  const [halfcode, setHalfcode] = useState("");
  const [token, setToken] = useState("");

  const provider = {
    isLoggedIn,
    accountSetup,
    halfcode,
    token,
    setToken,
    setHalfcode,
    setAccountSetup: (setup) => {
      setAccountSetup(setup);
    },
    setIsLoggedIn: (isLoggedIn) => {
      setIsLoggedIn(isLoggedIn);
    },
  };

  return (
    <ProfileStore.Provider value={provider}>
      {props.children}
    </ProfileStore.Provider>
  );
};

export default ProfileStore;
