import React, { useContext, useState } from "react";
import { LanguageStore } from "./LanguageStore";
import { dictionaryList } from "../components/Languages";

export function LanguageProvider(props) {
  const languageContext = useContext(LanguageStore);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
    },
  };

  return (
    <LanguageStore.Provider value={provider}>
      {props.children}
    </LanguageStore.Provider>
  );
}
