import React, { useEffect, useState } from "react";
import GlobalStyles from "../styles/GlobalStyles";
import Theme from "../styles/Theme";
import styled, { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/react-hooks";
// import ApolloClient from "apollo-boost";
import AppRouter from "./Router";
import { LanguageProvider } from "../context/LanguageProvider";
import { HashRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileProvider } from "../context/ProfileContext";
import { createHttpLink, InMemoryCache, ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Menu from "./Menu";
import "react-alice-carousel/lib/alice-carousel.css";
import Loader from "./Loader";
import { auth, database } from "firebase";
const AppWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const TheApp = styled.div`
  max-width: 470px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: white;
`;

const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
    padding: 8px;
    border-radius: 12px;
    margin-top: 16px;
  }
  .Toastify__toast {
    padding: 16px 8px;
    border-radius: 12px;
    margin-top: 16px;
    overflow: hidden;
  }
  .Toastify__toast--error {
    padding: 16px 8px;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await auth().currentUser.getIdToken();
        const idTokenResult = await auth().currentUser.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];
        if (hasuraClaim) {
          localStorage.setItem("token", token);
          toast.dismiss();
          toast.success("Welcome!");
          setIsLoggedIn(true);
        } else {
          // Check if refresh is required.
          const metadataRef = database().ref(
            "metadata/" + auth().currentUser.uid + "/refreshTime"
          );

          metadataRef.on("value", async (data) => {
            if (!data.exists) {
              return;
            }
            // Force refresh to pick up the latest custom claims changes.
            let token = await auth().currentUser.getIdToken(true);
            localStorage.setItem("token", token);
            setTimeout(() => {
              toast.dismiss();
              toast.success("Welcome!");
              setIsLoggedIn(true);
            }, 200);
          });
        }
      } else {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
      }
    });
    if (window.matchMedia("(display-mode: standalone)").matches) {
      return;
    } else {
      // show add to homescreen tips
      return localStorage.setItem("pwa", "show");
    }
  }, []);

  const httpLink = createHttpLink({
    uri: "https://hushlab.herokuapp.com/v1/graphql",
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
      headers: token
        ? {
            ...headers,
            authorization: `Bearer ${token}`,
          }
        : {
            ...headers,
          },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <StyledContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          style={{
            borderRadius: "8px",
            padding: 8,
            overflow: "hidden",
          }}
        />
        <LanguageProvider>
          <ThemeProvider theme={Theme}>
            <GlobalStyles />
            <ProfileProvider>
              <Router>
                <AppWrapper>
                  <TheApp>
                    {isLoggedIn === null ? (
                      <LoadingWrapper>
                        <Loader />
                      </LoadingWrapper>
                    ) : (
                      <AppRouter isLoggedIn={isLoggedIn} />
                    )}
                  </TheApp>
                </AppWrapper>
                <Menu isLoggedIn={isLoggedIn} />
              </Router>
            </ProfileProvider>
          </ThemeProvider>
        </LanguageProvider>
      </div>
    </ApolloProvider>
  );
}

export default App;
