import React from "react";
import { BsTrash } from "react-icons/bs";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const Name = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddDropContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
`;

const AddDropButton = styled.div`
  padding: 1rem;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const Input = styled.input`
  width: 80px;
  text-align: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.lightGreyColor};
  border: none;
`;

export default ({ id, img, name, value, onChange, onAdd, onMinus }) => {
  return (
    <Container>
      <Image src={img} />
      <InfoWrapper>
        <Name>{name}</Name>
        <AddDropContainer>
          <AddDropButton
            onClick={() => {
              onChange(id, value + 1);
            }}
          >
            +
          </AddDropButton>
          <Input
            value={value}
            onChange={(e) => {
              onChange(id, e.target.value);
            }}
            type="number"
          />
          <AddDropButton
            onClick={() => {
              onChange(id, value - 1);
            }}
          >
            {value === 1 ? <BsTrash color="red" size={14} /> : "-"}
          </AddDropButton>
        </AddDropContainer>
      </InfoWrapper>
    </Container>
  );
};
