import React, { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { GET_PRODUCTS } from "../query/Gallery";
import Loader from "../components/Loader";
import { auth } from "firebase";
import Back from "../components/Back";
import Button from "../components/Button";
import ProfileStore from "../context/ProfileContext";
import ShareLink from "../components/ShareLink";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-bottom: 20vh;
`;
const BackWrapper = styled.div`
  width: 100%;
  padding: 8px 12px;
  z-index: 10;
`;
const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EmptyText = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin-top: 60px;
  width: 100%;
  text-align: center;
`;

const ProductWrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

const ShowDescription = keyframes`
    0%{
        height: 120px;
    }
    100%{
        height: 100%;
    }
`;
const ProductBox = styled.div`
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.4s;
  :hover {
    transition: 0.4s;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.6);
    div {
      animation: ${ShowDescription} 0.4s ease-in;
      height: 100%;
    }
  }
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  height: 300px;
  margin-bottom: 20px;
`;

const ProductName = styled.h1`
  ${(props) => props.theme.title};
  color: white;
  margin-bottom: 20px;
`;
const ProductDescription = styled.p`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 400;
  color: white;
  max-height: 180px;
  line-height: 22px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.6s;
  :hover {
    p {
      -webkit-line-clamp: 8;
    }
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const ButtonWrapper = styled.div`
  padding: 20px;
  z-index: 10;
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
  margin-bottom: 20px;
`;
export default ({ history, location }) => {
  const { data, loading } = useQuery(GET_PRODUCTS);
  const { isLoggedIn } = useContext(ProfileStore);
  const { dictionary } = useContext(LanguageStore);
  const [referral, setReferral] = useState(location.search.split("=")[1]);

  let existingReferral = localStorage.getItem("retailReferral");
  localStorage.setItem(
    "retailReferral",
    referral || existingReferral || auth().currentUser?.uid
  );
  return loading ? (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      {isLoggedIn && (
        <BackWrapper>
          <Back
            onClick={() => {
              history.push("/sharing");
            }}
          />
        </BackWrapper>
      )}
      <ProductWrapper>
        <Title>{dictionary.prodMenu}</Title>
        {!loading &&
          (data && data.products.length > 0 ? (
            data.products.map((product, index) => {
              return (
                <ProductBox
                  onClick={() => {
                    history.push("/product?product_id=" + product.id);
                  }}
                >
                  <ProductImg src={product.product_img} />
                  <Overlay>
                    <ProductName>{product.name}</ProductName>
                    <ProductDescription>
                      {product.description}
                    </ProductDescription>
                  </Overlay>
                </ProductBox>
              );
            })
          ) : (
            <EmptyText>No products yet :(</EmptyText>
          ))}
        {/* {!isLoggedIn && (
          <ButtonWrapper>
            <Button
              label="Sign In/Sign Up"
              onClick={() => {
                history.push("/");
              }}
            />
          </ButtonWrapper>
        )} */}
      </ProductWrapper>
      {auth().currentUser ? (
        <ShareLink
          url={window.location.href + "/?referral=" + auth().currentUser.uid}
        />
      ) : (
        <ShareLink url={window.location.href} />
      )}
    </Wrapper>
  );
};
