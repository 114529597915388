import React, { useState, useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { GET_PRODUCTS } from "../query/Packages";
import { AiFillCheckCircle } from "react-icons/ai";
import Input from "./Input";
import { auth } from "firebase";
import Loader from "./Loader";
import NewAddressForm from "./NewAddressForm";
import { LanguageStore } from "../context/LanguageStore";
import ProductAddDrop from "./ProductAddDrop/ProductAddDrop";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ProductSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-overflow-style: none;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 0px 12px;
  padding-right: 30px;
  padding-bottom: 8px;
  width: 100%;
  position: relative;
`;

const ProductScrollWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const GradientShade = styled.div`
  position: absolute;
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 0.2s;
  left: 0;
  background: ${(props) => props.bg};
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 12px;
  width: 140px;
  cursor: pointer;
  position: relative;
  box-shadow: ${(props) => props.theme.boxShadow};
  :last-child {
    margin-right: 20px;
  }
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow},
      ${(props) => props.theme.boxShadow};
  }
  background-color: ${(props) => props.theme.lightGreyColor};
  border-radius: 16px;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.darkBlueColor};
`;

const CheckWrapper = styled.div`
  color: ${(props) => props.theme.mainColor};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
const SmallText = styled.p`
  font-weight: 400;
  text-align: center;
  color: ${(props) => props.theme.darkBlueColor};
  font-size: 12px;
  margin-top: 4px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;

const OtherInfo = styled.div``;

const Title = styled.h1`
  ${(props) => props.theme.title};
  margin-top: 12px;
  margin-bottom: 6px;
`;

const Error = styled.p`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 12px;
  margin-top: 6px;
`;

const Selector = styled.select`
  padding: 16px;
  border-radius: 12px;
  font-family: ${(props) => props.theme.mainFont};
  width: 100%;
  font-size: 14px;
  border: solid 1px ${(props) => props.theme.darkBlueColor};
`;

const NewAddress = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.theme.mainShade};
  :hover {
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const ProductPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  cursor: pointer;
  position: relative;
  background-color: none;
`;
export default ({
  selectedProduct,
  changeProductQuantity,
  setProduct,
  quantity,
  addProductToList,
  setQuantity,
  address,
  setAddress,
  limit = true,
  phone,
  recipient,
  setPhone,
  addressSet,
  setAddressSet,
  setRecipient,
  shippingSet,
  setShippingSet,
  shipping,
  change,
  setShipping,
  remark,
  setRemark,
}) => {
  const gradientScroll = useRef();
  const [gradient, setGradient] = useState(`linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.9)
  )`);
  const [max, setMax] = useState(0);
  const { dictionary } = useContext(LanguageStore);
  const [newAddress, setNewAddress] = useState(false);
  const { data, loading, error, refetch } = useQuery(GET_PRODUCTS, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (gradientScroll.current) {
      document.getElementById("product-scroll").onscroll = () => {
        if (gradientScroll.current.scrollLeft < 40) {
          setGradient(`linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.9)
        )`);
        } else {
          setGradient(`linear-gradient(
          to left,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.9)
        )`);
        }
      };
    }
  });
  return (
    <Wrapper>
      <Title>{dictionary.selProduct}</Title>
      {loading && (
        <OtherInfo>
          <Loader />
        </OtherInfo>
      )}
      {!loading && data && (
        <ProductSelector>
          {data.products.length > 0 &&
            data.products.map((product, index) => {
              return (
                <ProductWrapper
                  key={index}
                  onClick={() => {
                    setMax(product.max_order_quantity);
                    addProductToList(product);
                  }}
                >
                  <img
                    style={{ borderRadius: 16, objectFit: "cover" }}
                    src={product.product_img}
                    width={140}
                    height={140}
                  />
                  {change !== null &&
                    selectedProduct.findIndex((a) => a.id === product.id) !==
                      -1 && (
                      <CheckWrapper>
                        <AiFillCheckCircle size={18} />
                      </CheckWrapper>
                    )}
                  <TextWrapper>
                    <Name>{product.name}</Name>
                    <SmallText>
                      {dictionary.maximum}: {product.max_order_quantity}
                    </SmallText>
                  </TextWrapper>
                </ProductWrapper>
              );
            })}
        </ProductSelector>
      )}
      {!loading && data && selectedProduct && (
        <OtherInfo>
          <Title>{dictionary.enterQuantity}</Title>
          {change !== null && selectedProduct.length > 0 ? (
            selectedProduct.map((pro, index) => {
              return (
                <ProductAddDrop
                  id={pro.id}
                  key={index}
                  onChange={changeProductQuantity}
                  value={pro.quantity}
                  img={pro.img}
                  name={pro.name}
                />
              );
            })
          ) : (
            <p>No selected product yet!</p>
          )}
          {limit && quantity > max && (
            <Error>{dictionary.cannotExceed + " " + max}</Error>
          )}
          <Title>{dictionary.deliveryInformation}</Title>
          <InputWrapper>
            <Input
              label={dictionary.recipientName}
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
              placeholder="Name"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.phoneNo}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              placeholder="012xxxxxxx"
            />
          </InputWrapper>
          <InputWrapper>
            <Selector
              onChange={(e) => {
                data.users_by_pk.user_addresses.map((addr, index) => {
                  if (addr.id === parseInt(e.target.value)) {
                    setAddress(e.target.value);
                    return setAddressSet(addr);
                  }
                });
              }}
            >
              {address === "default" && (
                <option value="default">{dictionary.chooseAddress}</option>
              )}
              {!loading &&
                data &&
                data.users_by_pk.user_addresses.map((addr, index) => {
                  return (
                    <option key={index} value={addr.id}>
                      {addr.name}
                    </option>
                  );
                })}
            </Selector>
            <NewAddress
              onClick={() => {
                setNewAddress(true);
              }}
            >
              {" "}
              + {dictionary.newAddress}
            </NewAddress>
            {!loading &&
              data &&
              data.users_by_pk.user_addresses.map((addr, index) => {
                if (parseInt(address) === addr.id) {
                  return (
                    <p key={index} style={{ marginTop: 8 }}>
                      {dictionary.address}: {addr.line} <br />
                      {dictionary.city}: {addr.city} <br />
                      {dictionary.state}: {addr.state} <br />
                      {dictionary.postcode}: {addr.postcode}
                    </p>
                  );
                }
              })}
          </InputWrapper>
          <InputWrapper>
            <Selector
              onChange={(e) => {
                data.users_by_pk.user_country.country_shipping_rates.map(
                  (addr, index) => {
                    if (addr.id === parseInt(e.target.value)) {
                      setShipping(e.target.value);
                      return setShippingSet(addr);
                    }
                  }
                );
              }}
            >
              <option value="default">{dictionary.chooseShippingMethod}</option>
              {data.users_by_pk.user_country.country_shipping_rates.map(
                (addr, index) => {
                  return (
                    <option key={index} value={addr.id}>
                      {addr.name} -{" "}
                      {data.users_by_pk.user_country.currency + " " + addr.rate}
                    </option>
                  );
                }
              )}
            </Selector>
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Remark"
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              placeholder="Shipping Details"
            />
          </InputWrapper>
        </OtherInfo>
      )}
      {newAddress && (
        <NewAddressForm
          refetch={refetch}
          close={() => {
            setNewAddress(false);
          }}
        />
      )}
    </Wrapper>
  );
};
