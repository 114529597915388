import React, { useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.mainFont};
  width: 100%;
`;

const Container = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`;
const Button = styled.div`
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  font-family: ${(props) => props.theme.mainFont};
  color: white;
  border-style: none;
  cursor: pointer;
  transition: 0.4s;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme.mainColor};
  :hover {
    background-color: ${(props) => props.theme.mainShade};
  }
`;
export default ({
  placeholder,
  value,
  onChange,
  type = "text",
  required = true,
  className,
}) => {
  const inputRef = useRef(null);
  return (
    <Wrapper>
      <Container
        ref={inputRef}
        className={className}
        placeholder={placeholder}
        type="file"
        required={required}
        value={value}
        onChange={onChange}
      />
      <Button
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {placeholder}
      </Button>
    </Wrapper>
  );
};
