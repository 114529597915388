import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { TESTING } from "../mutations/Users";
import { toast } from "react-toastify";
import { GET_PRODUCT_ORDERS, GET_RB_ORDERS } from "../query/Orders";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { FormatTime } from "../utilities";
import Button from "../components/Button";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 70px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoOrder = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  font-family: ${(props) => props.theme.mainFont};
`;

const BackgroundWrapper = styled.div`
  background-color: ${(props) => props.theme.lightGreyColor};
  width: 100%;
`;

const OrderWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
  cursor: pointer;
  margin-bottom: 8px;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0px;
  justify-content: space-between;
`;
const Date = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Name = styled.h1`
  color: ${(props) => props.theme.darkBlueColor};
  font-weight: 600;
  font-size: 12px;
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ProductImage = styled.img`
  border-radius: 24px;
  height: 60px;
  width: 60px;
  margin-right: 8px;
`;

const PendingText = styled.h1`
  color: ${(props) => props.theme.orangeColor};
  font-size: 12px;
  font-weight: 600;
`;
const ApprovedText = styled.h1`
  color: ${(props) => props.theme.mainColor};
  font-size: 12px;
  font-weight: 600;
`;
const CancelledText = styled.h1`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 12px;
  font-weight: 600;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: 100px;
  width: 100%;
  max-width: 470px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default ({ history }) => {
  const { data, loading, refetch } = useQuery(GET_RB_ORDERS, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });
  const { dictionary } = useContext(LanguageStore);
  const checkStatus = (status) => {
    switch (status) {
      case "Pending":
        return <PendingText>{dictionary.pending}</PendingText>;
      case "Approved":
        return <ApprovedText>{dictionary.approved}</ApprovedText>;
      case "Cancelled":
        return <CancelledText>{dictionary.cancelled}</CancelledText>;
    }
  };
  return (
    <Wrapper>
      <PageTitle title={dictionary.rbRedeemHistory} />
      <PageWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </PageWrapper>
      {loading && data === undefined && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {!loading && data && data.rb_orders.length > 0 ? (
        <BackgroundWrapper>
          {data.rb_orders.map((order, index) => {
            return (
              <OrderWrapper
                key={index}
                onClick={() => {
                  history.push("/rb_order_details?order_id=" + order.id);
                }}
              >
                <Header>
                  {checkStatus(order.status)}
                  <Date>{FormatTime(order.created_at)}</Date>
                </Header>
                <BodyWrapper>
                  {order.products ? (
                    <ProductWrapper>
                      <ProductImage
                        src={order.products.product_img}
                        height={20}
                        width={20}
                      />
                      <Name>
                        {order.products.name} x {order.quantity}
                      </Name>
                    </ProductWrapper>
                  ) : (
                    JSON.parse(order.multiproduct).map((pro, index) => (
                      <ProductWrapper key={index}>
                        <ProductImage src={pro.img} height={20} width={20} />
                        <Name>
                          {pro.name} x {pro.quantity}
                        </Name>
                      </ProductWrapper>
                    ))
                  )}
                </BodyWrapper>
              </OrderWrapper>
            );
          })}
        </BackgroundWrapper>
      ) : (
        <LoadingWrapper>
          <NoOrder>{dictionary.noRecord}</NoOrder>
        </LoadingWrapper>
      )}
      <ButtonWrapper>
        <Button
          label={dictionary.redeemV}
          onClick={() => {
            history.push("/rb_order");
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
