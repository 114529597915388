import React from "react";
import styled from "styled-components";
import { FormatTime, CommaFormatted } from "../../utilities";
import WaitingApprovalStatus from "../WaitingApprovalStatus";
import ApprovedStatus from "../ApprovedStatus";
import { withRouter } from "react-router-dom";
import CancelledStatus from "../CancelledStatus";

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
  background-color: white;
  cursor: pointer;
  margin-bottom: 12px;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
`;

const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px;
`;
const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;

export default withRouter(
  ({
    id,
    status,
    created_at,
    currency,
    package_name,
    package_chinese_name,
    quantity,
    selling_price,
    history,
    half,
    big_offer,
    system_offer,
  }) => {
    const checkStatus = () => {
      switch (status) {
        case "Pending":
          return <WaitingApprovalStatus />;
        case "Approved":
          return <ApprovedStatus />;
        default:
          return <CancelledStatus />;
      }
    };
    return (
      <Wrapper
        onClick={() => {
          history.push("/my_order?order_id=" + id);
        }}
      >
        <Header>
          <Time>{FormatTime(created_at)}</Time>
        </Header>
        <Row>
          <Left>
            <Name>
              {package_name} ({package_chinese_name})
            </Name>
            {!(half || big_offer) ? (
              <Quantity>
                {quantity} x {currency} {selling_price} ={" "}
                <Price>
                  {currency} {CommaFormatted(quantity * selling_price)}
                </Price>
              </Quantity>
            ) : (
              <Quantity>
                {quantity + system_offer} x {currency} {selling_price} ={" "}
                <Price>
                  {currency}{" "}
                  {CommaFormatted((quantity + system_offer) * selling_price)}
                </Price>
              </Quantity>
            )}
          </Left>
          <Right>{checkStatus()}</Right>
        </Row>
      </Wrapper>
    );
  }
);
