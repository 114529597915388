import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
import Input from "./Input";
import ProfileStore from "../context/ProfileContext";
import { auth, storage } from "firebase";
import Back from "../components/Back";
import BlueHead from "../components/Images/BlueHead.png";
import { useMutation } from "@apollo/react-hooks";
import AuthOutLanguage from "../components/AuthOutLanguage";
import {
  UPDATE_AVATAR,
  UPDATE_PERSONAL_INFO,
  UPDATE_BANK_INFO,
} from "../mutations/Users";
import { toast } from "react-toastify";
import Button from "../components/Button";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import AnimatedContainer from "./AnimatedContainer";
import NewAddressForm from "./NewAddressForm";
import UpdateAddressForm from "./UpdateAddressForm";
import { LanguageStore } from "../context/LanguageStore";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeSecondPassword from "./ChangeSecondPassword";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 30vh;
  z-index: 59;
`;

const SignOutButton = styled.h1`
  ${(props) => props.theme.title}
  width: 100%;
  text-align: center;
  margin-top: 12px;
  color: ${(props) => props.theme.errorRedColor};
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const Section = styled.div`
  padding: 12px 20px;
  padding-bottom: 80px;
`;

const SectionTitleWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
  background-color: white;
  position: sticky;
  top: 0;
  padding-top: 24px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-style: solid;
  border-top-width: 1px;
  border-color: ${(props) => props.theme.lightGreyColor};
`;
const SectionTitle = styled.h1`
  ${(props) => props.theme.title};
`;

const InputWrapper = styled.div`
  padding: 12px 0px;
`;

const Clickable = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.lightBlackColor};
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const AddressName = styled.p`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
`;

const AddressLine = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: left;
`;
const AddressWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 20px;
  color: ${(props) => props.theme.lightBlackColor};
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const AvatarWrapper = styled.div`
  padding: 8px;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;
const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.subColor};
  margin-right: 12px;
`;

const ChangeImageText = styled.p`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
`;

const ImgInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`;

const ButtonWrapper = styled.div``;
const Header = styled.div`
  display: flex;
  width: 100%:
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  justify-content: space-between;
`;

const NewAddress = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.theme.mainShade};
  :hover {
    color: ${(props) => props.theme.mainColor};
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;
export default ({ user, history, refetch }) => {
  const { setIsLoggedIn } = useContext(ProfileStore);
  const { dictionary } = useContext(LanguageStore);
  const imgRef = useRef(null);
  const [avatar, setAvatar] = useState();
  const [changed, setChanged] = useState(false);
  const [bankChanged, setBankChanged] = useState(false);
  const [name, setName] = useState(user.name || "");
  const [phone, setPhone] = useState(user.phone || "");
  const [wechat, setWechat] = useState(user.wechat_id || "");
  const [whatsapp, setWhatsapp] = useState(user.whatsapp || "");
  const [bankName, setBankName] = useState(user.user_banks[0].bank_name);
  const [bankAccName, setBankAccName] = useState(
    user.user_banks[0].bank_acc_name
  );
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeSecondPassword, setShowChangeSecondPassword] = useState(
    false
  );
  const [newAddressForm, setNewAddressForm] = useState(false);
  const [updateAvatar] = useMutation(UPDATE_AVATAR);
  const [updatePersonalInfo] = useMutation(UPDATE_PERSONAL_INFO);
  const [updateBank] = useMutation(UPDATE_BANK_INFO);
  const [bankAccNo, setBankAccNo] = useState(user.user_banks[0].bank_acc_no);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const saveProfile = (e) => {
    e.preventDefault();
    if (name === "") {
      return toast.error(dictionary.enterName);
    }
    if (phone === "") {
      return toast.error(dictionary.enterPhone);
    }

    toast.warning(dictionary.savingChanges, { autoClose: false });
    updatePersonalInfo({
      variables: {
        id: user.id,
        name: name,
        wechat: wechat,
        whatsapp: whatsapp,
        phone: phone,
      },
    })
      .then((e) => {
        toast.dismiss();
        refetch();
        setChanged(false);
        toast.success(dictionary.changesSaved);
      })
      .catch((e) => {
        toast.dismiss();
        toast.error(e.message);
      });
  };

  const saveBank = () => {
    if (bankName === "") {
      return toast.error(dictionary.enterBankName);
    }
    if (bankAccName === "") {
      return toast.error(dictionary.enterBankAccName);
    }
    if (bankAccNo === "") {
      return toast.error(dictionary.bankAccNo);
    }
    toast.warning(dictionary.savingChanges);
    updateBank({
      variables: {
        id: user.user_banks[0].id,
        bank_name: bankName,
        bank_acc_no: bankAccNo,
        bank_acc_name: bankAccName,
      },
    })
      .then((e) => {
        toast.dismiss();
        refetch();
        setChanged(false);
        toast.success(dictionary.changesSaved);
      })
      .catch((e) => {
        toast.dismiss();
        toast.error(e.message);
      });
  };
  return (
    <Wrapper>
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      <SectionTitleWrapper>
        <AuthOutLanguage />
        <Header>
          <SectionTitle>{dictionary.perInfo}</SectionTitle>
          {changed && (
            <ButtonWrapper>
              <Button label="Save" onClick={saveProfile} />
            </ButtonWrapper>
          )}
        </Header>
        <InputWrapper>
          <AvatarWrapper
            onClick={() => {
              imgRef.current.click();
            }}
          >
            <ImgInput
              ref={imgRef}
              type="file"
              value={avatar}
              onChange={(e) => {
                toast.warning(dictionary.uploading);
                let avatarRef = storage().ref(
                  "/avatar/" +
                    auth().currentUser.uid +
                    "/" +
                    e.target.files[0].name
                );
                let storingImg = avatarRef.put(e.target.files[0]);
                storingImg.on(
                  "state_changed",
                  (snapshot) => {},
                  (error) => {
                    toast.dismiss();
                    toast.error(error.message);
                  },
                  () => {
                    storingImg.snapshot.ref
                      .getDownloadURL()
                      .then((downloadUrl) => {
                        updateAvatar({
                          variables: {
                            id: user.id,
                            url: downloadUrl,
                          },
                        })
                          .then((e) => {
                            toast.dismiss();
                            refetch();
                            toast.success(dictionary.profileUploaded);
                          })
                          .catch((e) => {
                            toast.dismiss();
                            toast.error(e.message);
                          });
                      });
                  }
                );
              }}
            />
            <Avatar
              height={60}
              width={60}
              src={user.avatar ? user.avatar : BlueHead}
            />
            <ChangeImageText>{dictionary.changeProfilePicture}</ChangeImageText>
          </AvatarWrapper>
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.fullName}
            placeholder="Your name"
            value={name}
            onChange={(e) => {
              setChanged(true);
              setName(e.target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.userName + "**"}
            value={user.username}
            onChange={(e) => {}}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.email + "**"}
            value={user.email}
            onChange={(e) => {}}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.phoneNo}
            value={phone}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setChanged(true);
                setPhone(e.target.value);
              }
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.weChat}
            placeholder="Your wechat ID"
            value={wechat}
            onChange={(e) => {
              setChanged(true);
              setWechat(e.target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.wa}
            value={whatsapp}
            placeholder="Your whatsapp no"
            onChange={(e) => {
              setChanged(true);
              setWhatsapp(e.target.value);
            }}
          />
        </InputWrapper>
      </SectionTitleWrapper>
      <SectionTitleWrapper>
        <Header>
          <SectionTitle>{dictionary.bankD}</SectionTitle>
          {bankChanged && (
            <ButtonWrapper>
              <Button label="Save" onClick={saveBank} />
            </ButtonWrapper>
          )}
        </Header>
        <InputWrapper>
          <Input
            label={dictionary.bankName}
            value={bankName}
            onChange={(e) => {
              setBankChanged(true);
              setBankName(e.target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.bankAccNo}
            value={bankAccNo}
            onChange={(e) => {
              setBankChanged(true);
              setBankAccNo(e.target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={dictionary.bankAccName}
            value={bankAccName}
            onChange={(e) => {
              setBankChanged(true);
              setBankAccName(e.target.value);
            }}
          />
        </InputWrapper>
      </SectionTitleWrapper>
      <SectionTitleWrapper>
        <Header>
          <SectionTitle>{dictionary.addBook}</SectionTitle>
        </Header>
        {user.user_addresses.length > 0 &&
          user.user_addresses.map((address, index) => {
            return (
              <AddressWrapper
                onClick={() => {
                  setSelectedAddress(address);
                }}
              >
                <AddressName>{address.name}</AddressName>
                <AddressLine>{address.line}</AddressLine>
              </AddressWrapper>
            );
          })}
        <NewAddress
          onClick={() => {
            setNewAddressForm(true);
          }}
        >
          + {dictionary.newAddress}
        </NewAddress>
      </SectionTitleWrapper>
      <SectionTitleWrapper>
        <Header>
          <SectionTitle>{dictionary.others}</SectionTitle>
        </Header>
        <Clickable
          onClick={() => {
            setShowChangePassword(true);
          }}
        >
          {dictionary.changePassword}
        </Clickable>
        <Clickable
          onClick={() => {
            setShowChangeSecondPassword(true);
          }}
        >
          Change second password
        </Clickable>
        <Clickable
          onClick={() => {
            setShowTerms(true);
          }}
        >
          {dictionary.TnC}
        </Clickable>
        <Clickable
          onClick={() => {
            setShowPolicy(true);
          }}
        >
          {dictionary.priP}
        </Clickable>
        <SignOutButton
          onClick={() => {
            localStorage.clear();
            auth()
              .signOut()
              .then(() => {
                setIsLoggedIn(false);
              });
          }}
        >
          {dictionary.signOut}
        </SignOutButton>
      </SectionTitleWrapper>
      {showTerms && (
        <TermsAndConditions
          close={() => {
            setShowTerms(false);
          }}
        />
      )}
      {showPolicy && (
        <PrivacyPolicy
          close={() => {
            setShowPolicy(false);
          }}
        />
      )}
      {newAddressForm && (
        <NewAddressForm
          refetch={refetch}
          close={() => {
            setNewAddressForm(false);
          }}
        />
      )}
      {selectedAddress && (
        <UpdateAddressForm
          selectedAddress={selectedAddress}
          refetch={refetch}
          close={() => {
            setSelectedAddress(false);
          }}
        />
      )}
      {showChangePassword && (
        <ChangePasswordForm
          close={() => {
            setShowChangePassword(false);
          }}
        />
      )}
      {showChangeSecondPassword && (
        <ChangeSecondPassword
          close={() => {
            setShowChangeSecondPassword(false);
          }}
        />
      )}
    </Wrapper>
  );
};
