import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  max-width: 470px;
  left: 50%;
  transform: translate(-50%, 0%);
  position: fixed;
  top: 0;
  padding: 20px;
  padding-top: 30px;
  z-index: 10;
  text-align: right;
  transition: 0.4s ease-out;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.theme.darkBlueColor};
  font-family: ${(props) => props.theme.mainFont};
  font-weight: 800;
  font-size: 20px;
`;

export default ({ title, bgColor = "none" }) => {
  return <Wrapper bgColor={bgColor}>{title}</Wrapper>;
};
