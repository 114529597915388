import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import Input from "../components/Input";
import Button from "../components/Button";
import { CHECK_RETAIL_ORDER } from "../query/Orders";
import { toast } from "react-toastify";
import WaitingApprovalStatus from "../components/WaitingApprovalStatus";
import ApprovedStatus from "../components/ApprovedStatus";
import { FormatTime, CommaFormatted } from "../utilities";
import ImageUploader from "../components/ImageUploader";
import { storage } from "firebase";
import {
  UPLOAD_RETAIL_ORDER_SLIP,
  CANCEL_RETAIL_ORDER,
} from "../mutations/Orders";
import PaymentPending from "../components/PaymentPending";
import PaymentPaid from "../components/PaymentPaid";
import Back from "../components/Back";
import TrackingInfo from "../components/TrackingInfo";
import { LanguageStore } from "../context/LanguageStore";
import CancelButton from "../components/CancelButton";
import CancelledStatus from "../components/CancelledStatus";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
`;
const InputWrapper = styled.div`
  padding: 12px 0px;
`;

const Warning = styled.div`
  position: sticky;
  width: 100%;
  padding: 6px 12px;
  text-align: center;
  background-color: ${(props) => props.theme.orangeColor};
  color: white;
  top: 0;
  left: 0;
`;
const SmallText = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.orangeColor};
  font-size: 14px;
  margin-top: 8px;
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  min-height: 100vh;
  padding-bottom: 15vh;
  background-color: ${(props) => props.theme.lightGreyColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px;
  width: 50%;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
`;

const BackWrapper = styled.div`
  padding-bottom: 20px;
  width: 100%;
`;
const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;
const Container = styled.div`
  margin-bottom: 8px;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const DetailsWrapper = styled.div`
  padding: 12px 0px;
  padding-bottom: 0px;
`;

const Slip = styled.img`
  object-fit: contain;
`;

const FirstContainer = styled.div`
  margin-bottom: 8px;
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0px;
`;
export default ({ history, location }) => {
  const { dictionary } = useContext(LanguageStore);
  const [oid, setOid] = useState(location.search.split("=")[1] || "");
  const [skip, setSkip] = useState(false);
  let order;
  const [updateSlip] = useMutation(UPLOAD_RETAIL_ORDER_SLIP);
  const [cancelOrder] = useMutation(CANCEL_RETAIL_ORDER);
  const [checkRetailOrder, { data, loading, refetch }] = useLazyQuery(
    CHECK_RETAIL_ORDER
  );

  if (data && data.retail_order.length > 0) {
    order = data.retail_order[0];
  }
  const checkStatus = () => {
    switch (data.retail_order[0].status) {
      case "Pending":
        return <WaitingApprovalStatus />;
      case "Approved":
        return <ApprovedStatus />;
      default:
        return <CancelledStatus />;
    }
  };

  const checkPayment = () => {
    switch (data.retail_order[0].payment_slip) {
      case null:
        return <PaymentPending />;
      default:
        return <PaymentPaid />;
    }
  };
  const submitSlip = (e) => {
    toast.warning("Uploading slip..");
    let imgRef = storage().ref(
      "payment_slip/public/retail_order/" + order.id + "/slip.png"
    );
    let storingImg = imgRef.put(e.target.files[0]);
    storingImg.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      () => {
        storingImg.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          updateSlip({
            variables: {
              id: order.id,
              url: downloadUrl,
            },
          })
            .then(() => {
              toast.dismiss();
              refetch();
              toast.success("Payment Slip Updated!");
            })
            .catch((e) => {
              toast.dismiss();
              toast.error(e.message);
            });
        });
      }
    );
  };

  const _cancelOrder = () => {
    toast.warning("Cancelling order...", { autoClose: false });
    cancelOrder({
      variables: {
        id: oid,
      },
    })
      .then(() => {
        refetch().then(() => {
          toast.dismiss();
          toast.success("Order cancelled!");
        });
      })
      .catch((e) => {
        toast.dismiss();
        toast.error(e.message);
      });
  };
  useEffect(() => {
    return checkRetailOrder({
      variables: {
        id: oid,
      },
    });
  }, [skip]);
  return (
    <Wrapper>
      <Warning>
        Add this link to your Home Screen to prevent losing your tracking id!
      </Warning>
      <PageWrapper>
        <FirstContainer>
          <BackWrapper>
            <Back
              onClick={() => {
                history.push("/");
              }}
            />
          </BackWrapper>
          <Title>Tracking</Title>
          <InputWrapper>
            <Input
              value={oid}
              label="Order ID:"
              placeholder="111"
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  return setOid(e.target.value);
                }
              }}
            />
            <SmallText>
              **Your order id is used to keep track of your order!
            </SmallText>
          </InputWrapper>
          <InputWrapper>
            <Button
              label="Check Order"
              onClick={() => {
                if (oid === "") {
                  return toast.error(
                    "Please enter an order id to track order.",
                    {
                      autoClose: false,
                    }
                  );
                }
                return checkRetailOrder({
                  variables: {
                    id: oid,
                  },
                });
              }}
            />
          </InputWrapper>
        </FirstContainer>
        {!loading &&
          data &&
          data.retail_order.length > 0 &&
          data.retail_order[0].id && (
            <PageWrapper>
              <Container>
                <StatusContainer>{checkStatus()}</StatusContainer>
              </Container>
              <Container>
                <Header>
                  <Time>Order ID: {order.id}</Time>
                  <Time>{FormatTime(order.created_at)}</Time>
                </Header>
                <ImageWrapper>
                  <img
                    height={60}
                    width={60}
                    style={{ borderRadius: 24, marginRight: 20 }}
                    src={order.retail_order_product.product_img}
                  />
                  <DetailsWrapper>
                    <Name>{order.retail_order_product.name}</Name>
                    <Quantity>{"x " + order.quantity}</Quantity>
                  </DetailsWrapper>
                </ImageWrapper>
              </Container>
              <Container>
                <Title>Delivery</Title>
                <DetailsWrapper>
                  <Name>{order.recipient_name}</Name>
                  <Quantity>Phone no: {order.phone}</Quantity>
                </DetailsWrapper>
                <DetailsWrapper>
                  <Row>
                    <Left>
                      <Name>{order.address}</Name>
                      <Quantity>
                        City: <Price>{order.city}</Price>
                      </Quantity>
                      <Quantity>
                        State: <Price>{order.state}</Price>
                      </Quantity>
                      <Quantity>
                        Postcode: <Price>{order.postcode}</Price>
                      </Quantity>
                    </Left>
                    <Right>
                      <Name>
                        {order.retail_order_shipping.shipping_rate_country
                          .currency +
                          " " +
                          order.shipping_rate}
                      </Name>
                    </Right>
                  </Row>
                </DetailsWrapper>
              </Container>
              <Container>
                <Header>
                  <Title>Payment</Title>
                  {checkPayment()}
                </Header>
                <DetailsWrapper>
                  <Row>
                    <Left>
                      <Name>{data.master[0].bank_acc_name}</Name>
                      <Quantity>
                        Bank account no:{" "}
                        <Price>{data.master[0].bank_acc_no}</Price>
                      </Quantity>
                      <Quantity>
                        Bank name: <Price>{data.master[0].bank_name}</Price>
                      </Quantity>
                    </Left>
                    <Right>
                      {data.retail_order[0].payment_slip ? (
                        <Slip
                          src={data.retail_order[0].payment_slip}
                          height={60}
                          width={60}
                        />
                      ) : (
                        <></>
                      )}
                    </Right>
                  </Row>
                </DetailsWrapper>
                <DetailsWrapper>
                  <Name>
                    Total Amount:{" "}
                    {order.retail_order_shipping.shipping_rate_country.currency}{" "}
                    {CommaFormatted(
                      order.quantity * order.unit_price + order.shipping_rate
                    )}
                  </Name>
                  <ImageUploader
                    placeholder={
                      data.retail_order[0].payment_slip
                        ? "Resubmit Payment Slip"
                        : "Upload Slip"
                    }
                    onChange={submitSlip}
                  />
                </DetailsWrapper>
              </Container>
              <TrackingInfo
                shipped={data.retail_order[0].shipped}
                shipped_at={data.retail_order[0].shipped_at}
                tracking_id={data.retail_order[0].tracking_id}
                courier={data.retail_order[0].courier}
                tracking_link={data.retail_order[0].tracking_link}
              />
              {data.retail_order[0].status === "Pending" && (
                <Container>
                  <Title>{dictionary.cancelOrder}</Title>
                  <DetailsWrapper>
                    <InputWrapper>
                      {dictionary.cancelOrderDescription}
                    </InputWrapper>
                  </DetailsWrapper>
                  <CancelButton
                    label={dictionary.cancelOrder}
                    onClick={_cancelOrder}
                  />
                </Container>
              )}
            </PageWrapper>
          )}
      </PageWrapper>
    </Wrapper>
  );
};
