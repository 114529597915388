import React from "react";
import styled from "styled-components";
import logo from "./Images/Google.png";
const Wrapper = styled.div`
  padding: 12px 24px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
const Text = styled.h1`
  font-family: ${(props) => props.theme.mainFont};
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  margin: 0px 8px;
`;
const GoogleSignInButton = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <img src={logo} height={24} width={24} />
      <Text>Continue with Google</Text>
    </Wrapper>
  );
};

export default GoogleSignInButton;
