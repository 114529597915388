import gql from "graphql-tag";

export const CHECK_USERNAME = gql`
  query checkUsername($username: String!) {
    users(where: { username: { _ilike: $username } }) {
      id
      name
      username
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getCountries($id: String!) {
    users_by_pk(id: $id) {
      country
      id
    }
  }
`;

export const CHECK_HALF_TICKET_USE = gql`
  query getHalfTicketCount($id: String!, $weight: Int) {
    half_ticket_aggregate(
      where: {
        half_package: { weight: { _eq: $weight } }
        user_id: { _eq: $id }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const GET_USERNAME = gql`
  query getUsername($id: String!) {
    users_by_pk(id: $id) {
      avatar
      name
      id
      username
      country
    }
    packages(where: { base: { _eq: true } }) {
      id
      name
      weight
      chinese_name
      country
    }
    package_order(
      order_by: { created_at: asc }
      where: {
        buyer_id: { _eq: $id }
        order_package: { big_offer: { _eq: true } }
      }
    ) {
      id
      order_package {
        promo__purchase_package {
          weight
          quantity
          package_weight(where: { half: { _eq: true } }) {
            id
            name
            chinese_name
            min
            system_offer
            package_country {
              name
              id
            }
          }
        }
      }
    }
    half_ticket(where: { user_id: { _eq: $id } }) {
      id
      code
      claimed_by
      package
      half_package {
        chinese_name
        name
        id
      }
    }
  }
`;
export const CHECK_REFERRAL = gql`
  query checkReferral($downline: String!) {
    relation_by_pk(downline: $downline) {
      downline
      upline
    }
  }
`;

export const USER_INFO = gql`
  query getUserInfo($id: String!) {
    users_by_pk(id: $id) {
      id
      name
      downline_count
      user_country {
        currency
      }
      pending_approval_orders_count
      users_upline {
        phone
        wechat_id
        whatsapp
        email
        name
        avatar
        current_rank_name
        joining_rank_name
      }
      current_rank_name
      current_rank_chinese_name
      joining_rank_name
      joining_rank_chinese_name
      avatar
      email
      phone
      username
      business_partner
      current_rank_chinese_name
      joining_rank_chinese_name
      stock_balance
    }
    cash_wallet_records_aggregate(
      where: { user_id: { _eq: $id }, status: { _eq: "Approved" } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    user_current_rank(where: { user_id: { _eq: $id } }) {
      user_rank {
        drb_reward
        id
      }
    }
    news {
      id
      cta_link
      url
    }
    master {
      company_name
      id
    }
  }
`;

export const CHECK_IC_USERNAME = gql`
  query getICUsername($username: String, $ic: String) {
    users(where: { ic_no: { _ilike: $ic }, username: { _ilike: $username } }) {
      username
      ic_no
    }
  }
`;

export const GET_UPLINE_INFO = gql`
  query getUplineInfp($id: String!) {
    relation_by_pk(downline: $id) {
      user_upline {
        current_rank_price
        current_rank_id
        id
        joining_rank_price
        joining_rank_name
        joining_package
      }
    }
  }
`;

export const GET_ALL_DOWNLINES = gql`
  query getDownlines($id: String!) {
    users(where: { id: { _eq: $id } }) {
      user_downlines {
        downline_count
        id
        current_rank_id
        current_rank_name
        name
        avatar
        email
        username
        phone
        user_joining_package {
          chinese_name
          name
        }
      }
      downline_count
      id
      upline
      current_rank_id
      current_rank_name
      name
      avatar
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile($id: String!) {
    users_by_pk(id: $id) {
      avatar
      business_partner
      current_rank_chinese_name
      current_rank_name
      downline_count
      joining_package
      joining_rank_chinese_name
      name
      stock_balance
      username
    }
    group_sales_figure {
      currency
      group_sales_quantity
    }
  }
`;

export const GET_PROFILE_DETAILS = gql`
  query userDetails($id: String!) {
    users_by_pk(id: $id) {
      avatar
      created_at
      email
      ic_no
      country
      id
      joining_rank_name
      joining_rank_chinese_name
      joining_rank_price
      phone
      secret_key
      stock_balance
      wechat_id
      whatsapp
      username
      name
      user_banks {
        bank_acc_name
        bank_acc_no
        bank_name
        id
      }
      user_addresses {
        city
        country
        id
        line
        name
        postcode
        state
        user_id
      }
    }
  }
`;

export const GET_TERMS_POLICY = gql`
  query getTermsAndPolicy($id: String) {
    master {
      terms_and_conditions
      privacy_policy
    }
  }
`;

export const GET_COMPANY_ABOUT = gql`
  query getCompany($id: Int!) {
    about_by_pk(id: $id) {
      id
      name
      url
    }
  }
`;

export const GET_COMPANY_CULTURE = gql`
  query getCompany($id: String) {
    master {
      id
      company_name
      brand_culture
    }
  }
`;

export const GET_COMPANY_ACTIVITY = gql`
  query getCompany($id: String) {
    master {
      id
      company_name
      team_activity
    }
  }
`;

export const GET_USER_PERFORMANCE = gql`
  query getPerformance($id: String!, $month: float8!, $year: float8!) {
    added_gross_personal_sales(
      where: { uid: { _eq: $id }, month: { _eq: $month }, year: { _eq: $year } }
    ) {
      quantity
    }
    added_group_sales_without_break_away(
      where: { uid: { _eq: $id }, month: { _eq: $month }, year: { _eq: $year } }
    ) {
      quantity
    }
    mds_bonus_v3_net(
      where: {
        user_id: { _eq: $id }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      quantity
      personal_bonus
      downline_bonus
    }
    break_away_v2_passup_record_aggregate(
      where: {
        user_id: { _eq: $id }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      aggregate {
        sum {
          quantity
          bonus
        }
      }
    }
    break_away_v2_qualified_users(
      where: {
        buyer_id: { _eq: $id }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      group_sales
      block
      personal_sales
    }
    break_away_v2_deduct_bonus_aggregate(
      where: {
        user_id: { _eq: $id }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      aggregate {
        sum {
          bonus
          quantity
        }
      }
    }
    cross_level_bonus_aggregate(
      where: {
        user_id: { _eq: $id }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      aggregate {
        sum {
          bonus
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: String!, $username: String) {
    users_by_pk(id: $id) {
      id
      name
    }
    users(where: { username: { _eq: $username } }) {
      id
      name
    }
    relation(where: { downline: { _eq: $id } }) {
      upline
    }
  }
`;
