import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import { useQuery } from "@apollo/react-hooks";
import { GET_GALLERY_IMAGE } from "../query/Gallery";
import Back from "../components/Back";
import { FormatTime } from "../utilities";
import { IoMdDownload } from "react-icons/io";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 20vh;
`;

const Box = styled.div`
  width: 100%:
    padding: 8px 12px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${(props) => props.theme.lightBlackColor};
    :last-child {
        border-style: none;
    }
    :hover{
        opacity: 0.7;
    }
`;
const BigText = styled.h1`
  font-size: 16px;
  font-weight: 600;
  padding: 8px 12px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const Date = styled.h1`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;
  color: ${(props) => props.theme.lightBlackColor};
`;

const PdfLink = styled.h1`
  font-size: 16px;
  font-weight: 600;
  padding: 8px 12px;
  cursor: pointer;
  color: white;
  pointer-events: auto;
  :hover {
    color: ${(props) => props.theme.mainColor};
  }
`;

const ImageWrapper = styled.div`
  width: calc(${(props) => props.width}px - 8px);
  border-radius: 16px;
  overflow: hidden;
  height: 350px;
  box-shadow: ${(props) => props.theme.boxShadow};
  margin-bottom: 8px;
  position: relative;
`;

const PreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 12px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 999;
  display: flex;
  align-items: center;
  padding 20px;
  justify-content: center;
`;

const Preview = styled.img`
  width: auto;
  height: auto;
  max-height: 90vh;
  max-width: 90vw;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 430px;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
`;
const ActionHeader = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.6);
`;
const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%:
  align-items: center;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 12px;
  text-transform:capitalize;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0.5));
`;

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
`;
export default ({ history, location }) => {
  let cid = location.search.split("=")[1];
  const { data, loading } = useQuery(GET_GALLERY_IMAGE, {
    variables: {
      id: cid,
    },
  });

  const wrapper = useRef(null);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [parentWidth, setParentWidth] = useState(0);
  useEffect(() => {
    if (wrapper.current) {
      setParentWidth(wrapper.current.offsetWidth);
    }
  });
  return (
    <Wrapper ref={wrapper}>
      <PageTitle
        title={!loading && data && data.gallery_collection_by_pk.name}
      />
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      {loading && <p>Loading..</p>}
      <Images>
        {!loading &&
          (data && data.gallery_image.length > 0 ? (
            data.gallery_image.map((item, index) => {
              console.log(item);
              if (
                item.type.split("/")[0] === "application" ||
                item.type.split("/")[0] === "video"
              ) {
                return (
                  <ImageWrapper
                    width={parentWidth * 0.5}
                    backgroundImage={item.url}
                    key={index}
                  >
                    {item.type.split("/")[0] === "video" ? (
                      <video
                        onClick={() => {
                          window.open(item.url);
                        }}
                        style={{
                          scrollbarWidth: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          position: "absolute",
                          zIndex: 1,
                        }}
                        src={item.url}
                        width="100%"
                        height={"100%"}
                      >
                        <source src={item.url} />
                      </video>
                    ) : (
                      <iframe
                        style={{
                          scrollbarWidth: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={item.url}
                        width="100%"
                        height={"100%"}
                      />
                    )}
                    <TextRow>
                      <PdfLink
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        {item.name}
                      </PdfLink>
                      {/* <Date>{FormatTime(item.created_at)}</Date> */}
                    </TextRow>
                  </ImageWrapper>
                );
              }
              return (
                <ImageWrapper width={parentWidth * 0.5}>
                  <ImageContainer>
                    <ActionHeader>
                      <a href={item.url} download target="_blank">
                        <IoMdDownload size={18} color="white" />
                      </a>
                    </ActionHeader>
                    <img
                      src={item.url}
                      height={"100%"}
                      width={"100%"}
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      onClick={() => {
                        setShowPreview(true);
                        setSelectedUrl(item.url);
                      }}
                    />
                  </ImageContainer>
                  {/* <TextRow>
                    <BigText>{item.name}</BigText>
                    <Date>{FormatTime(item.created_at)}</Date>
                  </TextRow> */}
                </ImageWrapper>
              );
            })
          ) : (
            <BigText>No image yet :(</BigText>
          ))}
      </Images>
      {showPreview && (
        <PreviewWrapper
          onClick={() => {
            setShowPreview(false);
          }}
        >
          <Preview src={selectedUrl} />
        </PreviewWrapper>
      )}
    </Wrapper>
  );
};
