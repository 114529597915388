import React, { useState } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CHECK_HALF_TICKET_USE } from "../query/Users";
import { auth } from "firebase";
import Loader from "./Loader";
import Button from "./Button";
import { INSERT_HALF_TICKET } from "../mutations/Users";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  margin-bottom: 12px;
`;

const Selector = styled.select`
  padding: 16px;
  border-radius: 12px;
  font-family: ${(props) => props.theme.mainFont};
  width: 100%;
  font-size: 14px;
  border: solid 1px ${(props) => props.theme.darkBlueColor};
`;

const SmallText = styled.p`
  margin: 8px 0px;
`;

export default ({ voucher, packages, country, close, refetch }) => {
  const [weight, setWeight] = useState(null);
  const [plan, setPlan] = useState(null);

  const [getHalfTicketCount, { data, loading, error }] = useLazyQuery(
    CHECK_HALF_TICKET_USE,
    {
      fetchPolicy: "network-only",
    }
  );

  const [insertHalf] = useMutation(INSERT_HALF_TICKET);
  const createVoucher = () => {
    toast.warning("Creating voucher...");
    insertHalf({
      variables: {
        id: auth().currentUser.uid,
        pid: plan,
      },
    }).then(() => {
      refetch().then(() => {
        toast.dismiss();
        toast.success("Voucher created!");
        close();
      });
    });
  };
  return (
    <AnimatedContainer
      close={close}
      title="Create Voucher"
      content={
        <Wrapper>
          <InputWrapper>
            <Selector
              onChange={(e) => {
                getHalfTicketCount({
                  variables: {
                    weight: voucher[e.target.value].weight,
                    id: auth().currentUser.uid,
                  },
                });
                setWeight(voucher[e.target.value]);
              }}
            >
              {weight === null && <option>Select a package</option>}
              {voucher.map((vouc, index) => {
                return (
                  <option key={index} value={index}>
                    {vouc.package_weight[0].name} -{" "}
                    {vouc.package_weight[0].chinese_name}:{" "}
                    {vouc.package_weight[0].system_offer}boxes
                  </option>
                );
              })}
            </Selector>
          </InputWrapper>
          {!loading &&
            data &&
            weight !== null &&
            (data.half_ticket_aggregate.aggregate.count < weight.quantity ? (
              <InputWrapper>
                <Selector
                  onChange={(e) => {
                    setPlan(e.target.value);
                  }}
                >
                  {plan === null && <option>Select a country</option>}
                  {weight.package_weight.map((country, index) => {
                    return (
                      <option value={country.id}>
                        {country.package_country.name}
                      </option>
                    );
                  })}
                </Selector>
                <SmallText>
                  {weight.quantity - data.half_ticket_aggregate.aggregate.count}{" "}
                  voucher left.
                </SmallText>
              </InputWrapper>
            ) : (
              <SmallText>Insufficient voucher!</SmallText>
            ))}
          {plan !== null && (
            <Button label="Create Voucher" onClick={createVoucher} />
          )}
          {loading && (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          )}
        </Wrapper>
      }
    />
  );
};
