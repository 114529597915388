import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_RETAIL_PRODUCTS } from "../query/Packages";
import { AiFillCheckCircle } from "react-icons/ai";
import Input from "../components/Input";
import { auth } from "firebase";
import Loader from "../components/Loader";
import Back from "../components/Back";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { INSERT_PUBLIC_RETAIL_ORDER } from "../mutations/Orders";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBG from "../components/Images/ThemeBG.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px 12px;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 1;
  padding-bottom: 230px;
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const ProductSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-overflow-style: none;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 0px 12px;
  width: 100%;
`;

const ProductScrollWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Section = styled.div`
  padding: 16px 20px;
  margin-bottom: 12px;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;
const GradientShade = styled.div`
  position: absolute;
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 0.2s;
  left: 0;
  background: ${(props) => props.bg};
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  width: 140px;
  cursor: pointer;
  position: relative;
  box-shadow: ${(props) => props.theme.boxShadow};
  :last-child {
    margin-right: 12px;
  }
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow},
      ${(props) => props.theme.boxShadow};
  }
  background-color: ${(props) => props.theme.lightGreyColor};
  border-radius: 16px;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.darkBlueColor};
`;

const CheckWrapper = styled.div`
  color: ${(props) => props.theme.mainColor};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
const SmallText = styled.p`
  font-weight: 400;
  text-align: center;
  color: ${(props) => props.theme.darkBlueColor};
  font-size: 12px;
  margin-top: 4px;
  padding-bottom: 8px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
`;

const InputWrapper = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow};
  padding-bottom: 0px;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 12px;
`;

const OtherInfo = styled.div``;

const Title = styled.h1`
  ${(props) => props.theme.title};
  margin-top: 12px;
  margin-bottom: 6px;
`;

const Error = styled.p`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 12px;
  margin-top: 6px;
`;

const Selector = styled.select`
  padding: 16px;
  border-radius: 12px;
  font-family: ${(props) => props.theme.mainFont};
  width: 100%;
  font-size: 14px;
  border: solid 1px ${(props) => props.theme.darkBlueColor};
`;
const ProductPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  cursor: pointer;
  position: relative;
  background-color: none;
`;
export default ({ history }) => {
  const gradientScroll = useRef();
  const [gradient, setGradient] = useState(`linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.9)
  )`);
  const [selectedProduct, setProduct] = useState();
  const [changed, setChanged] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [country, setCountry] = useState("default");
  const [quantity, setQuantity] = useState(0);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [dstate, setDState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [phone, setPhone] = useState("");
  const [shipping, setShipping] = useState("default");
  const { dictionary } = useContext(LanguageStore);
  const { data, loading, error } = useQuery(GET_RETAIL_PRODUCTS, {
    fetchPolicy: "network-only",
    variables: {
      id: localStorage.getItem("retailReferral") || " ",
    },
  });
  const [newRetailOrder] = useMutation(INSERT_PUBLIC_RETAIL_ORDER);

  useEffect(() => {
    if (gradientScroll.current) {
      document.getElementById("product-scroll").onscroll = () => {
        if (gradientScroll.current.scrollLeft < 40) {
          setGradient(`linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.9)
        )`);
        } else {
          setGradient(`linear-gradient(
          to left,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.9)
        )`);
        }
      };
    }
  });
  const placeOrder = (e) => {
    e.preventDefault();
    if (country === "") {
      return toast.error(dictionary.chooseCountry, { autoClose: 2000 });
    }
    if (!selectedProduct) {
      return toast.error(dictionary.enterProduct, { autoClose: 2000 });
    }
    if (isNaN(quantity)) {
      return toast.error(dictionary.emptyAmount, { autoClose: 2000 });
    }
    if (quantity <= 0) {
      return toast.error(dictionary.quantityCannotZero, { autoClose: 2000 });
    }
    if (recipient === "") {
      return toast.error(dictionary.enterName, { autoClose: 2000 });
    }
    if (phone === "") {
      return toast.error(dictionary.enterPhone, { autoClose: 2000 });
    }
    if (address === "") {
      return toast.error(dictionary.enterAddress, { autoClose: 2000 });
    }
    if (city === "") {
      return toast.error(dictionary.enterCity, { autoClose: 2000 });
    }
    if (dstate === "") {
      return toast.error(dictionary.enterState, { autoClose: 2000 });
    }
    if (postcode === "") {
      return toast.error(dictionary.enterPostcode, { autoClose: 2000 });
    }
    if (shipping === "default") {
      return toast.error(dictionary.enterShipping, {
        autoClose: 2000,
      });
    }
    if (data.users_by_pk) {
      if (quantity > data.users_by_pk.stock_balance) {
        return toast.error(dictionary.agentInsufficientStock, {
          autoClose: false,
        });
      }
      toast.warning(dictionary.submittingOrder, { autoClose: false });
      newRetailOrder({
        variables: {
          product_id: selectedProduct,
          quantity: quantity,
          address: address,
          state: dstate,
          city: city,
          postcode: postcode,
          recipient_name: recipient,
          phone: phone,
          shipping_rate: shipping.rate,
          shipping: shipping.id,
          seller_id:
            localStorage.getItem("retailReferral") ||
            auth().currentUser?.uid ||
            null,
          unit_price: country.retail_price,
        },
      })
        .then((result) => {
          toast.dismiss();
          toast.success(dictionary.orderPlaced);
          history.push(
            `/order_tracking?order_id=${result.data.insert_retail_order.returning[0].id}`
          );
        })
        .catch((e) => {
          toast.error(e.message, { autoClose: false });
        });
    } else {
      return toast.error(dictionary.noAgentDetected, { autoClose: false });
    }
  };
  return (
    <Wrapper>
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      <Title>{dictionary.chooseCountry}</Title>
      <InputWrapper>
        <Selector
          onChange={(e) => {
            setChanged(true);
            setCountry(data.country[e.target.value]);
            setShipping("default");
          }}
        >
          {!changed && <option>{dictionary.chooseCountry}</option>}
          {!loading &&
            data &&
            data.country.length > 0 &&
            data.country.map((country, index) => {
              return (
                <option value={index} key={index}>
                  {country.name +
                    " - " +
                    country.currency +
                    " " +
                    country.retail_price +
                    "/box"}{" "}
                </option>
              );
            })}
        </Selector>
      </InputWrapper>
      <Section>
        <Title>{dictionary.selProduct}</Title>
        {loading && (
          <OtherInfo>
            <Loader />
          </OtherInfo>
        )}
        {!loading && data && (
          <ProductScrollWrapper>
            <GradientShade bg={gradient} />

            <ProductSelector id="product-scroll" ref={gradientScroll}>
              {data.products.length > 0 &&
                data.products.map((product, index) => {
                  return (
                    <ProductWrapper
                      key={index}
                      onClick={() => {
                        setProduct(product.id);
                      }}
                    >
                      <img
                        style={{ borderRadius: 16, objectFit: "cover" }}
                        src={product.product_img}
                        width={140}
                        height={140}
                      />
                      {selectedProduct === product.id && (
                        <CheckWrapper>
                          <AiFillCheckCircle size={18} />
                        </CheckWrapper>
                      )}
                      <TextWrapper>
                        <Name>{product.name}</Name>
                      </TextWrapper>
                    </ProductWrapper>
                  );
                })}
              <ProductPlaceholder>
                <div
                  style={{
                    width: "120px",
                    height: "50px",
                    backgroundColor: "white",
                  }}
                ></div>
              </ProductPlaceholder>
            </ProductSelector>
          </ProductScrollWrapper>
        )}
      </Section>
      {country !== "default" && selectedProduct && (
        <OtherInfo>
          <Title>{dictionary.enterQuantity}</Title>
          <InputWrapper>
            <Input
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
              label={dictionary.qtt}
              placeholder="0"
            />
            {country !== "default" && !isNaN(quantity) && quantity > 0 && (
              <SmallText>
                {dictionary.unitP}:{" "}
                {country.currency +
                  country.retail_price +
                  " x " +
                  quantity +
                  " = " +
                  country.currency +
                  country.retail_price * quantity}
              </SmallText>
            )}
          </InputWrapper>
          <Title>{dictionary.deliveryInformation}</Title>
          <InputWrapper>
            <Input
              label={dictionary.recipientName}
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
              placeholder="Your name"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.phoneNo}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              placeholder="012xxxxxxx"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.address}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.city}
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.state}
              value={dstate}
              onChange={(e) => {
                setDState(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.postcode}
              value={postcode}
              onChange={(e) => {
                setPostcode(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Selector
              onChange={(e) => {
                setShipping(country.country_shipping_rates[e.target.value]);
              }}
            >
              {shipping === "default" && (
                <option value="default">{dictionary.chooseArea}</option>
              )}
              {country.country_shipping_rates.map((ship, index) => {
                return (
                  <option
                    selected={shipping?.id === ship.id}
                    value={index}
                    key={index}
                  >
                    {ship.name + " - " + country.currency + " " + ship.rate}
                  </option>
                );
              })}
            </Selector>
          </InputWrapper>
          <InputWrapper>
            <Button label={dictionary.submitOrder} onClick={placeOrder} />
          </InputWrapper>
        </OtherInfo>
      )}
    </Wrapper>
  );
};
