import React, { useState, useContext } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { INSERT_ADDRESS } from "../mutations/Users";
import Input from "./Input";
import { GET_COUNTRIES } from "../query/Users";
import Button from "./Button";
import { toast } from "react-toastify";
import { auth } from "firebase";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Selector = styled.select`
  padding: 16px;
  border-radius: 12px;
  font-family: ${(props) => props.theme.mainFont};
  width: 100%;
  font-size: 14px;
  border: solid 1px ${(props) => props.theme.darkBlueColor};
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
`;
const InputWrapper = styled.div`
  padding-bottom: 12px;
`;
export default ({ refetch, close }) => {
  const { data, loading } = useQuery(GET_COUNTRIES, {
    variables: {
      id: auth().currentUser.uid,
    },
  });
  const { dictionary } = useContext(LanguageStore);
  const [newAddress] = useMutation(INSERT_ADDRESS);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [aState, setAState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("default");
  console.log(data);
  return (
    <AnimatedContainer
      title={dictionary.newAddress}
      close={close}
      loading={loading}
      content={
        <Wrapper>
          {/* <Selector
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          >
            <option value="default">Select a country</option>
            {!loading &&
              data &&
              data.country.length > 0 &&
              data.country.map((c, index) => {
                return <option value={c.id}>{c.name}</option>;
              })}
          </Selector> */}
          <InputWrapper>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={dictionary.name}
              placeholder="e.g. My Home"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              label={dictionary.address}
              placeholder="e.g. #no, street, area..."
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              label={dictionary.city}
              placeholder="City"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={aState}
              onChange={(e) => {
                setAState(e.target.value);
              }}
              label={dictionary.state}
              placeholder="State"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={postcode}
              onChange={(e) => {
                setPostcode(e.target.value);
              }}
              label={dictionary.postcode}
              placeholder="Postcode"
            />
          </InputWrapper>
          <InputWrapper>
            {!loading && data && (
              <Button
                label={dictionary.addAddress}
                onClick={() => {
                  if (
                    name === "" ||
                    address === "" ||
                    city === "" ||
                    aState === "" ||
                    postcode === ""
                  ) {
                    return toast.error(dictionary.pleaseCompleteForm);
                  }
                  toast.warning(dictionary.addingAddress, { autoClose: false });
                  newAddress({
                    variables: {
                      city: city,
                      country: data.users_by_pk.country,
                      line: address,
                      name: name,
                      postcode: postcode,
                      state: aState,
                      uid: auth().currentUser.uid,
                    },
                  })
                    .then((e) => {
                      toast.dismiss();
                      refetch();
                      close();
                      toast.success(dictionary.addressAdded);
                    })
                    .catch((e) => {
                      toast.dismiss();
                      toast.error(e.message);
                    });
                }}
              />
            )}
          </InputWrapper>
        </Wrapper>
      }
    />
  );
};
