import React from "react";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";

const Wrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

export default ({ data }) => {
  return (
    <Wrapper>
      <AliceCarousel
        autoPlay
        autoPlayInterval="3000"
        mouseTrackingEnabled
        buttonsDisabled={true}
      >
        {data.map((image, index) => {
          return (
            <img
              key={index}
              src={image.url}
              onClick={() => {
                if (image.cta_link) {
                  window.open(image.cta_link);
                }
              }}
              width="100%"
              height="auto"
              style={{
                objectFit: "cover",
                borderRadius: "12px",
                backgroundColor: "#f1f3f6",
              }}
            />
          );
        })}
      </AliceCarousel>
    </Wrapper>
  );
};
