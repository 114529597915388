import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import { useLazyQuery } from "@apollo/react-hooks";
import Input from "./Input";
import Button from "./Button";
import { SEARCH_BIG_OFFER } from "../query/Packages";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { CommaFormatted } from "../utilities";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding-bottom: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Selector = styled.select`
  padding: 16px;
  border-radius: 12px;
  font-family: ${(props) => props.theme.mainFont};
  width: 100%;
  font-size: 14px;
  border: solid 1px ${(props) => props.theme.darkBlueColor};
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 120px;
  width: 100%;
`;

const BigOfferWrapper = styled.div`
  padding-top: 12px;
`;

const EmptyText = styled.p`
  width: 100%;
  text-align: center;
`;

const PackageName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
`;
export default ({ close, setPackage, next, setCountry, predefinedCode }) => {
  const [promo, setPromo] = useState("default");
  const [code, setCode] = useState("");
  const [ticket, setTicket] = useState(predefinedCode || "");
  const { dictionary } = useContext(LanguageStore);
  const [findOffer, { data, loading, error }] = useLazyQuery(SEARCH_BIG_OFFER);
  useEffect(() => {
    if (predefinedCode) {
      setPromo("half");
      findOffer({
        variables: {
          code: "",
          half_code: predefinedCode,
        },
      });
    }
  }, []);
  return (
    <AnimatedContainer
      title={dictionary.voucher}
      close={close}
      content={
        <Wrapper>
          <InputWrapper>
            <Selector
              onChange={(e) => {
                setPromo(e.target.value);
              }}
            >
              <option value="default">{dictionary.chooseOffer}</option>
              <option value="bigOffer">半门槛</option>
            </Selector>
            {promo === "bigOffer" && (
              <FormWrapper>
                <InputWrapper>
                  <Input
                    label={"半门槛"}
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </InputWrapper>
                <Button
                  label={dictionary.searchVoucher}
                  onClick={(e) => {
                    e.preventDefault();
                    if (code === "") {
                      return toast.error(dictionary.enterValidVoucher, {
                        autoClose: false,
                      });
                    }
                    findOffer({
                      variables: {
                        code: code,
                        half_code: "00000000-0000-0000-0000-000000000000",
                      },
                    });
                  }}
                />
              </FormWrapper>
            )}
            {promo === "half" && (
              <FormWrapper>
                <InputWrapper>
                  <Input
                    label={dictionary.halfTicket}
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxx"
                    value={ticket}
                    onChange={(e) => {
                      setTicket(e.target.value);
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Button
                    label={dictionary.checkTicket}
                    onClick={(e) => {
                      e.preventDefault();
                      if (ticket === "") {
                        return toast.error(dictionary.enterValidVoucher, {
                          autoClose: false,
                        });
                      }
                      findOffer({
                        variables: {
                          code: "",
                          half_code: ticket,
                        },
                      });
                    }}
                  />
                </InputWrapper>
              </FormWrapper>
            )}
          </InputWrapper>
          <ResultWrapper>
            {loading && <Loader />}
            {promo === "bigOffer" &&
              !loading &&
              data &&
              (data.big_offer_tickets.length > 0 &&
              data.big_offer_tickets[0].claimed_by === null ? (
                <BigOfferWrapper>
                  <InputWrapper>
                    <PackageName>
                      {data.big_offer_tickets[0].offer_package.chinese_name} -{" "}
                      {data.big_offer_tickets[0].offer_package.name}
                    </PackageName>
                    <SmallText>
                      {data.big_offer_tickets[0].offer_package.min +
                        " x " +
                        data.big_offer_tickets[0].offer_package.package_country
                          .currency +
                        " " +
                        data.big_offer_tickets[0].offer_package.unit_price +
                        " = " +
                        data.big_offer_tickets[0].offer_package.package_country
                          .currency +
                        CommaFormatted(
                          data.big_offer_tickets[0].offer_package.min *
                            data.big_offer_tickets[0].offer_package.unit_price
                        )}
                    </SmallText>
                  </InputWrapper>
                  <Button
                    label={dictionary.next}
                    onClick={() => {
                      setPackage({
                        id: data.big_offer_tickets[0].offer_package.id,
                        amount: data.big_offer_tickets[0].offer_package.min,
                        name:
                          data.big_offer_tickets[0].offer_package.chinese_name,
                        price:
                          data.big_offer_tickets[0].offer_package.unit_price,
                        currency:
                          data.big_offer_tickets[0].offer_package
                            .package_country.currency,
                        system_offer:
                          data.big_offer_tickets[0].offer_package.system_offer,
                        offer: "big_offer",
                        offer_package:
                          data.big_offer_tickets[0].offer_package.id,
                        voucher: code,
                      });
                      setCountry(
                        parseInt(
                          data.big_offer_tickets[0].offer_package
                            .package_country.id
                        )
                      );
                      next();
                    }}
                  />
                </BigOfferWrapper>
              ) : (
                <EmptyText>{dictionary.ticketUsed}</EmptyText>
              ))}
            {promo === "half" && error && (
              <EmptyText>{dictionary.ticketError}</EmptyText>
            )}
            {promo === "half" &&
              data &&
              data.half_ticket &&
              (data.half_ticket.length <= 0 ? (
                <EmptyText>{dictionary.ticketError}</EmptyText>
              ) : (
                <BigOfferWrapper>
                  {data.half_ticket[0].claimed_by !== null ? (
                    <EmptyText>{dictionary.ticketUsed}</EmptyText>
                  ) : (
                    <>
                      <InputWrapper>
                        <PackageName>
                          {data.half_ticket[0].half_package.chinese_name} -{" "}
                          {data.half_ticket[0].half_package.name}
                        </PackageName>
                        <SmallText>
                          {data.half_ticket[0].half_package.system_offer +
                            " x " +
                            data.half_ticket[0].half_package.package_country
                              .currency +
                            " " +
                            data.half_ticket[0].half_package.unit_price +
                            " = " +
                            data.half_ticket[0].half_package.package_country
                              .currency +
                            CommaFormatted(
                              data.half_ticket[0].half_package.system_offer *
                                data.half_ticket[0].half_package.unit_price
                            )}
                        </SmallText>
                      </InputWrapper>
                      <Button
                        label={dictionary.next}
                        onClick={() => {
                          setPackage({
                            id: data.half_ticket[0].half_package.id,
                            amount: data.half_ticket[0].half_package.min,
                            name: data.half_ticket[0].half_package.chinese_name,
                            price: data.half_ticket[0].half_package.unit_price,
                            currency:
                              data.half_ticket[0].half_package.package_country
                                .currency,
                            system_offer:
                              data.half_ticket[0].half_package.system_offer,
                            offer: "half",
                            half_package: data.half_ticket[0].half_package.id,
                            voucher: ticket,
                          });
                          setCountry(
                            parseInt(
                              data.half_ticket[0].half_package.package_country
                                .id
                            )
                          );
                          next();
                        }}
                      />
                    </>
                  )}
                </BigOfferWrapper>
              ))}
          </ResultWrapper>
        </Wrapper>
      }
    />
  );
};
