import React, { useState, useContext } from "react";
import styled from "styled-components";
// import { CREATE_PROFILE } from "../../mutations/User";
import { useMutation, useQuery } from "@apollo/react-hooks";
import MainButton from "../../components/Button";
import { auth } from "firebase";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import { GET_UPLINE_INFO } from "../../query/Users";
import { INSERT_USER } from "../../mutations/Users";
import TermsAndConditions from "../../components/TermsAndConditions";
import { CommaFormatted } from "../../utilities";
import { LanguageStore } from "../../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20vh;
`;
const Title = styled.div`
  margin-bottom: 6px;
  ${(props) => props.theme.title}
`;

const Box = styled.div`
  padding: 20px;
  margin: 8px 0px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.lightGreyColor};
`;
const Text = styled.h1`
  font-family: ${(props) => props.theme.mainFont};
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 470px;
  bottom: 0;
  left: 50%;
  padding: 20px;
  padding-bottom: 30px;
  transform: translate(-50%, 0%);
  z-index: 4;
  background-color: white;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
`;

const B = styled.span`
  font-weight: 600;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TCText = styled.p`
  font-size: 14px;
  margin-left: 8px;
`;
const Checkbox = styled.input`
  border-radius: 4px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
`;

const TC = styled.span`
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.mainColor};
  }
`;

const BackLink = styled.p`
  margin: 12px 0px;
  color: ${(props) => props.theme.mainColor};
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;

const ChangeSecret = styled.span`
  color: ${(props) => props.theme.mainColor};
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;
const Confirm = ({
  location,
  history,
  action,
  setAction,
  selectedPackage,
  country,
  name,
  ic,
  phone,
  secret,
  lineOne,
  city,
  aState,
  postcode,
  bankName,
  bankAccNo,
  username,
  bankAccName,
}) => {
  let email = auth().currentUser.email;
  let id = auth().currentUser.uid;
  const [showTc, setShowTc] = useState(false);
  const [checked, setChecked] = useState(false);
  let avatar = auth().currentUser.photoURL ? auth().currentUser.photoURL : null;
  const [confirmSecret, setConfirmSecret] = useState("");
  const { data, loading } = useQuery(GET_UPLINE_INFO, {
    variables: {
      id: auth().currentUser.uid,
    },
  });
  const [newUser] = useMutation(INSERT_USER);
  const { dictionary } = useContext(LanguageStore);

  return loading ? (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      <Box>
        <Title>{dictionary.selectedPackage}</Title>
        <Text>
          {dictionary.package}: {selectedPackage.name}
        </Text>
        <Text>
          {dictionary.qtt} :{" "}
          {selectedPackage.offer
            ? (selectedPackage.system_offer || 0) +
              (selectedPackage.amount || 0)
            : selectedPackage.amount}
          {selectedPackage.system_offer > 0 &&
            !selectedPackage.offer &&
            " + " + selectedPackage.system_offer}
        </Text>
        <Text>
          {dictionary.unitP}: {selectedPackage.currency}
          {selectedPackage.price} / unit
        </Text>
        <Text>
          {dictionary.total}: {selectedPackage.currency}
          {selectedPackage.offer
            ? CommaFormatted(
                selectedPackage.price *
                  (selectedPackage.system_offer + selectedPackage.amount)
              )
            : CommaFormatted(selectedPackage.price * selectedPackage.amount)}
        </Text>
        <BackLink
          onClick={() => {
            setAction(1);
          }}
        >
          {dictionary.backToSelectPackage}
        </BackLink>
      </Box>
      <Box>
        <Title>{dictionary.contactDetails}</Title>
        <Text>
          {dictionary.fullName}: <B>{name}</B>
        </Text>
        <Text>
          {dictionary.fullName}: <B>{username}</B>
        </Text>
        <Text>
          {dictionary.phoneNo}: <B>{phone}</B>
        </Text>
        <Text>
          IC no: <B>{ic}</B>
        </Text>
        <Text>
          {dictionary.secondPassword}:{" "}
          <ChangeSecret
            onClick={() => {
              setAction(2);
            }}
          >
            {dictionary.changeSecondPassword}
          </ChangeSecret>
        </Text>
        <BackLink
          onClick={() => {
            setAction(2);
          }}
        >
          {dictionary.backToContact}
        </BackLink>
      </Box>
      <Box>
        <Title>{dictionary.deliveryAddress}</Title>
        <Text>
          {dictionary.deliveryAddress}: <B>{lineOne}</B>
        </Text>
        <Text>
          {dictionary.ccity}: <B>{city}</B>
        </Text>
        <Text>
          {dictionary.state}: <B>{aState}</B>
        </Text>
        <Text>
          {dictionary.postcode}: <B>{postcode}</B>
        </Text>
        <BackLink
          onClick={() => {
            setAction(2);
          }}
        >
          {dictionary.backToDelivery}
        </BackLink>
      </Box>
      <Box>
        <Title>{dictionary.bankD}</Title>
        <Text>
          {dictionary.bankName}: <B>{bankName}</B>
        </Text>
        <Text>
          {dictionary.bankAccNo}: <B>{bankAccNo}</B>
        </Text>
        <Text>
          {dictionary.bankAccName}: <B>{bankAccName}</B>
        </Text>
        <BackLink
          onClick={() => {
            setAction(2);
          }}
        >
          {dictionary.backToBankDetails}
        </BackLink>
      </Box>

      <ButtonWrapper>
        <Input
          type="password"
          label={dictionary.confirmSecondPass}
          placeholder={dictionary.repeatSecondPass}
          value={confirmSecret}
          onChange={(e) => {
            setConfirmSecret(e.target.value);
          }}
        />
        <MainButton
          label="Save"
          onClick={async () => {
            if (confirmSecret !== secret) {
              return toast.error(dictionary.secondPasswordIncorrec);
            }
            if (data === undefined) {
              return toast.error(dictionary.noInternetConnection);
            }
            if (checked === false) {
              return toast.error("You must agree to our terms and conditions.");
            }
            toast.warning("Saving...", { autoClose: false });
            newUser({
              variables: {
                id: auth().currentUser.uid,
                avatar: auth().currentUser.photoURL || null,
                country: country,
                email: auth().currentUser.email,
                ic_no: ic,
                name: name,
                phone: phone,
                secret_key: secret,
                upline: data.relation_by_pk.user_upline.id,
                username: username,
                cost_price: data.relation_by_pk.user_upline.current_rank_price
                  ? data.relation_by_pk.user_upline.current_rank_price
                  : data.relation_by_pk.user_upline.joining_rank_price,
                package_id: selectedPackage.id,
                quantity: selectedPackage.amount,
                selling_price: selectedPackage.price,
                city: city,
                line: lineOne,
                postcode: postcode,
                state: aState,
                bankName: bankName,
                bankAccName: bankAccName,
                bankAccNo: bankAccNo,
                system_offer: selectedPackage.system_offer,
                bigOfferCode:
                  selectedPackage.offer === "big_offer"
                    ? selectedPackage.voucher
                    : "",
                ticketCode:
                  selectedPackage.offer === "half"
                    ? selectedPackage.voucher
                    : "00000000-0000-0000-0000-000000000000",
              },
            })
              .then(async (data) => {
                toast.dismiss();
                toast.success("Account setup complete!");
                history.push("/orders");
              })
              .catch((error) => {
                toast.dismiss();
                if (
                  error.message ===
                  `GraphQL error: Uniqueness violation. duplicate key value violates unique constraint "users_ic_no_key"`
                ) {
                  setAction(2);
                  return toast.error("Your IC no has been registered!");
                } else if (
                  error.message ===
                  `GraphQL error: Uniqueness violation. duplicate key value violates unique constraint "users_username_key"`
                ) {
                  setAction(2);
                  return toast.error("Your username has been taken!");
                } else {
                  toast.error(error.message, { autoClose: false });
                  return toast.error("Something happened! Contact our admin.");
                }
              });
          }}
        />
        <Row>
          <Checkbox
            type="checkbox"
            onChange={(e) => {
              setChecked(!checked);
            }}
            value={checked}
          />
          <TCText>
            I agree to {dictionary.companyName}'s{" "}
            <TC
              onClick={() => {
                setShowTc(true);
              }}
            >
              terms and conditions
            </TC>
          </TCText>
        </Row>
      </ButtonWrapper>
      {showTc && (
        <TermsAndConditions
          close={() => {
            setShowTc(false);
          }}
        />
      )}
    </Wrapper>
  );
};
export default withRouter(Confirm);
