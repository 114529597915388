import React, { useContext, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { AiFillHome, AiOutlineUsergroupAdd } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { MdAddBox } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoIosPaper } from "react-icons/io";
import { RiNewspaperLine } from "react-icons/ri";
import ProfileStore from "../context/ProfileContext";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  padding-bottom: 16px;
  font-family: ${(props) => props.theme.mainFont};
  align-items: center;
  background-color: white;
  position: fixed;
  width: 100%;
  max-width: 470px;
  left: 50%;
  z-index: 3;
  bottom: 0;
  background-color: white;
  transform: translate(-50%, 0%);
  border-top-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const AddButton = styled(NavLink)`
  cursor: pointer;
  padding: 12px;
  position: absolute;
  background: linear-gradient(
    -45deg,
    ${(props) => props.theme.mainShade},
    ${(props) => props.theme.mainColor}
  );
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  flex-direction: column;
  justify-content: center;
  border-radius: 23px;
  height: 50px;
  width: 50px;
  transform: translate(0%, 0%);
  border-color: white;
`;

const Item = styled(NavLink)`
  cursor: pointer;
  padding: 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.darkGreyColor};
  flex-direction: column;
  justify-content: space-between;
  :hover {
    color: ${(props) => props.theme.mainColor};
  }
`;

const PlaceHolder = styled.div`
  color: white;
  color: none;
`;

const ItemText = styled.p`
  font-size: 10px;
  text-align: center;
  margin-top: 4px;
`;

export default withTheme(({ theme, isLoggedIn }) => {
  const { dictionary } = useContext(LanguageStore);
  const activeStyle = {
    color: theme.mainColor,
  };

  return isLoggedIn ? (
    <Wrapper>
      <Item activeStyle={activeStyle} exact to="/">
        <AiFillHome size={24} />
        <ItemText>{dictionary.home}</ItemText>
      </Item>
      <Item activeStyle={activeStyle} to="/sharing">
        <IoIosPaper size={24} />
        <ItemText>{dictionary.sharing}</ItemText>
      </Item>
      <PlaceHolder>
        <MdAddBox size={24} />
      </PlaceHolder>
      <AddButton
        activeStyle={{
          color: theme.white,
          background: `linear-gradient(
            -45deg,
            ${theme.mainColor},
            ${theme.subColor}
          )`,
        }}
        to="/refer_new"
      >
        <AiOutlineUsergroupAdd size={28} />
      </AddButton>
      <Item activeStyle={activeStyle} to="/orders">
        <RiNewspaperLine size={24} />
        <ItemText>{dictionary.orders}</ItemText>
      </Item>
      <Item activeStyle={activeStyle} exact to="/profile">
        <IoMdPerson size={24} />
        <ItemText>{dictionary.me}</ItemText>
      </Item>
    </Wrapper>
  ) : null;
});
