import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import { CommaFormatted } from "../utilities";
import { dictionaryList } from "./Languages";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  transition: 0.4s;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  :hover {
    color: white;
    background-color: ${(props) => props.theme.mainShade};
  }
`;

const TitleWrapper = styled.div`
  padding: 12px;
  width: 100%;
  background-color: ${(props) => props.theme.mainShade};
`;
const Title = styled.h1`
  ${(props) => props.theme.title}
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.lightGreyColor};
`;

const ContentWrapper = styled.div`
  padding: 12px;
`;

const AmountText = styled.h1`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  line-height: 30px;
`;

const QuantityText = styled.h4`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
`;

const DiscountPrice = styled.h4`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  text-decoration: line-through;
`;
const Package = ({
  id,
  name,
  amount,
  unit,
  price,
  currency,
  selectedPackage,
  setPackage,
  theme,
  system_offer,
  chinese_name,
}) => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper
      bgColor={
        id === selectedPackage.id ? theme.mainShade : theme.lightGreyColor
      }
      color={id === selectedPackage.id ? "white" : theme.darkBlueColor}
      onClick={() => {
        setPackage({
          id,
          amount,
          name: chinese_name,
          unit,
          price,
          currency,
          system_offer,
        });
      }}
    >
      <TitleWrapper>
        <Title>{chinese_name}</Title>
      </TitleWrapper>
      <ContentWrapper>
        {system_offer !== 0 && (
          <DiscountPrice>{currency + " " + price + "/" + unit}</DiscountPrice>
        )}
        <AmountText>
          {system_offer
            ? currency +
              " " +
              (price * amount) / (amount + system_offer) +
              "/" +
              unit
            : currency + " " + price + "/" + unit}
        </AmountText>
        <QuantityText>
          {amount} {system_offer ? " + " + system_offer + " " : ""}
          {unit}(s)
        </QuantityText>
        <QuantityText>
          {dictionaryList.total}:{" "}
          {currency + " " + CommaFormatted(parseInt(price) * parseInt(amount))}
        </QuantityText>
      </ContentWrapper>
    </Wrapper>
  );
};

export default withTheme(Package);
