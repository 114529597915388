import React, { useContext } from "react";
import styled from "styled-components";
import { FormatTime } from "../utilities";
import { IoMdCopy } from "react-icons/io";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { LanguageStore } from "../context/LanguageStore";

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;

const Container = styled.div`
  margin-bottom: 8px;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
`;
const DetailsWrapper = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TrackingLink = styled.span`
  margin-top: 12px;
  color: ${(props) => props.theme.mainColor};
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.subColor};
  }
`;
const CopyWrapper = styled.span`
  margin-left: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const EmptyWrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EmptyText = styled.p`
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`;
export default ({
  shipped,
  shipped_at,
  tracking_id,
  tracking_link,
  courier,
}) => {
  const { dictionary } = useContext(LanguageStore);
  const CopyTracking = (tracking) => {
    copy(tracking);
    toast.success(dictionary.trackingCopied);
  };
  if (shipped && tracking_id) {
    return (
      <Container>
        <Header>
          <Title>{dictionary.tracking}</Title>
          <Time>{FormatTime(shipped_at)}</Time>
        </Header>
        <DetailsWrapper>
          <Name>
            {tracking_id}{" "}
            <CopyWrapper
              onClick={() => {
                CopyTracking(tracking_id);
              }}
            >
              <IoMdCopy size={16} />{" "}
            </CopyWrapper>
          </Name>
          <Quantity>
            <Price>{courier}</Price>
          </Quantity>
          {tracking_link ? (
            <TrackingLink
              onClick={() => {
                window.open(
                  tracking_link.substring(0, 4) === "http"
                    ? tracking_link
                    : "https://" + tracking_link
                );
              }}
            >
              {tracking_link}
            </TrackingLink>
          ) : (
            <></>
          )}
        </DetailsWrapper>
      </Container>
    );
  } else {
    return (
      <Container>
        <Title>{dictionary.tracking}</Title>
        <EmptyWrapper>
          <EmptyText>
            {shipped ? dictionary.orderProcessing : dictionary.shipmentPending}
          </EmptyText>
        </EmptyWrapper>
      </Container>
    );
  }
};
