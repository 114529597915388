const COMPANY_NAME = "Hushlab";
export default {
  companyName: "Hushlab",
  signIn: "登入",
  createAcc: "注册",
  creatingAcc: "请稍等。。。",
  email: "电邮",
  pass: "密码",
  paid: "已付款",
  otherOption: "其它",
  maximum: "上限",
  refUsername: "推荐人",
  about: "品牌故事",
  culture: "团队文化",
  performance: "业绩",
  activities: "品牌活动",
  changePassword: "更改密码",
  oldPassword: "旧密码",
  cancelOrder: "取消订单",
  cancelOrderDescription: "订单被批准前可取消订单。确定取消订单吗？",

  newPassword: "新密码",
  confirmNewPassword: "确认新密码",
  resetPassword: "重置密码",
  noPaymentYet: "对方还未付款，是否确定批准订单？",
  checkPaymentFirst: "请确保对方已经付款，订单批准后将无法撤回。",
  resetPasswordTips:
    "输入您的电子邮件后，我们将发送链接到您的电子邮件让您重置密码。",
  descrefUsername: "仅限会员登入，请输入推荐人用户号",
  userName: "用户号",
  searchUsername: "搜索用户号",
  contF: "面子书登入",
  contG: "谷歌登入",
  wait: "请稍等。。。",
  welcomeAlert: "欢迎!",
  brandStory: "关于Hushlab",
  addHomeScreen: "加 Hushlab 到手机主页",
  descaddHomeScreen: "下载Hushlab到手机主页后可当APP使用",
  maybeLater: "下次吧",
  welcomeHome: "欢迎",
  stocks: "库存",
  cashWallet: "钱包",
  downline: "组织图",
  repeatOrder: "补货",
  upgrade: "升级",
  mms: "MMS",
  name: "名字",
  breakAwayBonus: "脱离奖金",
  monthlySales: "月销量",
  groupSales: "组织销量",
  personalSales: "个人销量",
  overrideBonus: "越级奖金",
  downlineBonus: "下线奖金",
  personalGroupSales: "个人小组",
  breakAwayLine: "脱离线",
  boxes: "盒",
  viewSummary: "报告",
  reqShipping: "要求发货",
  mall: "商城",
  personalBonus: "个人奖金",
  passupBonus: "下线Pass Up奖金",
  comingSoon: "敬请期待",
  home: "主页",
  sharing: "分享",
  orders: "订单",
  pendingApproval: "等待批准中.",
  me: "我",
  upLine: "上线",
  phoneNo: "电话号码",
  call: "拨打",
  callHaku: "拨电 Hushlab",
  emailHaku: "电邮 Hushlab",
  sharingMaterial: "资料分享",
  gallery: "素材包",
  retailSharing: "零售分享",
  gotoRetail: "查看产品目录",
  approveOrder: "批准订单",
  waitingCompanyApproval: "公司审核中",
  waiting: "审核中",
  approval: "",
  shareLink: "分享链接",
  aboutUs: "关于我们",
  brand: "品牌故事",
  gotoAbout: "浏览关于我们",
  myOrders: "我的订购单",
  partnerOrder: "伙伴订购单",
  noOrders: "暂时无订购单 :(",
  myNetwork: "我的组织",
  walletsNbonuses: "钱包与奖金",
  viewMore: "显示更多",
  showLess: "显示更少",
  cashW: "钱包",
  myRBVoucher: "我的RB Voucher",
  mmsReward: "MMS Reward",
  monthlyPSR: "每月拓展奖金",
  chooseShippingMethod: "请选择运输方式",
  cbR: "CB Points",
  back: "返回",
  package: "配套",
  minOrder: "最低数量",
  unitP: "单价 ",
  orderQ: "订购量",
  qtt: "数量",
  monthlyM: "10代奖金池（MMS）",
  monthlyMalert: "您这个月还没补货",
  selProduct: "选择产品",
  stockB: "库存余额",
  regBusinessP: "注册生意伙伴",
  refLink: "分享推荐链接：",
  copyLink: "复制链接",
  emptyAmount: "请输入数额",
  minimumBalance: "您必须保留至少RM10在钱包内。",
  balanceInsufficient: "余额不足!",
  withdrawCash: "提取现金",
  cashWithdrawal: "提取现金",
  withdrawDesc: "钱包余额将在每个月的最后一天发出。",
  noRecord: "暂无记录",
  RBVoucher: "RB卷",
  amount: "数额",
  cancelOrder: "取消订单",
  mmsOrderMistake: "订单有误吗？取消订单后可重新下单哦！",
  cancelled: "已取消",
  RBDesc: "RB Voucher 可用来回购",
  redeemV: "认领回扣卷",
  value: "价值",
  totalValue: "总价值",
  RBDesc2: "每次补货将获得RB Voucher 奖励:",
  RBDesc3:
    "You will be rewarded 10% RB of the amount of stocks you purchase, with a worth of 30% of the unit price.",
  RBDesc4:
    "E.g. When restocking 600 boxes at the price of RM111/box, you will receive 60 RB vouchers, each worth RM 33.30",
  close: "关闭",
  mmsR: "每月总结MMS奖金",
  totMain: "补货总销量 (盒)",
  thisMonthB: "本月奖金",
  mmsRewardalert:
    "You haven't maintain sales this month! Maintain sales now to earn MMS Rewarads!",
  mainSalesNow: "该补货啦！",
  monthlyPSR2: "每月总结MPS奖金",
  groupSalesR: "团队销售量 (销售额)",
  groupSalesB: "团队销售量 (盒)",
  bonus: "奖金",
  mallAlert: "商城即将到来",
  gotoMall: "游览商城 (即将到来)",
  stockP: "购货",
  cashB: "现金回扣 (CB)",
  cashB1: "CB(s) are rewarded when you purchase stocks as an agent.",
  cashB2: "20CB(s) are rewarded for each unit you purchase. (1CB = RM1)",
  cashB3: "You may use CB to shop from our mall!",
  yourP: "你的伙伴",
  noPart: "暂无伙伴",
  submittingOrder: "下单中。。。",
  network: "我的团队",
  perInfo: "个人资料",
  name: "名字",
  newAddress: "添加新地址",
  address: "地址",
  addAddress: "添加地址",
  pleaseCompleteForm: "请完成表格。",
  addingAddress: "添加地址中。。。",
  addressAdded: "地址成功添加！",
  fullName: "全名",
  userName: "用户名",
  weChat: "微信号",
  wa: "WhatsApp 号",
  bankD: "银行详情",
  bankName: "银行名字",
  bankAccNo: "银行户口号",
  bankAccName: "银行户口名",
  addBook: "地址",
  myAdd: "我的地址",
  others: "其它 ",
  TnC: "条款与条规",
  priP: "隐私政策",
  signOut: "登出",
  shipmentHistory: "发货记录",
  viewShipment: "发货记录 ",
  noShipment: "没有发货记录",
  orderID: "订单号",
  copyClipB: "已成功复制!",
  prodMenu: "产品目录",
  noReferral: "需有推荐人才可登入",
  referredBy: "推荐人: ",
  userNotExist: "用户不存在",
  noInternetConnection: "没有网络",
  enterEmail: "请输入电子邮件",
  recipientName: "收件人名称",
  enterQuantity: "请输入数量",
  cannotExceed: "不可超过",
  deliveryInformation: "运输资料",
  enterPassword: "密码必须拥有六个字母以上",
  emailRegistered: "电子邮件已被使用！",
  readyToJoin: "准备好加入\nHushlab了吗?",
  setupAcc: "简单设置好账号后便可马上开始了哦！",
  letsGo: "开始吧!",
  approved: "已批准",
  cancelled: "已取消",
  enterProduct: "请选择产品",
  pending: "等待审核",
  backToSignIn: "回到登入页面",
  useVoucher: "使用优惠卷",
  selectPackage: "选择配套",
  chooseCountry: "选择国家",
  useVoucher: "使用优惠卷",
  contactDetails: "联络方式",
  deliveryAddress: "邮寄地址",
  bankDetails: "银行户口",
  next: "下一步",
  uploading: "上传中。。。请勿刷新页面！",
  enterBankName: "请输入银行名称",
  enterBankAccName: "请输入银行户口拥有人名称",
  enterBankAccNo: "请输入银行户口号码",
  profileUploaded: "照片已成功上传",
  changeProfilePicture: "更换头像",
  chooseOffer: "选择优惠",
  enterName: "请输入您的名字",
  agentStockBalance: "的库存",
  quantityCannotZero: "数量不可为零！",
  agentInsufficientStock: "代理库存不足！请联系代理补货！",
  noAgentDetected:
    "无法侦测代理！请重新点击代理链接，或者联系代理重新发送链接。",
  enterPhone: "请输入您的电话号码",
  savingChanges: "正在保存...",
  changesSaved: "已保存",
  saveAddress: "保存地址",
  enterAddress: "请输入地址",
  enterCity: "请输入城市",
  enterState: "请输入州属",
  enterPostcode: "请输入邮政码",
  enterShipping: "请选择邮寄方式",
  enterBankName: "请输入银行名称",
  enterBankAccName: "请输入银行户口拥有人名称",
  enterBankAccNo: "请输入银行户口",
  chooseAddress: "请选择地址",
  voucher: "优惠卷",
  bigVoucher: "大礼包卷",
  searchVoucher: "搜索大礼包卷",
  enterValidVoucher: "请输入正确的优惠卷",
  halfTicket: "招募金卷",
  checkTicket: "搜索招募金卷",
  ticketUsed: "优惠卷错误或已被使用",
  ticketError: "优惠卷错误",
  usernameTip:
    "请输入独特的账户号以方便推荐朋友加入! **账户号一旦设置后将不可更改.",
  usernameTaken: "账户号已被使用，请尝试别的！",
  icWarning: "请输入正确的身份证号码。错误的身份证号码将导致提款失败！",
  icTaken: "身份证已被注册！",
  secondPassword: "二级密码",
  secondPasswordTip: "提款或下单买货时将需要使用二级密码",
  backToSelectPackage: "回去选择配套",
  city: "城市",
  state: "州属",
  postcode: "邮政号",
  backToContact: "回去个人资料",
  yourName: "你的名字",
  backToDelivery: "回去邮寄地址",
  selectedPackage: "已选择配套",
  package: "配套",
  total: "总共",
  changeSecondPassword: "更换二级密码",
  backToBankDetails: "回去银行详情",
  confirmSecondPass: "确认二级密码",
  repeatSecondPass: "重复二级密码",
  secondPasswordIncorrec: "二级密码错误",
  stillHavePendingOrder: "请先通知上线批准未批准订单！",
  summary: "总结",
  submitOrder: "下单",
  mmsOrder: "MMS 消费",
  makingRequest: "正在申请提款。。。",
  withdrawalRequestMade: "提款申请成功！",
  tracking: "包裹追踪",
  trackingCopied: "已复制订单追踪号",
  orderProcessing: "正在进行打包",
  shipmentPending: "订单处理中",
  notEnoughStock: "您的库存不足！请先补货！",
  orderApproved: "成功批准订单",
  payment: "付款方式",
  uploadingSlip: "正在上传收据。。。",
  slipUpdated: "收据成功上传！",
  uploadSlip: "上传收据",
  resubmitSlip: "重新上传收据",
  maintainSalesError: "您需要购买两盒以上才可获得奖励。",
  totalAmount: "总数",
  save: "保存",
  orderPlaced: "下单成功！",
  mmsWarning: "您的订单需被批准后才可获得MMS奖励。",
  mmsSuccess: "点击查看MMS奖励。",
  mmsTips: "每月自己消费两盒将可获得MMS奖励！",
  you: "你",
  sPartner: " 的伙伴",
  rbRedeemHistory: "RB卷使用记录",
  selectAVoucher: "选择RB卷",
  noRbVoucher: "你没有RB卷！",
  replenishPrice: "补货价",
  notEnoughVoucher: "你没有足够的RB卷！",
  chooseArea: "请选择邮寄地区",
};
