import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  border-color: ${(props) => props.theme.lightGreyColor};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.p`
  padding: 16px 16px;
  font-size: 14px;
  padding-bottom: 8px;
`;
const Container = styled.input`
  padding: 16px 8px;
  border-style: none;
  padding-bottom: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  ::placeholder {
    color: ${(props) => props.theme.lightBlackColor};
    font-weight: 400;
  }
`;

export default ({ label, placeholder, onChange, type = "text", value }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Container
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};
