import React, { useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Back from "../../components/Back";
import { PRODUCT_ORDER_DETAIL, RB_ORDER_DETAILS } from "../../query/Orders";
import Loader from "../../components/Loader";
import { auth, storage } from "firebase";
import WaitingApprovalStatus from "../../components/WaitingApprovalStatus";
import ApprovedStatus from "../../components/ApprovedStatus";
import { FormatTime, CommaFormatted } from "../../utilities";
import PaymentPending from "../../components/PaymentPending";
import PaymentPaid from "../../components/PaymentPaid";
import Button from "../../components/Button";
import ImageUploader from "../../components/ImageUploader";
import { toast } from "react-toastify";
import {
  UPDATE_SLIP,
  UPDATE_PRODUCT_ORDER_SLIP,
  UPDATE_RB_ORDER_SLIP,
} from "../../mutations/Orders";
import TrackingInfo from "../../components/TrackingInfo";
import { LanguageStore } from "../../context/LanguageStore";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 15vh;
`;
const BackWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 470px;
  left: 50%;
  padding: 12px 20px;
  background-color: rgba(256, 256, 256, 0.6);

  transform: translate(-50%, 0%);
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.lightGreyColor};
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 12px;
  width: 100%;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
`;

const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;
const Container = styled.div`
  background-color: white;
  margin-bottom: 8px;
  padding: 20px;
`;

const DetailsWrapper = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TotalPrice = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  margin-top: 8px;
  text-align: right;
`;
const Slip = styled.img`
  object-fit: contain;
`;

const OriPrice = styled.p`
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  text-decoration: right;
  margin-bottom: 8px;
`;

const Savings = styled.p`
  ${(props) => props.theme.title};
  font-size: 14px;
  margin-top: 8px;
  color: ${(props) => props.theme.mainGreenColor};
`;

export default ({ history, location }) => {
  let orderId = location.search.split("=")[1];
  let order = {};
  let master = {};
  let totalPrice = "";
  let totalPriceWithDeliveryRate = "";
  const { dictionary } = useContext(LanguageStore);
  const [updateSlip] = useMutation(UPDATE_RB_ORDER_SLIP);
  const { data, loading, refetch, error } = useQuery(RB_ORDER_DETAILS, {
    variables: {
      order_id: orderId,
      uid: auth().currentUser.uid,
    },
  });
  if (data) {
    order = data.rb_orders[0];
    master = data.master[0];
    totalPrice =
      data.rb_orders[0].product_order_shipping.shipping_rate_country.currency +
      " " +
      CommaFormatted(
        data.rb_orders[0].replenish_price.toFixed(2) * order.quantity
      );
    totalPriceWithDeliveryRate =
      data.rb_orders[0].product_order_shipping.shipping_rate_country.currency +
      " " +
      CommaFormatted(
        data.rb_orders[0].replenish_price.toFixed(2) * order.quantity +
          data.rb_orders[0].shipping_rate
      );
  }
  const checkStatus = () => {
    switch (data.rb_orders[0].status) {
      case "Pending":
        return <WaitingApprovalStatus />;
      case "Approved":
        return <ApprovedStatus />;
    }
  };

  const checkPayment = () => {
    switch (data.rb_orders[0].payment_slip) {
      case null:
        return <PaymentPending />;
      default:
        return <PaymentPaid />;
    }
  };

  const submitSlip = (e) => {
    toast.warning(dictionary.uploadingSlip);
    let imgRef = storage().ref(
      "payment_slip/" +
        auth().currentUser.uid +
        "/rb_order/" +
        order.id +
        "/slip.png"
    );
    let storingImg = imgRef.put(e.target.files[0]);
    storingImg.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      () => {
        storingImg.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          updateSlip({
            variables: {
              id: order.id,
              url: downloadUrl,
            },
          })
            .then(() => {
              toast.dismiss();
              refetch();
              toast.success(dictionary.slipUpdated);
            })
            .catch((e) => {
              toast.dismiss();
              toast.error(e.message);
            });
        });
      }
    );
  };

  return (
    <Wrapper>
      <BackWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </BackWrapper>
      {loading && data === undefined && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {data && data.rb_orders.length > 0 ? (
        <PageWrapper>
          <Container>
            <StatusContainer>{data && checkStatus()}</StatusContainer>
          </Container>
          <Container>
            <Header>
              <Time>
                {dictionary.orderID}: {order.id}
              </Time>
              <Time>{data && FormatTime(order.created_at)}</Time>
            </Header>
            <Row>
              <Left>
                {order.products ? (
                  <>
                    <img
                      src={order.products.product_img}
                      height={60}
                      width={60}
                      style={{
                        borderRadius: 24,
                        marginRight: 8,
                        marginTop: 12,
                        marginBottom: 12,
                      }}
                    />
                    <DetailsWrapper>
                      <Name>{data && order.products.name}</Name>
                      <Quantity>
                        <Price>x {data && order.quantity}</Price>
                      </Quantity>
                    </DetailsWrapper>
                  </>
                ) : (
                  JSON.parse(order.multiproduct).map((pro, index) => (
                    <>
                      <img
                        key={index}
                        src={pro.img}
                        height={60}
                        width={60}
                        style={{
                          borderRadius: 24,
                          marginRight: 8,
                          marginTop: 12,
                          marginBottom: 12,
                        }}
                      />
                      <DetailsWrapper>
                        <Name>{data && pro.name}</Name>
                        <Quantity>
                          <Price>x {data && pro.quantity}</Price>
                        </Quantity>
                      </DetailsWrapper>
                    </>
                  ))
                )}
              </Left>
              <Right>
                <OriPrice>
                  {!loading &&
                    data &&
                    data.rb_orders.length > 0 &&
                    data.rb_orders[0].rb_package &&
                    data.rb_orders[0].product_order_shipping
                      .shipping_rate_country.currency +
                      " " +
                      data.rb_orders[0].rb_package.unit_price +
                      "/box"}
                </OriPrice>
                <Price>
                  {!loading &&
                    data &&
                    data.rb_orders.length > 0 &&
                    data.rb_orders[0].product_order_shipping
                      .shipping_rate_country.currency +
                      " " +
                      data.rb_orders[0].replenish_price.toFixed(2) +
                      "/box x " +
                      order.quantity +
                      " = "}
                </Price>
                <TotalPrice>
                  {!loading && data && data.rb_orders.length > 0 && totalPrice}
                </TotalPrice>
                <Savings>
                  {dictionary.save + " "}
                  {!loading &&
                    data &&
                    data.rb_orders.length > 0 &&
                    data.rb_orders[0].rb_package &&
                    data.rb_orders[0].product_order_shipping
                      .shipping_rate_country.currency +
                      " " +
                      CommaFormatted(
                        (data.rb_orders[0].rb_package.unit_price -
                          data.rb_orders[0].replenish_price.toFixed(2)) *
                          order.quantity
                      )}
                </Savings>
              </Right>
            </Row>
          </Container>
          <Container>
            <Header>
              <Title>{dictionary.deliveryInformation}</Title>
            </Header>
            <DetailsWrapper>
              <Row>
                <Left>
                  <Name>{order.product_order_shipping.name}</Name>
                  <Quantity>
                    <Price>{order.address}</Price>
                  </Quantity>
                  <Quantity>
                    {dictionary.city}: <Price>{order.city}</Price>
                  </Quantity>
                  <Quantity>
                    {dictionary.state}: <Price>{order.state}</Price>
                  </Quantity>
                  <Quantity>
                    {dictionary.postcode}: <Price>{order.postcode}</Price>
                  </Quantity>
                </Left>
                <Right>
                  <Name>
                    {
                      order.product_order_shipping.shipping_rate_country
                        .currency
                    }{" "}
                    {order.shipping_rate}
                  </Name>
                </Right>
              </Row>
            </DetailsWrapper>
          </Container>
          {!loading && data && data.rb_orders && data.rb_orders.length > 0 && (
            <Container>
              <Header>
                <Title>{dictionary.payment}</Title>
                {checkPayment()}
              </Header>
              <DetailsWrapper>
                <Row>
                  <Left>
                    <Name>{data.master[0].bank_acc_name}</Name>
                    <Quantity>
                      {dictionary.bankAccName}:{" "}
                      <Price>{data.master[0].bank_acc_no}</Price>
                    </Quantity>
                    <Quantity>
                      {dictionary.bankName}:{" "}
                      <Price>{data.master[0].bank_name}</Price>
                    </Quantity>
                  </Left>
                  {order.payment_slip ? (
                    <Right>
                      <Slip src={order.payment_slip} height={60} width={60} />
                    </Right>
                  ) : (
                    <></>
                  )}
                </Row>
                <TotalPrice>
                  {dictionary.totalAmount}: {totalPriceWithDeliveryRate}{" "}
                </TotalPrice>
                <ImageUploader
                  placeholder={
                    order.payment_slip
                      ? dictionary.resubmitSlip
                      : dictionary.uploadSlip
                  }
                  onChange={submitSlip}
                />
              </DetailsWrapper>
            </Container>
          )}
          <TrackingInfo
            tracking_id={data && order.tracking_id}
            tracking_link={data && order.tracking_link}
            courier={data && order.courier}
            shipped={data && order.shipped}
            shipped_at={data && order.shipped_at}
          />
        </PageWrapper>
      ) : (
        <LoadingWrapper>
          {!loading && <Quantity>Can't find anything here</Quantity>}
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};
