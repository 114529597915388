import React, { useContext } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import { useQuery } from "@apollo/react-hooks";
import { GET_IMAGE_COUNT, GET_GALLERY_COLLECTION } from "../query/Gallery";
import Back from "../components/Back";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 20px;
  padding-top: 70px;
`;

const Box = styled.div`
  width: 100%:
    padding: 8px 12px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${(props) => props.theme.lightBlackColor};
    :last-child {
        border-style: none;
    }
    :hover{
        opacity: 0.7;
    }
`;
const BigText = styled.h1`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.darkBlueColor};
`;

export default ({ history }) => {
  const { data, loading } = useQuery(GET_GALLERY_COLLECTION);
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper>
      <PageTitle title={dictionary.sharingMaterial} />
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      {!loading && data && data.gallery_collection.length > 0 ? (
        data.gallery_collection.map((item, index) => {
          return (
            <Box
              onClick={() => {
                history.push("/gallery_images?collection_id=" + item.id);
              }}
            >
              <BigText>{item.name}</BigText>
            </Box>
          );
        })
      ) : (
        <BigText>No gallery yet :(</BigText>
      )}
    </Wrapper>
  );
};
