import React, { useContext, useState } from "react";
import styled from "styled-components";
import { MdSettings } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import BlueHead from "../components/Images/BlueHead.png";
import { useQuery } from "@apollo/react-hooks";
import { USER_INFO } from "../query/Users";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { CommaFormatted } from "../utilities";
import ProfileRowList from "../components/Profile/ProfileRowList";
import { FaBox } from "react-icons/fa";
import { IoMdCash } from "react-icons/io";
import cb from "../components/Images/Bonuses/cb.jpeg";
import mps from "../components/Images/Bonuses/mps.jpeg";
import mms from "../components/Images/Bonuses/mms.jpeg";
import drb from "../components/Images/Bonuses/drb.jpeg";
import rb from "../components/Images/Bonuses/rb.jpeg";
import certificate from "../components/Images/Bonuses/certificate.jpeg";
import { LanguageStore } from "../context/LanguageStore";
import { toast } from "react-toastify";
import ThemeBG from "../components/Images/ThemeBG.png";
import MemberBonusDetails from "../components/MemberBonusDetails";
// import { generatePdf } from "../components/Certificate";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.subColor};
`;

const Header = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.subColor};
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 80px;
`;
const TouchableIcon = styled.div`
  color: white;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const AvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 32px;
  border-width: 5px;
  border-color: white;
  border-style: solid;
  margin-top: -40px;
  background-color: ${(props) => props.theme.mainColor};
`;

const NetworkButton = styled.div`
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  transform: translate(-5%, -200%);
  position: absolute;
  top: 0;
  right: 0;
  color: ${(props) => props.theme.mainColor};
  background-color: white;
  margin-top: 8px;
  :hover {
    background-color: ${(props) => props.theme.mainColor};
    color: white;
  }
`;

const SummaryButton = styled.div`
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  color: white;
  background-color: ${(props) => props.theme.darkBlueColor};
  margin-top: 8px;
  :hover {
    background-color: ${(props) => props.theme.mainColor};
    color: white;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
`;

const Name = styled.div`
  font-weight: 800;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.darkBlueColor};
  font-size: 18px;
`;
const Rank = styled.div`
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 18px;
`;
const NameWrapper = styled.div`
  padding: 12px 20px;
  width: 100%;
`;

const UsernameTag = styled.span`
  padding: 4px 8px;
  color: ${(props) => props.theme.lightBlackColor};
  text-align: center;
  background-color: ${(props) => props.theme.lightGreyColor};
  font-size: 11px;
  border-radius: 12px;
  width: 80%;
`;

const PageTitle = styled.h1`
  ${(props) => props.theme.title};
  font-weight: 800;
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const PageTitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ContentContainer = styled.div`
  width: 100%;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  min-height: calc(100vh - 80px);
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Figure = styled.h4`
  ${(props) => props.theme.title};
  font-size: 18px;
  margin: 6px 0px;
`;

const FigureText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.lightBlackColor};
`;
export default ({ history }) => {
  let date = new Date();
  const [showDetails, setShowDetails] = useState(null);
  const { data, loading, error } = useQuery(USER_INFO, {
    variables: {
      id: auth().currentUser.uid,
    },
  });

  console.log(data, error);
  const { dictionary } = useContext(LanguageStore);
  return !loading ? (
    <Wrapper>
      <Header>
        <TouchableIcon
          onClick={() => {
            history.push("/profile_settings");
          }}
        >
          <MdSettings size={24} />
        </TouchableIcon>
      </Header>
      <ContentContainer>
        <AvatarWrapper>
          <Avatar
            height={80}
            width={80}
            src={(data && data.users_by_pk.avatar) || BlueHead}
          />
          <NetworkButton
            onClick={() => {
              history.push("/network");
            }}
          >
            {dictionary.myNetwork}&nbsp;{" "}
            <FaNetworkWired size={14} style={{ marginLeft: 4 }} />
          </NetworkButton>
        </AvatarWrapper>
        <NameWrapper>
          <Name>
            {data && data.users_by_pk.name}{" "}
            <UsernameTag>{data && data.users_by_pk.username}</UsernameTag>
          </Name>
          <Rank>
            {data &&
              (data.users_by_pk.current_rank_name
                ? data.users_by_pk.current_rank_name +
                  " (" +
                  data.users_by_pk.current_rank_chinese_name +
                  ")"
                : data.users_by_pk.joining_rank_name +
                  " (" +
                  data.users_by_pk.joining_rank_chinese_name +
                  ")")}
          </Rank>
        </NameWrapper>
        <ContentWrapper>
          {/* <StatsWrapper>
          <StatsContainer>
            <Figure>{data && (data.users_by_pk.stock_balance || 0)}</Figure>
            <FigureText>stocks</FigureText>
          </StatsContainer>
          <StatsContainer>
            <Figure>
              RM{" "}
              {data &&
                (data.cash_balance_aggregate.aggregate.sum.amount
                  ? CommaFormatted(
                      data.cash_balance_aggregate.aggregate.sum.amount
                    )
                  : 0)}
            </Figure>
            <FigureText>cash wallet</FigureText>
          </StatsContainer>
          <StatsContainer>
            <Figure>{data && (data.users_by_pk.downline_count || 0)}</Figure>
            <FigureText>downlines</FigureText>
          </StatsContainer>
        </StatsWrapper> */}
          <PageTitleHeader>
            <PageTitle>{dictionary.walletsNbonuses}</PageTitle>
            <SummaryButton
              onClick={() => {
                setShowDetails({
                  id: auth().currentUser.uid,
                  name: data.users_by_pk.name,
                  avatar: data.users_by_pk.avatar,
                  phone: data.users_by_pk.phone,
                  email: data.users_by_pk.email,
                  username: data.users_by_pk.username,
                  joining_rank: {
                    chinese_name: data.users_by_pk.joining_rank_chinese_name,
                  },
                  current_rank: data.users_by_pk.current_rank_name,
                });
              }}
            >
              {dictionary.viewSummary}
            </SummaryButton>
          </PageTitleHeader>
          <ProfileRowList
            onClick={() => {
              history.push("/cash_wallet");
            }}
            icon={<IoMdCash size={18} />}
            name={dictionary.cashW}
          />
          <ProfileRowList
            onClick={() => {
              history.push("/monthly_performance_sales_reward");
            }}
            icon={<img src={mps} height={18} width={18} />}
            name={dictionary.monthlyPSR}
          />
          {/* <ProfileRowList
          onClick={() => {
            history.push("/stock_balance");
          }}
          icon={<FaBox size={18} />}
          name="Stock Balance"
        /> */}
          <ProfileRowList
            onClick={() => {
              history.push("/rb_records");
            }}
            icon={<img src={rb} height={18} width={18} />}
            name={dictionary.myRBVoucher}
          />
          {/* {!loading &&
            data &&
            data.user_current_rank.length > 0 &&
            data.user_current_rank[0].user_rank.drb_reward && (
              <ProfileRowList
                onClick={() => {
                  history.push("/drb_history");
                }}
                icon={<img src={drb} height={18} width={18} />}
                name="My Daily Rebate Bonus (DRB)"
              />
            )} */}
          {/* <ProfileRowList
            onClick={() => {
              history.push("/cb");
            }}
            icon={<img src={cb} height={18} width={18} />}
            name={dictionary.cbR}
          /> */}
          {/* <PageTitle>{dictionary.others}</PageTitle>
          <ProfileRowList
            icon={<img src={certificate} height={18} width={18} />}
            onClick={() => {
              toast.warning("Generating Certificate...");
              console.log(data.users_by_pk);
              generatePdf({
                companyName: data.master[0].company_name,
                name: data.users_by_pk.name,
                rankName:
                  data.users_by_pk.current_rank_name ||
                  data.users_by_pk.joining_rank_name,
              });
              toast.dismiss();
              toast.success("Certificate saved!");
            }}
            name="Certificate"
          /> */}
        </ContentWrapper>
      </ContentContainer>
      {showDetails !== null && (
        <MemberBonusDetails
          user={showDetails}
          close={() => {
            setShowDetails(null);
          }}
        />
      )}
    </Wrapper>
  ) : (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  );
};
