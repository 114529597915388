import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/react-hooks";
import { GET_MY_ORDERS } from "../query/Orders";
import { auth } from "firebase";
import MyOrder from "../components/Orders/MyOrder";
import DownlineOrder from "../components/Orders/DownlineOrder";
import Loader from "../components/Loader";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBG from "../components/Images/ThemeBG.png";
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  padding-top: 110px;
  padding-bottom: 15vh;
  height: 100vh;
  overflow-y: auto;
  background-size: cover;
  background-image: url(${ThemeBG});
`;

const SelectorWrapper = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: fixed;
  top: 70px;
  background-color: white;
  max-width: 470px;
  box-shadow: 0 4px 12px -8px rgba(0, 0, 0, 0.3);
`;

const SelectorContainer = styled.div`
  padding: 0px 8px;
`;
const Selector = styled.div`
  opacity: ${(props) => props.opacity};
  ${(props) => props.theme.title};
  font-size: ${(props) => props.fontSize}px;
  transition: 0.2s;
  border-style: solid;
  border-bottom-width: 4px;
  padding-bottom: 8px;
  cursor: pointer;
  border-color: ${(props) => props.theme.mainColor};
`;

const OrdersWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export default ({ location }) => {
  let checkdownline = location.search.split("=")[1];
  //true = downline's order
  const [action, setAction] = useState(checkdownline === "approval");
  const { data, loading, error, refetch } = useQuery(GET_MY_ORDERS, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });
  const { dictionary } = useContext(LanguageStore);

  useEffect(() => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Wrapper>
      <PageTitle title={dictionary.orders} bgColor="white" />
      <SelectorWrapper>
        <SelectorContainer>
          <Selector
            onClick={() => {
              setAction(false);
            }}
            opacity={action ? 0.5 : 1}
            fontSize={action ? 18 : 20}
          >
            {dictionary.myOrders}
          </Selector>
        </SelectorContainer>
        <SelectorContainer>
          <Selector
            onClick={() => {
              setAction(true);
            }}
            opacity={action ? 1 : 0.5}
            fontSize={action ? 20 : 18}
          >
            {dictionary.partnerOrder}
          </Selector>
        </SelectorContainer>
      </SelectorWrapper>
      {loading && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {!loading &&
        data &&
        (action ? (
          <OrdersWrapper>
            {data && data.package_order.length > 0 ? (
              data.package_order.map((order, index) => {
                return (
                  <DownlineOrder
                    key={index}
                    buyer_id={order.buyer_id}
                    status={order.status}
                    id={order.id}
                    package_chinese_name={order.order_package.chinese_name}
                    package_name={order.order_package.name}
                    quantity={order.quantity}
                    selling_price={order.selling_price}
                    created_at={order.created_at}
                    currency={order.package_country.currency}
                    buyer_avatar={order.user.avatar}
                    buyer_name={order.user.name}
                    big_offer={order.order_package.big_offer}
                    system_offer={order.system_offer}
                    half={order.order_package.half}
                  />
                );
              })
            ) : (
              <p>{dictionary.noOrders}</p>
            )}
          </OrdersWrapper>
        ) : (
          <OrdersWrapper>
            {data && data.users_by_pk.package_orders.length > 0 ? (
              data.users_by_pk.package_orders.map((order, index) => {
                return (
                  <MyOrder
                    key={index}
                    status={order.status}
                    id={order.id}
                    package_chinese_name={order.order_package.chinese_name}
                    package_name={order.order_package.name}
                    quantity={order.quantity}
                    selling_price={order.selling_price}
                    created_at={order.created_at}
                    currency={order.package_country.currency}
                    system_offer={order.system_offer}
                    big_offer={order.order_package.big_offer}
                    half={order.order_package.half}
                  />
                );
              })
            ) : (
              <p>You have no previous orders!</p>
            )}
          </OrdersWrapper>
        ))}
    </Wrapper>
  );
};
