import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    *{
        font-family: ${(props) => props.theme.mainFont};
        box-sizing: border-box;
        box-sizing: border-box;
        ::-webkit-scrollbar {
            width: 2px; /* Remove scrollbar space */
          }
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none;
          /* Optional: show position indicator in red */
          ::-webkit-scrollbar-thumb {
            height: 50%;
            border-radius: 8px;
            background: ${(props) => props.theme.darkGreyColor};
          }
    }
    body{
        font-family: ${(props) => props.theme.mainFont};
        background-color: #ffffff;
    }
    input:placeholder{
        color: ${(props) => props.theme.darkGreyColor};
    }
    input:focus{
        outline: none;
    }
    strong{
        font-weight: 800;
    }
    em{
        font-style: italic;
    }
`;
