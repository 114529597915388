import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { GET_PRODUCT_ORDERS } from "../query/Orders";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { FormatTime } from "../utilities";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 70px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoOrder = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  font-family: ${(props) => props.theme.mainFont};
`;

const BackgroundWrapper = styled.div`
  background-color: ${(props) => props.theme.lightGreyColor};
  width: 100%;
`;

const OrderWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
  cursor: pointer;
  margin-bottom: 8px;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0px;
  justify-content: space-between;
`;
const Date = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Name = styled.h1`
  color: ${(props) => props.theme.darkBlueColor};
  font-weight: 600;
  font-size: 16px;
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ProductImage = styled.img`
  border-radius: 24px;
  height: 60px;
  width: 60px;
  margin-right: 8px;
`;

const PendingText = styled.h1`
  color: ${(props) => props.theme.orangeColor};
  font-size: 12px;
  font-weight: 600;
`;
const ApprovedText = styled.h1`
  color: ${(props) => props.theme.mainColor};
  font-size: 12px;
  font-weight: 600;
`;
const CancelledText = styled.h1`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 12px;
  font-weight: 600;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export default ({ history }) => {
  const { data, loading } = useQuery(GET_PRODUCT_ORDERS, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });
  const { dictionary } = useContext(LanguageStore);

  const checkStatus = (status) => {
    switch (status) {
      case "Pending":
        return <PendingText>Pending</PendingText>;
      case "Approved":
        return <ApprovedText>Approved</ApprovedText>;
      case "Cancelled":
        return <CancelledText>Cancelled</CancelledText>;
    }
  };
  return (
    <Wrapper>
      <PageTitle title={dictionary.shipmentHistory} />
      <PageWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </PageWrapper>
      {loading && data === undefined && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {!loading && data && data.product_order.length > 0 ? (
        <BackgroundWrapper>
          {data.product_order.map((order, index) => {
            return (
              <OrderWrapper
                onClick={() => {
                  history.push("/shipment_details?order_id=" + order.id);
                }}
              >
                <Header>
                  {checkStatus(order.status)}
                  <Date>{FormatTime(order.created_at)}</Date>
                </Header>
                <BodyWrapper>
                  <Name>x {order.quantity} boxes</Name>
                </BodyWrapper>
              </OrderWrapper>
            );
          })}
        </BackgroundWrapper>
      ) : (
        <LoadingWrapper>
          <NoOrder>{dictionary.noShipment}</NoOrder>
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};
