import gql from "graphql-tag";

export const GET_CB = gql`
  query getCB($id: String) {
    cb_record_add(where: { user_id: { _eq: $id } }) {
      created_at
      cb_value
      user_id
    }
    cb_record_add_aggregate(
      where: { user_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      aggregate {
        sum {
          cb_value
        }
      }
    }
  }
`;

export const GET_DRB_RECORD = gql`
  query getDRB($user_id: String!) {
    drb_record(
      where: { user_id: { _eq: $user_id } }
      order_by: { created_at: desc }
    ) {
      user_id
      id
      value
      created_at
    }
    users_by_pk(id: $user_id) {
      user_country {
        currency
        country_packages(
          where: { drb_reward: { _eq: true }, status: { _eq: "active" } }
        ) {
          name
          id
          min
          system_offer
          chinese_name
          unit_price
        }
      }
    }
    drb_record_aggregate(where: { user_id: { _eq: $user_id } }) {
      aggregate {
        sum {
          value
        }
      }
    }
  }
`;

export const GET_CASH = gql`
  query getCash($id: String!) {
    users_by_pk(id: $id) {
      id
      secret_key
      user_country {
        currency
      }
    }
    country {
      name
      id
      conversion
      currency
    }
    cash_wallet_records(
      where: { user_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      created_at
      amount
      type
      details
      status
    }
    cash_wallet_records_aggregate(
      where: { user_id: { _eq: $id }, status: { _eq: "Approved" } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const CHECK_MMS = gql`
  query checkMMS($id: String!, $month: float8!) {
    mms_by_month(where: { month: { _eq: $month }, user_id: { _eq: $id } }) {
      address
      created_at
      month
      order_id
      payment_slip
      phone
      product_id
      quantity
      recipient_name
      status
      user_id
      shipping_rate
      tracking_id
      multiproduct
      tracking_link
      shipped
      shipped_at
      courier
      retail_order_product {
        product_img
        name
      }
    }
    users_by_pk(id: $id) {
      id
      user_country {
        retail_price
        id
        currency
        country_packages(where: { mms_price: { _eq: true } }) {
          unit_price
          pay_company
          unit
          id
        }
      }
      secret_key
      upline
      user_addresses {
        name
        postcode
        city
        state
        line
        id
      }
    }
    master {
      bank_acc_name
      bank_acc_no
      bank_name
      id
    }
  }
`;

export const GET_MMS = gql`
  query getMMS($id: String!, $month: float8!, $year: float8!) {
    mms_bonus_total_quantity(
      where: { id: { _eq: $id }, month: { _lt: $month }, year: { _lte: $year } }
    ) {
      pv
      month
      qualified
      total_quantity
      id
      bonus
    }
    mms_bonus_total_quantity_aggregate(
      where: { id: { _eq: $id }, month: { _eq: $month }, year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_quantity
          pv
          bonus
        }
      }
    }
    users_by_pk(id: $id) {
      user_country {
        id
        currency
      }
      id
    }
    mms_qualify(
      where: {
        user_id: { _eq: $id }
        month: { _eq: $month }
        year: { _eq: $year }
      }
    ) {
      can_get
      user_id
      month
    }
    mms_record(
      order_by: { year: desc, month: desc }
      where: { id: { _eq: $id }, month: { _lt: $month }, year: { _lte: $year } }
    ) {
      bonus
      month
      pv
      total_quantity
      year
    }
  }
`;
export const GET_STOCKS = gql`
  query getUserStocks($id: String!) {
    stock_record(
      where: { user_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      created_at
      add
      item
      order_id
      quantity
      status
      user_id
    }
    users_by_pk(id: $id) {
      id
      stock_balance
      secret_key
      user_country {
        id
        currency
      }
    }
  }
`;

export const GET_RB = gql`
  query getRB($id: String!) {
    check_rb_balance(
      where: { user_id: { _eq: $id } }
      order_by: { unit_price: asc }
    ) {
      package_name
      package_chinese_name
      quantity
      total_value
      unit_price
      user_id
      package_id
    }
    check_rb_balance_aggregate(where: { user_id: { _eq: $id } }) {
      aggregate {
        sum {
          total_value
        }
      }
    }
  }
`;

export const CHECK_MPS = gql`
  query getMPS($id: String!, $month: float8!, $year: float8!) {
    mds_bonus_v3_net(
      order_by: { closing_date: desc }
      where: { user_id: { _eq: $id } }
    ) {
      month
      year
      quantity
      personal_bonus
      closing_date
      level
    }
    group_sales_figure(
      where: { id: { _eq: $id }, month: { _eq: $month }, year: { _eq: $year } }
    ) {
      group_sales
      group_sales_quantity
      currency
      id
    }
    mps_second_final(
      order_by: { year: desc, month: desc }
      where: { month: { _lt: $month }, year: { _lte: $year }, id: { _eq: $id } }
    ) {
      currency
      final_bonus
      id
      month
      year
      quantity
    }
    mps_second_final_aggregate(
      where: { year: { _eq: $year }, month: { _eq: $month }, id: { _eq: $id } }
    ) {
      aggregate {
        sum {
          final_bonus
        }
      }
    }
    users_by_pk(id: $id) {
      user_country {
        id
        currency
      }
      id
    }
  }
`;
