import React, { useContext, useState } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import Input from "./Input";
import { LanguageStore } from "../context/LanguageStore";
import Button from "./Button";
import { auth } from "firebase";
import { toast } from "react-toastify";

const Wrapper = styled.form``;

const Description = styled.p`
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  line-height: 22px;
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;
export default ({ close }) => {
  const [email, setEmail] = useState("");
  const { dictionary } = useContext(LanguageStore);
  const [loading, setLoading] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();

    setLoading(true);
    auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false);
        toast.success("Password reset e-mail sent! Please check your mailbox.");
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        if (e.code === "auth/user-not-found") {
          toast.error("You do not have an account!");
        } else if (e.code === "auth/invalid-email") {
          toast.error("Please enter a proper email.");
        } else {
          toast.error(e.message);
        }
      });
  };

  return (
    <AnimatedContainer
      title={dictionary.resetPassword}
      loading={loading}
      close={close}
      content={
        <Wrapper onSubmit={resetPassword}>
          <InputWrapper>
            <Input
              label={dictionary.email}
              value={email}
              placeholder="member@example.com"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Description>{dictionary.resetPasswordTips}</Description>
          </InputWrapper>
          <InputWrapper>
            <Button label={dictionary.resetPassword} onClick={resetPassword} />
          </InputWrapper>
        </Wrapper>
      }
    />
  );
};
