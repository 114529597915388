import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageStore } from "../context/LanguageStore";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.mainColor};
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 8px;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 600;
`;

export default () => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Container>
      <Icon>
        <IoIosCheckmarkCircleOutline size={16} />
      </Icon>
      <Text>{dictionary.paid}</Text>
    </Container>
  );
};
