import React from "react";
import styled, { withTheme } from "styled-components";

const Wrapper = styled.div`
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  font-family: ${(props) => props.theme.mainFont};
  color: white;
  border-style: none;
  cursor: pointer;
  transition: 0.4s;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme.errorRedColor};
  :hover {
    opacity: 0.7;
  }
`;

export default withTheme(({ label, onClick }) => {
  return <Wrapper onClick={onClick}>{label}</Wrapper>;
});
