import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "../routes/Auth";
import ProfileStore from "../context/ProfileContext";
import Dashboard from "../routes/Dashboard";
import AccountSetup from "../routes/AccountSetup";
import Menu from "./Menu";
import Network from "../routes/Network";
import NewUser from "../routes/NewUser";
import Sharing from "../routes/Sharing";
import Profile from "../routes/Profile";
import ProfileSetting from "../routes/ProfileSetting";
import Orders from "../routes/Orders";
import MyOrderDetails from "../routes/Orders/MyOrderDetails";
import DownlineOrderDetails from "../routes/Orders/DownlineOrderDetails";
import RepeatOrder from "../routes/RepeatOrder";
import CbRecords from "../routes/CbRecords";
import CashWallet from "../routes/CashWallet";
import StockRecord from "../routes/StockRecord";
import MMS from "../routes/MMS";
import RequestShipping from "../routes/RequestShipping";
import RbRecord from "../routes/RbRecord";
import ShipmentHistory from "../routes/ShipmentHistory";
import ShipmentDetails from "../routes/Orders/ShipmentDetails";
import RBHistory from "../routes/RBHistory";
import RBOrders from "../routes/RBOrders";
import MPS from "../routes/MPS";
import GalleryCollection from "../routes/GalleryCollection";
import GalleryImage from "../routes/GalleryImage";
import MMSRecord from "../routes/MMSRecord";
import RBDetails from "../routes/Orders/RBDetails";
import DrbRecord from "../routes/DrbRecord";
import About from "../routes/About";
import RetailMenu from "../routes/RetailMenu";
import RetailProduct from "../routes/RetailProduct";
import RetailForm from "../routes/RetailForm";
import RetailSuccess from "../routes/RetailSuccess";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import Culture from "../routes/Culture";
import Activity from "../routes/Activity";

const LoggedInRoute = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route path="/account_set_up" component={AccountSetup} />
    <Route path="/network" component={Network} />
    <Route path="/refer_new" component={NewUser} />
    <Route path="/sharing" component={Sharing} />
    <Route path="/profile" component={Profile} />
    <Route path="/profile_settings" component={ProfileSetting} />
    <Route path="/orders" component={Orders} />
    <Route path="/my_order" component={MyOrderDetails} />
    <Route path="/approve_orders" component={DownlineOrderDetails} />
    <Route path="/repeat_order" component={RepeatOrder} />
    {/* <Route path="/cb" component={CbRecords} /> */}
    <Route path="/cash_wallet" component={CashWallet} />
    <Route path="/stock_balance" component={StockRecord} />
    <Route path="/monthly_maintenance" component={MMS} />
    <Route path="/request_shipping" component={RequestShipping} />
    <Route path="/rb_records" component={RbRecord} />
    <Route path="/shipment_history" component={ShipmentHistory} />
    <Route path="/shipment_details" component={ShipmentDetails} />
    <Route path="/rb_history" component={RBHistory} />
    <Route path="/rb_order" component={RBOrders} />
    <Route path="/monthly_performance_sales_reward" component={MPS} />
    <Route path="/gallery_collection" component={GalleryCollection} />
    <Route path="/gallery_images" component={GalleryImage} />
    <Route path="/mms_record" component={MMSRecord} />
    <Route path="/rb_order_details" component={RBDetails} />
    {/* <Route path="/drb_history" component={DrbRecord} /> */}
    <Route path="/about" component={About} />
    <Route path="/product_menu" component={RetailMenu} />
    <Route path="/product" component={RetailProduct} />
    <Route path="/retail_order" component={RetailForm} />
    <Route path="/order_tracking" component={RetailSuccess} />
    <Route path="/privacy_policy" component={PrivacyPolicy} />
    <Route path="/terms_and_conditions" component={TermsAndConditions} />
    <Route path="/team_culture" component={Culture} />
    <Route path="/brand_activities" component={Activity} />
    <Route from="*" to="/" />
    <Menu />
  </Switch>
);

const LoggedOutRoute = () => (
  <Switch>
    <Route exact path="/" component={Auth} />
    <Route path="/about" component={About} />
    <Route path="/product_menu" component={RetailMenu} />
    <Route path="/product" component={RetailProduct} />
    <Route path="/retail_order" component={RetailForm} />
    <Route path="/order_tracking" component={RetailSuccess} />
    <Route path="/privacy_policy" component={PrivacyPolicy} />
    <Route path="/terms_and_conditions" component={TermsAndConditions} />
    <Route path="/team_culture" component={Culture} />
    <Route path="/brand_activities" component={Activity} />
    <Redirect to="/" />
  </Switch>
);

const AppRouter = ({ isLoggedIn }) => {
  return isLoggedIn ? <LoggedInRoute /> : <LoggedOutRoute />;
};

export default AppRouter;
