import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { IoMdClose } from "react-icons/io";
import { AiFillFacebook, AiOutlineWhatsApp } from "react-icons/ai";
import { IoLogoWhatsapp, IoMdCopy } from "react-icons/io";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { LanguageStore } from "../context/LanguageStore";

const SlideUp = keyframes`
0%{
    transform: translate(0%, 100%);
    background-color: rgba(0,0,0,0);
}
50%{
    background-color: rgba(0,0,0,0);
}
100%{
  transform: translate(0%, 0%);
  background-color: rgba(0,0,0,0.4);
}
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
`;

const Container = styled.div`
  width: 100%;
  max-width: 470px;
  padding: 0px 20px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${SlideUp} 0.6s ease-in;
`;

const CancelButton = styled.div`
  background-color: ${(props) => props.theme.lightGreyColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const Options = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const Option = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  justify-content: center;
  background-color: ${(props) => props.bgColor};
`;
const Section = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 15vh;
`;

const Preview = styled.div`
  background-color: ${(props) => props.theme.lightGreyColor};
  line-height: 24px;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  color: ${(props) => props.theme.lightBlackColor};
`;
export default ({ url, close }) => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper>
      <Container>
        <Section>
          <Preview>{url}</Preview>
        </Section>
        <Section>
          <Options>
            <Option
              bgColor={"#39579A"}
              onClick={() => {
                window.open(
                  "https://www.facebook.com/v3.3/dialog/share?app_id=196681948428929&href=" +
                    url
                );
              }}
            >
              <AiFillFacebook size={24} color={"white"} />
            </Option>
            <Option
              bgColor={"#06D253"}
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send?text=" +
                    encodeURIComponent(url)
                );
              }}
            >
              <IoLogoWhatsapp size={24} color={"white"} />
            </Option>
            <Option
              bgColor={"#f1f3f6"}
              onClick={() => {
                copy(url);
                close();
                toast.success(dictionary.copyClipB);
              }}
            >
              <IoMdCopy size={24} />
            </Option>
          </Options>
          <CancelButton onClick={close}>
            <IoMdClose size={24} />
          </CancelButton>
        </Section>
      </Container>
    </Wrapper>
  );
};
