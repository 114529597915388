import React from "react";
import styled, { withTheme } from "styled-components";

const Wrapper = styled.button`
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  font-family: ${(props) => props.theme.mainFont};
  color: white;
  border-style: none;
  cursor: pointer;
  transition: 0.4s;
  font-size: 14px;
  font-weight: 600;
  :disabled {
    opacity: 0.6;
  }
  background-color: ${(props) => props.theme.mainColor};
  :hover {
    background-color: ${(props) => props.theme.mainShade};
  }
`;

export default withTheme(
  ({ label, onClick, theme, backgroundColor, disabled = false }) => {
    return (
      <Wrapper
        disabled={disabled}
        backgroundColor={backgroundColor || theme.subColor}
        onClick={onClick}
      >
        {label}
      </Wrapper>
    );
  }
);
