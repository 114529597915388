import React from "react";
import styled from "styled-components";
import BlueHead from "../Images/BlueHead.png";

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.lightGreyColor};
  border-radius: 32px;
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
  font-family: ${(props) => props.theme.mainFont};
  justify-content: space-between;
  align-items: center;
`;

const ProfilePicture = styled.div`
  width: 80px;
  margin: 2px;
  height: 80px;
  min-weight: 80px;
  border-radius: 32px;
  overflow: hidden;
`;

const ProfileInfo = styled.div`
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Name = styled.div`
  font-weight: 800;
  font-size: 18px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const Rank = styled.div`
  font-weight: 400;
  line0height: 24px;
  margin-top: 4px;
  font-size: 14px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const CountWrapper = styled.div`
  padding: 0px 8px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Count = styled.h1`
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  width: 100%;
  margin-bottom: 8px;
`;

const OtherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CountLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  text-align: center;
`;

const Link = styled.span`
  color: ${(props) => props.theme.mainColor};
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.subColor};
  }
`;

const LinkCenter = styled.span`
  color: ${(props) => props.theme.mainColor};
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  :hover {
    color: ${(props) => props.theme.subColor};
  }
`;

export default ({
  profile,
  count,
  name,
  rank,
  id,
  onClick,
  showDetails,
  listLength,
}) => {
  return (
    <Wrapper>
      <OtherWrapper>
        <ProfilePicture>
          <img
            src={profile || BlueHead}
            height={80}
            width={80}
            style={{ objectFit: "cover" }}
          />
        </ProfilePicture>
        <ProfileInfo>
          <Name>{name}</Name>
          <Rank>{rank ? rank : "Waiting Approval"}</Rank>
          {listLength < 2 && <Link onClick={showDetails}>View Details</Link>}
        </ProfileInfo>
      </OtherWrapper>
      <CountWrapper>
        <Count>{count === 0 ? 0 : count}</Count>
        <CountLabel>Partner(s)</CountLabel>
        <LinkCenter
          onClick={() => {
            onClick(id);
          }}
        >
          Expand network
        </LinkCenter>
      </CountWrapper>
    </Wrapper>
  );
};
