import React, { useState, useContext, useEffect } from "react";
import styled, { withTheme, keyframes } from "styled-components";
import { auth, database } from "firebase";
import bgimg from "../components/Images/authbg.jpg";
import Input from "../components/Input";
import Button from "../components/Button";
import logo from "../components/Images/logo512.png";
import GoogleSignInButton from "../components/GoogleSignInButton";
import { IoMdClose } from "react-icons/io";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CHECK_USERNAME } from "../query/Users";
import { toast } from "react-toastify";
import ProfileStore from "../context/ProfileContext";
import { BIND_ACCOUNT } from "../mutations/Users";
import HomeTitle from "../components/Images/ThemeBG.png";
import { LanguageStore } from "../context/LanguageStore";
import AuthOutLanguage from "../components/AuthOutLanguage";
import ForgetPasswordForm from "../components/ForgetPasswordForm";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  max-height: 80px;
`;

const Background = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  flex-direction: column;
  width: 100%;
  max-width: 470px;
  height: 100vh;
  background: url(${HomeTitle});
  background-size: cover;
  background-position: top center;
  background-overlay: rgba(0, 0, 0, 0.2);
`;
const BackgroundOverlay = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FormTab = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.darkBlueColor};
`;

const Selector = styled.div`
  width: 100%;
  cursor: pointer;
  border-style: solid;
  border-color: ${(props) => props.borderColor};
  border-bottom-width: 4px;
  opacity: ${(props) => props.opacity};
  padding: 12px 0px;
  margin-bottom: -2px;
  :hover {
    opacity: 1;
    color: ${(props) => props.theme.mainShade};
    border-color: ${(props) => props.theme.mainShade};
  }
`;

const Logo = styled.img`
  width: 100%;
`;
const Title = styled.p`
  ${(props) => props.theme.title}
  text-align: center;
  font-size: 16px;
`;

const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px 40px;
  color: white;
  font-weight: 800;
  font-size: 28px;
  align-items: center;
  min-height: 40vh;
  width: 100%;
  color: white;
  font-size: 30px;
  padding: 20px 40px;
  line-height: 42px;
  letter-spacing: 4px;
  font-weight: 600;
`;

const FormWrapper = styled.div`
  padding: 16px;
  position: fixed;
  left: 50%;
  max-width: 440px;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  margin-bottom: 20px;
  background-color: white;
  width: 100%;
  border-radius: 24px;
  box-shadow: ${(props) => props.theme.boxShadow},
    ${(props) => props.theme.boxShadow}, ${(props) => props.theme.boxShadow};
  @media (max-width: 420px) {
    width: calc(100vw - 30px);
    max-width: none;
  }
`;

const Form = styled.div`
  padding: 20px;
  padding-bottom: 0px;
`;

const InputWrapper = styled.div`
  width: 100%;
  padding: 8px 0px;
`;

const Description = styled.p`
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  line-height: 22px;
`;

const OtherOptions = styled.p`
  text-decoration: underline;
  font-weight: 600;
  color: ${(props) => props.theme.mainColor};
  margin: 12px 0px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;
const ForgetPassword = styled.p`
  text-decoration: underline;
  font-weight: 400;
  color: ${(props) => props.theme.mainColor};
  margin: 12px 0px;
  margin-top: 6px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;

const BgChange = keyframes`
  0%{
    background-color: rgba(0,0,0,0);
  }
  100%{
    background-color: rgba(0,0,0,0.4);
  }
`;

const SlideUp = keyframes`
  0%{
      opacity: 0;
      transform: translate(0%, 400%);
  }
  100%{
      opacity: 1;
      transform: translate(0%, 0%);
  }
`;

const FormBackground = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  left: 50%;
  width: 100%;
  max-width: 470px;
  background-color: rgba(0, 0, 0, 0.4);
  transform: translate(-50%, 0%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  animation: ${BgChange} 0.3s linear;
`;
const SignUpForm = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  animation: ${SlideUp} 0.4s linear;
`;
const Subtitle = styled.h1`
  ${(props) => props.theme.title};
  font-size: 20px;
`;

const Row = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const SocialWrapper = styled.div`
  padding-bottom: 6px;
`;

const Divider = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.mainFont};
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.lightBlackColor};
  margin-bottom: 8px;
`;

export default withTheme(({ theme, location }) => {
  const [action, setAction] = useState(
    location.search.split("=")[1] ? "Sign Up" : "Sign In"
  );

  const { language, dictionary, setLanguage } = useContext(LanguageStore);
  const { setIsLoggedIn, setHalfcode, setToken } = useContext(ProfileStore);
  const [showForm, setShowForm] = useState(false);
  const [searchUser, setSearchUser] = useState(true);
  const [checking, setChecking] = useState(true);
  const [changeable, setChangeable] = useState(
    location.search.split("=")[1] ? false : true
  );
  const [username, setUsername] = useState(location.search.split("=")[1] || "");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const { data, loading, error } = useQuery(CHECK_USERNAME, {
    variables: {
      username: username,
    },
    skip: searchUser,
  });
  const [bindAccount] = useMutation(BIND_ACCOUNT);

  if (!searchUser && data && data.users.length <= 0) {
    if (!toast.isActive("user_exist")) {
      toast.error(dictionary.userNotExist, {
        toastId: "user_exist",
        autoClose: false,
      });
      setUsername("");
      setChangeable(true);
      setSearchUser(true);
    } else {
      toast.dismiss();
    }
  }

  if (!loading && error) {
    if (error.message === "Network error: Failed to fetch") {
      toast.error(dictionary.noInternetConnection, {
        toastId: "Network_issue",
        autoClose: false,
      });
    }
  }
  const _inputCheck = () => {
    if (email === "") {
      return toast.error(dictionary.enterEmail);
    }
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return toast.error(dictionary.enterEmail);
    }
    if (password.length < 6) {
      return toast.error(dictionary.enterPassword);
    }
  };

  const _signInWithEmail = (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error(dictionary.enterEmail);
    }
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return toast.error(dictionary.enterEmail);
    }
    if (password.length < 6) {
      return toast.error(dictionary.enterPassword);
    }
    let signInToast = toast.warning("Wait a moment..", { autoClose: false });
    auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        toast.warning("Hold on...", { autoClose: false });
        localStorage.setItem("uid", user.user.uid);
        if (location.search.split("=")[3]) {
          setHalfcode(location.search.split("=")[3]);
        }
      })
      .catch((e) => {
        if (e.code === "auth/user-not-found") {
          toast.update(signInToast, {
            type: toast.TYPE.ERROR,
            autoClose: false,
            render: <p>Your e-mail is not registered!</p>,
          });
        } else if (e.code === "auth/invalid-email") {
          toast.update(signInToast, {
            type: toast.TYPE.ERROR,
            autoClose: false,
            render: <p>Your e-mail is invalid!</p>,
          });
        } else if (e.code === "auth/wrong-password") {
          toast.update(signInToast, {
            type: toast.TYPE.ERROR,
            autoClose: false,
            render: <p>Password incorrect!</p>,
          });
        } else if (e.code === "auth/network-request-failed") {
          toast.dismiss();
          return toast.error(dictionary.noInternetConnection);
        } else {
          toast.update(signInToast, {
            type: toast.TYPE.ERROR,
            autoClose: false,
            render: <p>Unknown error! Contact admin or try again!</p>,
          });
        }
      });
  };
  const _signInWithGoogle = () => {
    let provider = new auth.GoogleAuthProvider();
    auth()
      .signInWithPopup(provider)
      .then((result) => {
        if (result.additionalUserInfo.isNewUser) {
          if (data && data.users && data.users.length > 0) {
            bindAccount({
              variables: {
                downline: result.user.uid,
                upline: data.users[0].id,
              },
            })
              .then((returning) => {
                toast.warning("Hold on...", { autoClose: false });
                localStorage.setItem("uid", result.user.uid);
                if (location.search.split("=")[3]) {
                  setHalfcode(location.search.split("=")[3]);
                }
              })
              .catch((e) => {
                toast.dismiss();
                toast.error(e.message);
                auth().currentUser.delete();
              });
          } else {
            auth().currentUser.delete();
            toast.error(dictionary.noReferral);
          }
        } else {
          toast.warning("Hold on...", { autoClose: false });
          localStorage.setItem("uid", result.user.uid);
          if (location.search.split("=")[3]) {
            setHalfcode(location.search.split("=")[3]);
          }
        }
      });
  };
  const _signUpWithEmail = (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error(dictionary.enterEmail);
    }
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return toast.error(dictionary.enterEmail);
    }
    if (password.length < 6) {
      return toast.error(dictionary.enterPassword);
    }
    toast.warning(dictionary.creatingAcc, { autoClose: false });
    auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        bindAccount({
          variables: {
            upline: data.users[0].id,
            downline: user.user.uid,
          },
        })
          .then((returning) => {
            localStorage.setItem("uid", user.user.uid);
            if (location.search.split("=")[3]) {
              setHalfcode(location.search.split("=")[3]);
            }
          })
          .catch((e) => {
            toast.dismiss();
            toast.error(e.message);
            auth().currentUser.delete();
          });
      })
      .catch((e) => {
        toast.dismiss();
        if (e.code === "auth/email-already-in-use") {
          toast.error(dictionary.emailRegistered);
        } else {
          toast.error(e.message);
        }
      });
  };
  useEffect(() => {
    if (location.search.split("=")[1]) {
      setSearchUser(false);
    }
  }, []);
  return (
    <Wrapper>
      <Background>
        <BackgroundOverlay>
          <Header></Header>
          <Content>
            <Logo src={bgimg} />
          </Content>
        </BackgroundOverlay>
      </Background>
      <FormWrapper>
        <FormTab>
          <Selector
            onClick={() => {
              setAction("Sign In");
            }}
            borderColor={action === "Sign In" ? theme.mainColor : "transparent"}
            opacity={action === "Sign In" ? 1 : 0.6}
          >
            <Title>{dictionary.signIn}</Title>
          </Selector>
          <Selector
            onClick={() => {
              setAction("Sign Up");
            }}
            borderColor={action !== "Sign In" ? theme.mainColor : "transparent"}
            opacity={action !== "Sign In" ? 1 : 0.6}
          >
            <Title>{dictionary.createAcc}</Title>
          </Selector>
        </FormTab>
        {action === "Sign In" ? (
          <Form>
            <InputWrapper>
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={"test@example.com"}
                label={dictionary.email}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder={"******"}
                label={dictionary.pass}
              />
            </InputWrapper>

            <InputWrapper>
              <Button label={dictionary.signIn} onClick={_signInWithEmail} />
            </InputWrapper>
            <ForgetPassword
              onClick={() => {
                setShowForgetPassword(true);
              }}
            >
              Forget Password?
            </ForgetPassword>
            <OtherOptions
              onClick={() => {
                setShowForm(true);
              }}
            >
              {dictionary.otherOption}
            </OtherOptions>
            <AuthOutLanguage />
          </Form>
        ) : (
          <Form>
            <Title>{dictionary.refUsername}</Title>
            <Description>{dictionary.descrefUsername}</Description>
            <InputWrapper>
              <Input
                value={username}
                onChange={(e) => {
                  if (!changeable) {
                    return;
                  }
                  setUsername(e.target.value);
                }}
                label={dictionary.userName}
                placeholder="XXXX"
              />
            </InputWrapper>
            <InputWrapper>
              <Button
                label={dictionary.searchUsername}
                onClick={(e) => {
                  e.preventDefault();
                  if (username === "") {
                    toast.error(dictionary.noReferral);
                  } else {
                    setSearchUser(false);
                  }
                }}
              />
            </InputWrapper>
            <AuthOutLanguage />
          </Form>
        )}
      </FormWrapper>
      {(showForm || (data && data.users && data.users.length > 0)) && (
        <FormBackground>
          {action === "Sign In" ? (
            <SignUpForm>
              <Row>
                <Subtitle>{dictionary.signIn}</Subtitle>
                <CloseButton
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  <IoMdClose size={24} />
                </CloseButton>
              </Row>
              <SocialWrapper>
                <GoogleSignInButton onClick={_signInWithGoogle} />
              </SocialWrapper>
            </SignUpForm>
          ) : (
            <SignUpForm>
              <Row>
                <Subtitle>{dictionary.createAcc}</Subtitle>
                <CloseButton
                  onClick={() => {
                    if (location.search.split("=")[1]) {
                      setShowForm(false);
                      setSearchUser(true);
                    } else {
                      setUsername("");
                      setShowForm(false);
                      setSearchUser(true);
                    }
                  }}
                >
                  <IoMdClose size={24} />
                </CloseButton>
              </Row>
              <Title>
                {dictionary.referredBy}
                {data && data.users[0].name}
              </Title>
              <InputWrapper>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder={"example@email.com"}
                  label={dictionary.email}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  type="password"
                  placeholder={"******"}
                  label={dictionary.pass}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <Button
                  onClick={_signUpWithEmail}
                  label={dictionary.createAcc}
                />
              </InputWrapper>
              <Divider>or</Divider>
              <SocialWrapper>
                <GoogleSignInButton onClick={_signInWithGoogle} />
              </SocialWrapper>
            </SignUpForm>
          )}
        </FormBackground>
      )}
      {showForgetPassword && (
        <ForgetPasswordForm
          close={() => {
            setShowForgetPassword(false);
          }}
        />
      )}
    </Wrapper>
  );
});
