const COMPANY_NAME = "Hushlab";

export default {
  companyName: "Hushlab",
  signIn: "Sign In",
  createAcc: "Create Account",
  creatingAcc: "Creating account... ",
  email: "E-mail",
  pass: "Password",
  paid: "Paid",
  about: "About",
  performance: "Performance",
  culture: "Team Culture",
  activities: "Brand Activities",
  otherOption: "Other Options",
  refUsername: "Referral Username",
  cancelOrder: "Cancel Order",
  mmsOrderMistake: "Made a mistake? Cancel the order to retake MMS order.",
  changePassword: "Change Password",
  oldPassword: "Old Password",
  cancelOrder: "Cancel Order",
  cancelOrderDescription:
    "You may cancel orders that are still waiting approval. Confirm cancel order?",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  noPaymentYet: "Downline haven't make payment yet. Confirm approve order?",
  checkPaymentFirst:
    "Make sure downline has already made payment. This action is not reversible!",
  resetPassword: "Reset Password",
  resetPasswordTips:
    "Enter your e-mail and we will send you a link to reset your password.",
  descrefUsername:
    "This is a member only portal. Sign up with a referral username!",
  userName: "Username",
  brandStory: "About Hushlab",
  cancelled: "Cancelled",
  searchUsername: "Search Username",
  contF: "Continue with Facebook",
  contG: "Continue with Google",
  wait: "Wait a Moment...",
  welcomeAlert: "Welcome!",
  addHomeScreen: "Add Hushlab on your Home Screen",
  descaddHomeScreen:
    "Install Hushlab on your home screen and access it like an App! Click the button below and tap 'Add to Home Screen'.",
  maybeLater: "MAYBE LATER",
  welcomeHome: "Welcome",
  stocks: "stocks",
  cashWallet: "cash wallet",
  downline: "network tree",
  repeatOrder: "Repeat Order",
  upgrade: "Upgrade",
  mms: "MMS",
  reqShipping: "Request Shipping",
  mall: "Mall",
  comingSoon: "COMING SOON",
  home: "Home",
  sharing: "Sharing",
  orders: "Orders",
  pendingApproval: "pending approval.",
  me: "Me",
  viewSummary: "View Report",
  uploadSlip: "Upload Slip",
  submitOrder: "Submit Order",
  resubmitSlip: "Resubmit payment slip",
  uploadingSlip: "Uploading slip...",
  uploading: "Uploading... Do not refresh!",
  slipUpdated: "Payment slip updated!",
  profileUploaded: "Profile picture uploaded!",
  viewMore: "View More",
  showLess: "Show Less",
  upLine: "Upline",
  phoneNo: "Phone No",
  call: "Call",
  save: "Save",
  callHaku: "Call Hushlab",
  emailHaku: "E-mail Hushlab",
  changeProfilePicture: "Change Profile Picture",
  sharingMaterial: "Sharing Material",
  gallery: "Gallery",
  retailSharing: "Retail Sharing",
  gotoRetail: "View Products",
  waiting: "Waiting",
  approval: "Approval",
  waitingCompanyApproval: "Waiting company approval",
  shareLink: "Share Link",
  aboutUs: "About Us",
  brand: "Brand Story",
  mmsOrder: "MMS Order",
  submittingOrder: "Submitting order...",
  approveOrder: "Approve Order",
  gotoAbout: "Go to About Us",
  myOrders: "My Orders",
  partnerOrder: "Partners' Orders",
  noOrders: "No Orders Yet :(",
  myNetwork: "My Network",
  walletsNbonuses: "Wallets and Bonuses",
  cashW: "Cash Wallet",
  myRBVoucher: "My RB Vouchers",
  mmsReward: "MMS Reward",
  monthlyPSR: "Monthly Development Bonus",
  chooseShippingMethod: "Choose Shipping Method",
  cbR: "CB Points",
  back: "Back",
  package: "Package",
  minOrder: "Min Order",
  unitP: "Unit Price ",
  orderQ: "Order Quantity",
  qtt: "Quantity",
  monthlyM: "Monthly Maintenance",
  maintainSalesError: "You need at least 2 boxes to maintain sales.",
  monthlyMalert: "You haven't maintain sales this month",
  selProduct: "Select a Product",
  stockB: "Stock Balance",
  regBusinessP: "Register Business Partner",
  refLink: "Your Referral Link: ",
  recipientName: "Recipient Name",
  copyLink: "Copy Link",
  emptyAmount: "Please enter an amount",
  enterName: "Please enter your name!",
  enterPhone: "Please enter your phone no!",
  enterProduct: "Please select a product",
  savingChanges: "Saving changes...",
  changesSaved: "Changes saved!",
  saveAddress: "Save Address",
  enterAddress: "Please enter your address",
  enterCity: "Please enter your city!",
  enterState: "Please enter your state!",
  enterPostcode: "Please enter your postcode!",
  enterShipping: "Please select a shipping method!",
  enterBankName: "Please enter your bank name",
  enterBankAccName: "Please enter bank account holder",
  enterBankAccNo: "Please enter bank account no",
  minimumBalance: "",
  balanceInsufficient: "Balance insufficient!",
  withdrawCash: "Withdraw Cash",
  cashWithdrawal: "Cash Withdrawal",
  withdrawDesc: "Cash balance are paid out at the end of each month.",
  noRecord: "No Record Yet",
  RBVoucher: "RB Vouchers",
  amount: "Amount",
  RBDesc: "RB can be used to purchase products",
  redeemV: "Redeem Vouchers",
  RBDesc2: "RB vouchers are rewarded everytime you restock:",
  RBDesc3:
    "You will be rewarded 10% RB of the amount of stocks you purchase, with a worth of 30% of the unit price.",
  RBDesc4:
    "E.g. When restocking 600 boxes at the price of RM111/box, you will receive 60 RB vouchers, each worth RM 33.30",
  close: "CLOSE",
  maximum: "Maximum",
  mmsR: "MMS Rewards are finalized monthly:",
  totMain: "Total Maintenance (boxes)",
  thisMonthB: "This Month's Bonus",
  mmsRewardalert:
    "You haven't maintain sales this month! Maintain sales now to earn MMS Rewards!",
  mainSalesNow: "Maintain Sales Now",
  monthlyPSR2: "MPS Rewards are finalized monthly:",
  groupSalesR: "Group Sales (Revenue)",
  groupSalesB: "Group Sales (Boxes)",
  personalSales: "Personal Sales",
  downlineBonus: "Downline Bonus",
  personalGroupSales: "Personal Sales",
  breakAwayLine: "Break Away Line",
  monthlySales: "Monthly Sales",
  groupSales: "Group Sales",
  overrideBonus: "Overriding Bonus",
  breakAwayBonus: "Break Away Bonus",
  personalBonus: "Personal Bonus",
  passupBonus: "Downline Pass Up Bonus",
  boxes: "boxes",
  bonus: "Bonus",
  mallAlert: "Mall will be available soon",
  gotoMall: "Go To Mall (Coming Soon)",
  stockP: "Stock Purchase",
  cashB: "Cash Back (CB)",
  cashB1: "CB(s) are rewarded when you purchase stocks as an agent.",
  cashB2: "20CB(s) are rewarded for each unit you purchase. (1CB = RM1)",
  cashB3: "You may use CB to shop from our mall!",
  yourP: "Your Partners",
  noPart: "No partners yet.",
  network: "Network",
  perInfo: "Personal Information",
  name: "Name",
  newAddress: "New Address",
  address: "Address",
  addAddress: "Add Address",
  pleaseCompleteForm: "Please complete the form.",
  addingAddress: "Adding address...",
  addressAdded: "Address added",
  yourAddress: "Your Address",
  fullName: "Full Name",
  userName: "Username",
  weChat: "WeChat ID",
  wa: "WhatsApp No",
  bankD: "Bank Details",
  bankName: "Bank Name",
  bankAccNo: "Bank Account No",
  bankAccName: "Bank Account Holder",
  addBook: "Address Book",
  myAdd: "My Address",
  others: "Others",
  TnC: "Terms and Conditions",
  priP: "Privacy Policy",
  signOut: "Sign Out",
  shipmentHistory: "Shipment History",
  viewShipment: "View Shipment History",
  noShipment: "No Shipment History",
  orderID: "Order ID",
  copyClipB: "Copied to Clipboard!",
  prodMenu: "Product Menu",
  value: "Value",
  totalAmount: "Total Amount",
  totalValue: "Total value",
  noReferral: "You don't have a referral!",
  referredBy: "Referred by: ",
  userNotExist: "User does not exist!",
  noInternetConnection: "No internet connection!",
  enterEmail: "Please enter a valid e-mail",
  enterQuantity: "Enter quantity",
  cannotExceed: "Cannot exceed",
  deliveryInformation: "Delivery Information",
  enterPassword: "Password length must be longer than 6!",
  emailRegistered: "This email has been registered.",
  readyToJoin: `Ready to join Hushlab?`,
  setupAcc: "Setup your account to become a member of Hushlab.",
  letsGo: "Let's go!",
  approved: "Approved",
  cancelled: "Cancelled",
  pending: "Pending",
  backToSignIn: "Back to Sign In",
  selectPackage: "Select Package",
  useVoucher: "Use Voucher",
  chooseCountry: "Choose a country",
  contactDetails: "Contact Details",
  deliveryAddress: "Delivery Address",
  bankDetails: "Bank Details",
  next: "Next",
  rbRedeemHistory: "RB Redeem History",
  chooseOffer: "Choose an offer",
  agentStockBalance: "'s stock balance",
  quantityCannotZero: "Cannot order 0 product!",
  agentInsufficientStock:
    "Agent has insufficient stock! Contact agent to restock!",
  noAgentDetected: "No agent detected. Contact agent to resend order link.",
  chooseAddress: "Choose an address",
  voucher: "Voucher",
  bigVoucher: "大礼包卷",
  searchVoucher: "Search Voucher",
  enterValidVoucher: "Please enter a valid voucher code!",
  halfTicket: "招募金卷",
  checkTicket: "Check Ticket",
  ticketUsed: "Invalid voucher",
  ticketError: "Invalid Voucher",
  usernameTip:
    "Enter a unique username so you're friends can find you easily! **This can't be changed after setting up.",
  usernameTaken: "This username is taken! Try another one!",
  icWarning:
    "Your IC number is required to withdraw payment. Any faulty IC no may lead to failure in money withdrawal.",
  icTaken: "身份证已被注册！",
  secondPasswordIncorrec: "Second Password Incorrect",
  secondPassword: "Second Password",
  secondPasswordTip:
    "Your secret key is required to withdraw money and purchase stocks.",
  backToSelectPackage: "Back to Select Package",
  city: "City",
  state: "State",
  postcode: "Postcode",
  backToContact: "Back to Contact Details",
  yourName: "Your Name",
  backToDelivery: "Back to Delivery Address",
  selectedPackage: "Selected Package",
  package: "Package",
  total: "Total",
  changeSecondPassword: "Change Second Password",
  backToBankDetails: "Back to Bank Details",
  confirmSecondPass: "Confirm Second Password",
  repeatSecondPass: "Repeat Second Password",
  summary: "Summary",
  stillHavePendingOrder:
    "You still have pending orders! Clear your orders first.",
  makingRequest: "Making Request...",
  withdrawalRequestMade: "Withdrawal request made!",
  tracking: "Tracking",
  trackingCopied: "Tracking number copied!",
  orderProcessing: "Order processing",
  shipmentPending: "Shipment pending!",
  notEnoughStock: "You don't have enough stock! Restock first!",
  orderApproved: "Order Approved!",
  payment: "Payment",
  orderPlaced: "Order placed!",
  mmsWarning: "Your order must be approved to entitle for MMS reward.",
  mmsSuccess: "View your MMS reward here.",
  mmsTips:
    "Consume 2 boxes of our products every month to entitle for Monthly Maintenance Sales bonus",
  you: "You",
  sPartner: "'s Partners",
  selectAVoucher: "Select a voucher",
  noRbVoucher: "You have no RB voucher!",
  replenishPrice: "Replenish price",
  notEnoughVoucher: "You don't have enough voucher!",
  chooseArea: "Choose a shipping area",
};
