import React, { useState, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import { GET_PACKAGES_FOR_SETUP } from "../../query/Packages";
import Package from "../../components/Package";
import Button from "../../components/Button";
import gift from "../../components/Images/gift.png";
import ExtraPackage from "../../components/ExtraPackage";
import ProfileStore from "../../context/ProfileContext";
import { LanguageStore } from "../../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10vh;
  position: relative;
`;

const CountrySelector = styled.select`
  border-radius: 12px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px 8px;
  width: 100%;
  margin: 12px 0px;
  cursor: pointer;
  font-size: 16px;
`;

const LoadWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 470px;
  bottom: 0;
  left: 50%;
  padding: 20px;
  padding-bottom: 30px;
  transform: translate(-50%, 0%);
  z-index: 8;
`;

const Shake = keyframes`
  0% {
    transform: translate(20%, 0%) rotateZ(15deg) scale(1.1, 1);
  }
  5%{
    transform: translate(20%, 0%) rotateZ(0deg) scale(1,1)
  }
  10%{
    transform: translate(20%, 0%) rotateZ(-15deg) scale(1, 1.1)
  }
  15%{
    transform: translate(20%, 0%) rotateZ(0deg)scale(1, 1)
  }
  20% {
    transform: translate(20%, 0%) rotateZ(15deg)scale(1.1, 1)
  }
  25%{
    transform: translate(20%, 0%) rotateZ(0deg)scale(1,1)
  }
  30%{
    transform: translate(20%, 0%) rotateZ(-15deg)scale(1, 1.1)
  }
  35%{
    transform: translate(20%, 0%) rotateZ(0deg)scale(1,1)
  }
  90%{
    transform: translate(20%, 0%) rotateZ(0deg)scale(1,1)
  }
  100%{
    transform: translate(20%, 0%) rotateZ(15deg)scale(1.1, 1)
  }
`;

const GiftBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  transform: translate(0%, -180%);
  left: 0;
  margin-left: -20px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.mainColor};
  align-items: center;
  :hover {
    background-color: ${(props) => props.theme.mainShade};
  }
`;

const FatText = styled.h1`
  ${(props) => props.theme.title};
  font-weight: 800;
  color: ${(props) => props.theme.subColor};
  margin: 0px 8px;
`;

const GiftBox = styled.div`
  transform: translate(20%, 0%);
  animation: ${Shake} 2s ease-in infinite;
`;
export default ({
  setAction,
  setPackage,
  selectedPackage,
  country,
  setCountry,
}) => {
  const { data, loading, error } = useQuery(GET_PACKAGES_FOR_SETUP);
  const { halfcode } = useContext(ProfileStore);
  const { dictionary } = useContext(LanguageStore);
  const [showExtra, setShowExtra] = useState(false);

  useEffect(() => {
    if (halfcode) {
      return setShowExtra(true);
    }
  }, []);
  return (
    <Wrapper>
      {loading && (
        <LoadWrapper>
          <Loader />
        </LoadWrapper>
      )}
      {!loading && data && data.country.length > 0 && (
        <CountrySelector
          onChange={(e) => {
            setCountry(parseInt(e.target.value));
          }}
        >
          <option value="default">{dictionary.chooseCountry}</option>
          {data.country.map((country, index) => {
            return (
              <option value={country.id} key={index}>
                {country.name}
              </option>
            );
          })}
        </CountrySelector>
      )}
      {!loading &&
        country !== "default" &&
        data &&
        data.packages.length > 0 &&
        data.packages.map((item, index) => {
          if (item.country === country) {
            return (
              <Package
                id={item.id}
                name={item.name}
                chinese_name={item.chinese_name}
                amount={item.min}
                unit={item.unit}
                price={item.unit_price}
                currency={item.package_country.currency}
                selectedPackage={selectedPackage}
                setPackage={setPackage}
                key={index}
                system_offer={item.system_offer}
              />
            );
          } else {
            return null;
          }
        })}
      {selectedPackage !== "none" && country !== "default" && (
        <ButtonWrapper>
          <Button
            label={dictionary.next}
            onClick={() => {
              setAction(2);
            }}
          />
        </ButtonWrapper>
      )}
      {
        <GiftBoxWrapper
          onClick={() => {
            setShowExtra(true);
          }}
        >
          <FatText>{dictionary.useVoucher}</FatText>
          <GiftBox>
            <img
              src={gift}
              height={40}
              width={40}
              style={{ objectFit: "contain" }}
            />
          </GiftBox>
        </GiftBoxWrapper>
      }
      {showExtra && (
        <ExtraPackage
          predefinedCode={halfcode}
          setCountry={setCountry}
          setPackage={setPackage}
          next={() => {
            setAction(2);
          }}
          close={() => {
            setShowExtra(false);
          }}
        />
      )}
    </Wrapper>
  );
};
