import gql from "graphql-tag";

export const GET_MY_ORDERS = gql`
  query getMyOrders($id: String!) {
    users_by_pk(id: $id) {
      id
      package_orders(order_by: { status: desc, created_at: desc }) {
        id
        created_at
        system_offer
        package_country {
          currency
          id
        }
        order_package {
          chinese_name
          name
          id
          big_offer
          half
        }
        quantity
        selling_price
        status
      }
    }
    package_order(
      where: { seller_id: { _eq: $id } }
      order_by: { status: desc, created_at: desc }
    ) {
      system_offer
      user {
        name
        avatar
        id
      }
      order_package {
        id
        chinese_name
        name
        big_offer
        half
      }
      id
      created_at
      buyer_id
      status
      quantity
      selling_price
      package_country {
        id
        currency
      }
    }
  }
`;

export const DOWNLINE_ORDER_DETAIL = gql`
  query getMyOrderDetails($id: Int!, $uid: String!) {
    users_by_pk(id: $uid) {
      stock_balance
      id
      current_rank_price
    }
    package_order(where: { id: { _eq: $id }, seller_id: { _eq: $uid } }) {
      approved_at
      buyer_id
      cost_price
      created_at
      system_offer
      id
      order_package {
        chinese_name
        name
        id
        status
        pay_company
        big_offer
        half
        package_country {
          currency
        }
      }
      selling_price
      user {
        id
        avatar
        name
        phone
        email
      }
      payment_slip
      quantity
      status
    }
    master {
      bank_acc_name
      bank_name
      company_logo
      company_name
      id
      bank_acc_no
    }
  }
`;
export const MY_ORDER_DETAIL = gql`
  query getMyOrderDetails($id: Int!, $uid: String!) {
    package_order(where: { id: { _eq: $id }, buyer_id: { _eq: $uid } }) {
      approved_at
      buyer_id
      cost_price
      created_at
      id
      system_offer
      order_package {
        chinese_name
        name
        system_offer
        id
        status
        pay_company
        big_offer
        half
        package_country {
          currency
        }
      }
      selling_price
      user {
        users_upline {
          avatar
          user_banks {
            id
            bank_name
            bank_acc_no
            bank_acc_name
          }
          id
          name
        }
      }
      payment_slip
      quantity
      status
    }
    master {
      bank_acc_name
      bank_name
      company_logo
      company_name
      id
      bank_acc_no
    }
  }
`;

export const GET_PRODUCT_ORDERS = gql`
  query shipmentHistory($id: String!) {
    product_order(
      where: { user_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      id
      status
      products {
        name
        product_img
        id
      }
      quantity
      created_at
    }
  }
`;

export const RB_ORDER_DETAILS = gql`
  query getRBOrderDetails($order_id: Int!, $uid: String!) {
    rb_orders(where: { buyer_id: { _eq: $uid }, id: { _eq: $order_id } }) {
      address
      city
      contact_no
      created_at
      id
      order_type
      multiproduct
      payment_slip
      postcode
      products {
        id
        name
        product_img
        unit_measurement
      }
      product_order_shipping {
        status
        id
        name
        shipping_rate_country {
          currency
          id
        }
      }
      quantity
      recipient_name
      shipping
      state
      status
      shipped
      shipped_at
      tracking_id
      courier
      tracking_link
      replenish_price
      shipping_rate
      rb_package {
        unit_price
        id
      }
    }
    master {
      bank_acc_name
      bank_name
      company_logo
      company_name
      id
      bank_acc_no
    }
  }
`;

export const PRODUCT_ORDER_DETAIL = gql`
  query getProductDetail($order_id: Int!, $uid: String!) {
    product_order(where: { id: { _eq: $order_id }, user_id: { _eq: $uid } }) {
      address
      city
      contact_no
      created_at
      delivery_rate
      id
      order_type
      payable_per_unit
      payment_slip
      postcode
      multiproduct
      products {
        id
        name
        product_img
        unit_measurement
      }
      product_order_shipping {
        status
        id
        name
        shipping_rate_country {
          currency
          id
        }
      }
      quantity
      recipient_name
      shipping
      state
      status
      tracking_id
      tracking_link
      courier
      shipped
      shipped_at
    }
    master {
      bank_acc_name
      bank_name
      company_logo
      company_name
      id
      bank_acc_no
    }
  }
`;

export const GET_RB_ORDERS = gql`
  query getRbHistory($id: String!) {
    rb_orders(
      where: { buyer_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      id
      status
      products {
        name
        product_img
        id
      }
      multiproduct
      replenish_price
      quantity
      created_at
    }
  }
`;

export const GET_RB_DETAILS = gql`
  query getRbDetails($id: Int!, $uid: String!) {
    rb_orders(where: { id: { _eq: $id }, user_id: $uid }) {
      address
      city
      replenish_price
      contact_no
      created_at
      delivery_rate
      id
      order_type
      payment_slip
      postcode
      products {
        name
        product_img
        unit_measurement
      }
      quantity
      recipient_name
      shipping
      state
      status
      product_order_shipping {
        status
        name
        shipping_rate_country {
          currency
          id
        }
        id
      }
    }
    user_current_rank(where: { user_id: { _eq: $uid } }) {
      user_rank {
        drb_reward
        id
      }
    }
  }
`;

export const GET_RB_ORDER_INFO = gql`
  query rbOrderInfo($id: String!) {
    check_rb_balance(
      where: { user_id: { _eq: $id } }
      order_by: { unit_price: asc }
    ) {
      package_id
      package_chinese_name
      package_name
      quantity
      user_id
      unit_price
    }
    users_by_pk(id: $id) {
      secret_key
      user_country {
        id
        currency
      }
      id
    }
    check_rb_balance_aggregate(where: { user_id: { _eq: $id } }) {
      aggregate {
        sum {
          total_value
        }
      }
    }
  }
`;

export const CHECK_RETAIL_ORDER = gql`
  query checkRetailOrder($id: Int) {
    retail_order(where: { id: { _eq: $id } }) {
      product_id
      quantity
      address
      state
      city
      id
      postcode
      recipient_name
      status
      created_at
      phone
      shipping_rate
      shipping
      shipped
      shipped_at
      tracking_id
      courier
      tracking_link
      seller_id
      unit_price
      payment_slip
      retail_order_product {
        product_img
        id
        name
      }
      retail_order_shipping {
        id
        shipping_rate_country {
          id
          currency
        }
      }
    }
    master {
      bank_acc_name
      bank_name
      company_logo
      company_name
      id
      bank_acc_no
    }
  }
`;
