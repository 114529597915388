import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const Icon = styled.div`
  width: 50px;
  height: 50px;
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.darkBlueColor};
`;
const Text = styled.div`
  font-size: 16px;
  width: 100%;
  padding: 12px 0px;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${(props) => props.theme.lightGreyColor};
  cursor: pointer;
`;
export default ({ icon, name, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon>{icon}</Icon>
      <Text>{name}</Text>
    </Wrapper>
  );
};
