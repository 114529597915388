import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";
import { GET_PRODUCTS_ONE } from "../query/Gallery";
import parse, { domToReact } from "html-react-parser";
import Loader from "../components/Loader";
import Back from "../components/Back";
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi";
import Button from "../components/Button";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductImage = styled.img`
  position: fixed;
  top: 0;
  left: 50%;
  max-width: 470px;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 70vh;
  object-fit: contain;
  z-index: 1;
`;

const ProductName = styled.h1`
  ${(props) => props.theme.title};
  color: white;
  font-size: 32px;
  margin-bottom: 8px;
`;

const CompanyName = styled.p`
  ${(props) => props.theme.title};
  font-size: 18px;
  font-weight: 400;
  color: white;
  line-height: 22px;
`;

const BackWrapper = styled.div`
  padding: 12px 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
`;
const ProductOverlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 470px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: calc(5vh + 20px) 40px;
  color: white;
  width: 100%;
  height: 70vh;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.8)
  );
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 65vh;
  left: 0;
  min-height: 35vh;
  z-index: 3;
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  width: 100%;
  padding-bottom: 10vh;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SmallButton = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.orangeColor};
  padding: 8px 12px;
  color: white;
  border-radius: 20px;
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  right: 32px;
  top: 0;
  transform: translate(0%, -50%);
  transition: 0.4s;
  z-index: 9;
  :hover {
    background-color: ${(props) => props.theme.mainColor};
  }
`;

const ProductDescription = styled.p`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.darkBlueColor};
  max-height: 180px;
  line-height: 22px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ViewMore = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  color: ${(props) => props.theme.mainColor};
  margin-top: 20px;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;

const ShowMoreWrapper = styled.div``;

const ButtonWrapper = styled.div`
  padding: 20px;
`;
export default ({ history, location }) => {
  const pid = location.search.split("=");
  if (pid.length === 1) {
    return <Redirect to="/product_menu" />;
  }

  const { dictionary } = useContext(LanguageStore);

  const [details, setDetails] = useState("Loading...");
  const [showDetails, setShowDetails] = useState(false);
  const { data, loading } = useQuery(GET_PRODUCTS_ONE, {
    variables: {
      id: pid[1],
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data) {
      fetch(data.products[0].details).then((blob) => {
        blob.text().then((data) => {
          return setDetails(data);
        });
      });
    }
  });
  const options = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) return;

      if (name === "h1") {
        return (
          <div style={{ paddingLeft: 12, paddingRight: 12, width: "100%" }}>
            <h1 style={{ fontSize: 18 }}>{domToReact(children, options)}</h1>
          </div>
        );
      }
      if (name === "p" && children[0].name !== "img") {
        return (
          <div style={{ paddingLeft: 12, paddingRight: 12, width: "100%" }}>
            <p style={{ fontSize: 14 }}>{domToReact(children, options)}</p>
          </div>
        );
      }
      if (name === "iframe") {
        return (
          <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
            <iframe
              frameBorder={attribs.frameBorder}
              allowfullscreen
              src={attribs.src}
              style={{ width: "100%", height: "250px" }}
            >
              {domToReact(children, options)}
            </iframe>
          </div>
        );
      }
      if (name === "img") {
        return (
          <img
            width={450}
            style={{ maxWidth: 470, width: "100%" }}
            src={attribs.src}
          />
        );
      }
    },
  };
  return loading && !data ? (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      <BackWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </BackWrapper>
      <ProductImage src={data && data.products[0].product_img} />
      <ProductOverlay>
        <ProductName>{data && data.products[0].name}</ProductName>
        <CompanyName>{dictionary.companyName}</CompanyName>
      </ProductOverlay>
      <ContentWrapper>
        <SmallButton
          onClick={() => {
            history.push("/retail_order");
          }}
        >
          Buy Now
        </SmallButton>
        <ContentContainer>
          <ProductDescription>
            {data && data.products[0].description}
          </ProductDescription>
          {data && data.products[0].details !== null && (
            <ViewMore
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            >
              {showDetails ? "Show less" : "View Details"}
              <span style={{ marginLeft: 8 }}>
                {showDetails ? (
                  <FiChevronsUp size={18} />
                ) : (
                  <FiChevronsDown size={18} />
                )}
              </span>
            </ViewMore>
          )}
        </ContentContainer>
        {showDetails && (
          <ShowMoreWrapper>
            {parse(details, options)}
            <ButtonWrapper>
              <Button
                onClick={() => {
                  history.push("/retail_order");
                }}
                label="Buy now"
              />
            </ButtonWrapper>
          </ShowMoreWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
