import React, { useContext } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import { BsQuestionCircle } from "react-icons/bs";
import { auth } from "firebase";
import { CHECK_MPS } from "../query/Bonus";
import { CommaFormatted } from "../utilities";
import Loader from "../components/Loader";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  background: linear-gradient(
    to left,
    ${(props) => props.theme.subColor},
    ${(props) => props.theme.mainColor}
  );
  height: 150px;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
`;

const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  padding-bottom: 0px;
`;

const Title = styled.div`
  color: white;
  font-weight: 800;
  font-size: 18px;
  margin-right: 8px;
`;

const Touchable = styled.div`
  color: white;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const BoxRow = styled.div`
  width: 100%;
  display: flex;
  z-index: 15;
  flex-direction: row;
  padding: 12px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: white;
  border-radius: 12px;
  color: ${(props) => props.theme.darkBlueColor};
  box-shadow: ${(props) => props.theme.boxShadow};
  margin: 0px 8px;
  width: 100%;
  min-height: 80px;
`;
const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  width: 100%;
  justify-content: space-between;
`;

const BoxTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Figure = styled.h4`
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  width: 100%;
`;

const Suffix = styled.span`
  font-weight: 400;
  font-size: 12px;
  margin: 0px 4px;
`;
const Subtitle = styled.h4`
  font-weight: 600;
  font-size: 14px;
  color: white;
  margin-top: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  padding-top: 60px;
  align-items: center;
`;

const CBRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const DateText = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
`;
const RowName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;

const NewRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const EmptyText = styled.h4`
  ${(props) => props.theme.title};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
export default ({ history }) => {
  let date = new Date();
  const { dictionary } = useContext(LanguageStore);
  const { data, loading } = useQuery(CHECK_MPS, {
    variables: {
      id: auth().currentUser.uid,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
  });

  console.log(data);

  const checkMonth = (month) => {
    switch (parseInt(month)) {
      case 1:
        return "JANUARY";
      case 2:
        return "FEBRUARY";
      case 3:
        return "MARCH";
      case 4:
        return "APRIL";
      case 5:
        return "MAY";
      case 6:
        return "JUNE";
      case 7:
        return "JULY";
      case 8:
        return "AUGUST";
      case 9:
        return "SEPTEMBER";
      case 10:
        return "OCTOBER";
      case 11:
        return "NOVEMBER";
      default:
        return "DECEMBER";
    }
  };

  let now = new Date();
  console.log(data);
  return (
    <Wrapper>
      <Header>
        <BackWrapper>
          <Back
            color="white"
            onClick={() => {
              history.goBack();
            }}
          />
        </BackWrapper>
        <TitleRow>
          <Title>{dictionary.monthlyPSR}</Title>
          <Touchable>
            <BsQuestionCircle size={18} />
          </Touchable>
        </TitleRow>
        <TitleRow>
          <Subtitle>{dictionary.monthlyPSR2} </Subtitle>
        </TitleRow>
        <BoxRow>
          {/* <Box>
            <BoxTitle>{dictionary.groupSalesR}</BoxTitle>
            <Figure>
              {!loading && data && data.group_sales_figure.length > 0
                ? data.group_sales_figure[0].currency +
                  " " +
                  CommaFormatted(data.group_sales_figure[0].group_sales)
                : 0}
            </Figure>
          </Box> */}
          <Box>
            <BoxTitle>{dictionary.groupSalesB}</BoxTitle>
            <Figure>
              {!loading &&
              data &&
              data.mds_bonus_v3_net.length > 0 &&
              data.mds_bonus_v3_net[0].month === now.getMonth() + 1 &&
              data.mds_bonus_v3_net[0].year === now.getFullYear()
                ? data.mds_bonus_v3_net[0].quantity
                : 0}
              <Suffix>boxes</Suffix>
            </Figure>
          </Box>
          <Box>
            <BoxTitle>{dictionary.bonus}</BoxTitle>
            <Figure>
              PV
              {!loading &&
              data &&
              data.mds_bonus_v3_net.length > 0 &&
              data.mds_bonus_v3_net[0].month === now.getMonth() + 1 &&
              data.mds_bonus_v3_net[0].year === now.getFullYear()
                ? data.mds_bonus_v3_net[0].personal_bonus
                : 0}
            </Figure>
          </Box>
        </BoxRow>
      </Header>
      <ContentWrapper>
        {loading && <Loader />}
        {!loading &&
          data &&
          (data.mds_bonus_v3_net.length > 0 ? (
            data.mds_bonus_v3_net.map((record, index) => {
              let now = new Date();
              if (record.month < now.getMonth() + 1) {
                return (
                  <CBRow>
                    <NewRow>
                      <DateText>
                        {checkMonth(record.month) + " " + record.year}
                      </DateText>
                      <DateText>{dictionary.bonus}:</DateText>
                    </NewRow>
                    <NewRow>
                      <RowName>x{record.quantity} Boxes</RowName>
                      <RowName>+ PV{record.personal_bonus}</RowName>
                    </NewRow>
                  </CBRow>
                );
              }
            })
          ) : (
            <EmptyText>{dictionary.noRecord}</EmptyText>
          ))}
      </ContentWrapper>
    </Wrapper>
  );
};
