import React, { useContext } from "react";
import styled from "styled-components";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50px;
`;

const Icon = styled.div`
  color: ${(props) => props.theme.errorRedColor};
`;

const Text = styled.div`
  color: ${(props) => props.theme.errorRedColor};
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`;

export default () => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper>
      <Icon>
        <IoIosCloseCircleOutline size={24} />
      </Icon>
      <Text>{dictionary.cancelled}</Text>
    </Wrapper>
  );
};
