import React from "react";
import styled, { keyframes, withTheme } from "styled-components";
import Loader from "./Loader";
import { IoMdClose } from "react-icons/io";

const Transparency = keyframes`
0%{
  background-color: rgba(0,0,0,0);
}
100%{
  background-color: rgba(0,0,0,0.4);
}
`;

const Up = keyframes`
0%{
  transform: translate(0%, 100%);
}
100%{
transform: translate(0%, 0%);
}
`;

const AnimatedWrapper = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.darkBlueColor};
  font-family: ${(props) => props.theme.mainFont};
  animation: ${Transparency} 0.4s linear;
`;

const AnimatedContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  max-width: 430px;
  flex-direction: column;
  animation: ${Up} 0.2s ease-in;
`;
const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Content = styled.div`
  max-height: 60vh;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: auto;
  h1 {
    font-weight: 600;
    font-size: 18px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
  }
`;
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h1`
  ${(props) => props.theme.title}
`;
const Touchable = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
export default withTheme(
  ({ title, content, loading = false, theme, close }) => {
    return (
      <AnimatedWrapper>
        <AnimatedContainer>
          <Header>
            <Title>{title}</Title>
            <Touchable onClick={close}>
              <IoMdClose size={18} color={theme.lightBlackColor} />
            </Touchable>
          </Header>
          {loading ? (
            <Content>
              <LoadingWrapper>
                <Loader />
              </LoadingWrapper>
            </Content>
          ) : (
            <Content>{content}</Content>
          )}
        </AnimatedContainer>
      </AnimatedWrapper>
    );
  }
);
