import gql from "graphql-tag";

export const UPDATE_SLIP = gql`
  mutation updateSlip($id: Int!, $url: String!) {
    update_package_order(
      where: { id: { _eq: $id } }
      _set: { payment_slip: $url }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_PUBLIC_RETAIL_ORDER = gql`
  mutation newRetailOrder(
    $product_id: Int
    $quantity: Int
    $address: String
    $state: String
    $city: String
    $postcode: String
    $recipient_name: String
    $phone: String
    $shipping_rate: float8
    $shipping: Int
    $seller_id: String
    $unit_price: float8
  ) {
    insert_retail_order(
      objects: {
        product_id: $product_id
        quantity: $quantity
        address: $address
        state: $state
        city: $city
        postcode: $postcode
        recipient_name: $recipient_name
        phone: $phone
        shipping_rate: $shipping_rate
        shipping: $shipping
        seller_id: $seller_id
        unit_price: $unit_price
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($id: Int!, $time: timestamptz!, $cost_price: Int!) {
    update_package_order_by_pk(
      pk_columns: { id: $id }
      _set: { approved_at: $time, status: "Approved", cost_price: $cost_price }
    ) {
      approved_at
      status
    }
  }
`;

export const RESTOCK = gql`
  mutation restock(
    $package_id: Int!
    $buyer_id: String!
    $quantity: Int!
    $seller_id: String!
    $cost_price: Int!
    $selling_price: Int!
    $country: Int!
  ) {
    insert_package_order(
      objects: {
        buyer_id: $buyer_id
        package_id: $package_id
        quantity: $quantity
        seller_id: $seller_id
        cost_price: $cost_price
        selling_price: $selling_price
        country: $country
      }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const UPLOAD_RETAIL_ORDER_SLIP = gql`
  mutation updateSlip($id: Int!, $url: String!) {
    update_retail_order(
      where: { id: { _eq: $id } }
      _set: { payment_slip: $url }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CANCEL_RETAIL_ORDER = gql`
  mutation cancelRetailORder($id: Int!) {
    update_retail_order(
      where: { id: { _eq: $id } }
      _set: { status: "Cancelled" }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_RETAIL_ORDER = gql`
  mutation deleteOrder($order_id: Int!) {
    delete_retail_order(where: { id: { _eq: $order_id } }) {
      affected_rows
    }
  }
`;
export const INSERT_RETAIL_ORDER = gql`
  mutation retailOrder(
    $uid: String!
    $quantity: Int!
    $multiproduct: String!
    $mms: Boolean
    $address: String!
    $state: String!
    $city: String!
    $postcode: String!
    $recipient_name: String!
    $phone: String!
    $shipping_rate: Int!
    $shipping: Int!
    $seller_id: String
    $unit_price: Int
  ) {
    insert_retail_order_one(
      object: {
        address: $address
        city: $city
        mms: $mms
        phone: $phone
        postcode: $postcode
        multiproduct: $multiproduct
        quantity: $quantity
        recipient_name: $recipient_name
        state: $state
        user_id: $uid
        shipping_rate: $shipping_rate
        shipping: $shipping
        seller_id: $seller_id
        unit_price: $unit_price
      }
    ) {
      id
    }
  }
`;

export const INSERT_PRODUCT_ORDER = gql`
  mutation newProductOrder(
    $city: String
    $contact_no: String!
    $delivery_rate: float8
    $pick_up: Boolean!
    $postcode: String!
    $multiproduct: String!
    $recipient_name: String!
    $state: String!
    $user_id: String!
    $shipping: Int!
    $quantity: Int!
    $address: String!
    $remark: String!
  ) {
    insert_product_order(
      objects: {
        city: $city
        contact_no: $contact_no
        delivery_rate: $delivery_rate
        address: $address
        pick_up: $pick_up
        postcode: $postcode
        multiproduct: $multiproduct
        recipient_name: $recipient_name
        state: $state
        user_id: $user_id
        quantity: $quantity
        shipping: $shipping
        remark: $remark
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_ORDER_SLIP = gql`
  mutation updateSlip($id: Int!, $url: String!) {
    update_product_order(
      where: { id: { _eq: $id } }
      _set: { payment_slip: $url }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CANCEL_PRODUCT_ORDER = gql`
  mutation cancelProductOrder($id: Int!) {
    update_product_order(
      where: { id: { _eq: $id } }
      _set: { status: "Cancelled" }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_RB_ORDER_SLIP = gql`
  mutation updateSlip($id: Int!, $url: String!) {
    update_rb_orders_by_pk(
      pk_columns: { id: $id }
      _set: { payment_slip: $url }
    ) {
      payment_slip
      id
    }
  }
`;

export const INSERT_RB_ORDER = gql`
  mutation newProductOrder(
    $city: String
    $contact_no: String!
    $postcode: String!
    $quantity: Int!
    $recipient_name: String!
    $state: String!
    $user_id: String!
    $shipping: Int!
    $address: String!
    $replenish_price: float8!
    $package: Int!
    $shipping_rate: float8!
    $multiproduct: String!
  ) {
    insert_rb_orders(
      objects: {
        city: $city
        contact_no: $contact_no
        address: $address
        postcode: $postcode
        quantity: $quantity
        recipient_name: $recipient_name
        state: $state
        buyer_id: $user_id
        package: $package
        shipping: $shipping
        shipping_rate: $shipping_rate
        multiproduct: $multiproduct
        replenish_price: $replenish_price
      }
    ) {
      returning {
        id
      }
    }
  }
`;
