import React, { useState, useContext } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import Profile from "../components/Network/Profile";
import { useQuery } from "@apollo/react-hooks";
import { GET_ALL_DOWNLINES } from "../query/Users";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { LanguageStore } from "../context/LanguageStore";
import MemberBonusDetails from "../components/MemberBonusDetails";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
  padding-top: 70px;
  padding-bottom: 20vh;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
  color: ${(props) => props.theme.darkBlueColor};
  margin-bottom: 8px;
`;
const Subtitle = styled.h1`
  ${(props) => props.theme.title};
  color: ${(props) => props.theme.darkBlueColor};
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 8px;
`;

const UplineLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.mainColor};
  }
`;
export default ({ history }) => {
  let user_id = auth().currentUser.uid;
  const { dictionary } = useContext(LanguageStore);
  const [showDetails, setShowDetails] = useState(null);
  const [ownerId, setOwnerId] = useState(auth().currentUser.uid);
  const [uplines, setUplines] = useState([]);
  const { data, loading, refetch, error } = useQuery(GET_ALL_DOWNLINES, {
    variables: {
      id: ownerId,
    },
  });
  const onUplineClick = (id) => {
    let uplineList = uplines;
    uplineList.pop();
    setUplines(uplineList);
    setOwnerId(id);
    refetch();
  };

  console.log(uplines);
  const onProfileClick = (uid) => {
    let currentUser = uid;
    let uplinesList = uplines;
    uplinesList.push({
      name: data.users[0].name,
      id: data.users[0].id,
    });
    setUplines(uplinesList);
    setOwnerId(currentUser);
    refetch();
  };
  return (
    <Wrapper>
      <PageTitle title={dictionary.network} />
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      <Title>
        {loading !== true &&
          data &&
          (data.users[0].id === user_id ? (
            dictionary.yourP
          ) : (
            <Title>
              <UplineLink
                onClick={() => {
                  onUplineClick(data.users[0].upline);
                }}
              >
                {uplines.length > 0 &&
                  (uplines.length === 1
                    ? dictionary.you
                    : uplines[uplines.length - 1].name)}
              </UplineLink>
              {" > "}
              {data.users[0].name + dictionary.sPartner}
            </Title>
          ))}
      </Title>
      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {loading !== true &&
        data &&
        data.users &&
        data.users[0].user_downlines &&
        (data.users[0].user_downlines.length !== 0 ? (
          data.users[0].user_downlines.map((user, index) => {
            console.log(user);
            return (
              <Profile
                onClick={(uid) => {
                  onProfileClick(uid);
                }}
                showDetails={() => {
                  setShowDetails({
                    id: user.id,
                    name: user.name,
                    avatar: user.avatar,
                    phone: user.phone,
                    email: user.email,
                    username: user.username,
                    joining_rank: user.user_joining_package,
                    current_rank: user.current_rank_name,
                  });
                }}
                listLength={uplines.length}
                name={user.name}
                profile={user.avatar}
                count={user.downline_count}
                id={user.id}
                rank={user.current_rank_name}
              />
            );
          })
        ) : (
          <LoaderWrapper>
            <Subtitle>{dictionary.noRecord}</Subtitle>
          </LoaderWrapper>
        ))}
      {showDetails !== null && (
        <MemberBonusDetails
          user={showDetails}
          close={() => {
            setShowDetails(null);
          }}
        />
      )}
    </Wrapper>
  );
};
