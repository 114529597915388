import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import logo from "./Images/logo512.png";
import { LanguageStore } from "../context/LanguageStore";

const SlideUp = keyframes`
    0%{
        background-color: rgba(0,0,0,0);
    }
    100%{
        background-color: rgba(0,0,0,0.4);
    }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.darkBlueColor};
  font-family: ${(props) => props.theme.mainFont};
  animation: ${SlideUp} 0.4s linear;
  @media (min-width: 600px) {
    display: none;
  }
`;

const Up = keyframes`
  0%{
      transform: translate(0%, 100%);
  }
  100%{
    transform: translate(0%, 0%);
}
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  animation: ${Up} 0.4s linear;
  :after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    margin-left: 50vw;
    transform: rotate(45deg) translate(-50%, 0%);
  }
`;
const Header = styled.div`
  font-weight: 800;
  padding: 12px 0px;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  // border-style: solid;
  // border-bottom-width: 1px;
  // border-color: ${(props) => props.theme.darkGreyColor};
`;

const HeaderText = styled.h1`
  width: 100%;
  text-align: center;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 100%;
  align-items: center;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction：column;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.darkBlueColor};
  text-align: center;
  line-height: 24px;
`;

const Button = styled.h1`
  font-weight: 900;
  width: 100%;
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  text-align: center;
  margin: 20px 0px;
  font-size: 14px;
  :hover {
    color: ${(props) => props.theme.darkBlueColor};
  }
`;

export default ({ close }) => {
  const { dictionary } = useContext(LanguageStore);
  let OS = window.navigator;
  return (
    <Wrapper onClick={close}>
      <Container>
        <LogoWrapper>
          <img src={logo} height={60} width="auto" />
        </LogoWrapper>
        <Header>
          <HeaderText>{dictionary.addHomeScreen}</HeaderText>
        </Header>
        <ContentWrapper>
          <Subtitle>{dictionary.descaddHomeScreen}</Subtitle>
        </ContentWrapper>
        <Button onClick={close}>{dictionary.maybeLater}</Button>
      </Container>
    </Wrapper>
  );
};
