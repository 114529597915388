import gql from "graphql-tag";

export const GET_COLLECTIONS = gql`
  query getImageCount($id: String) {
    gallery_collection(order_by: { sequence: desc }) {
      id
      name
      gallery_images_aggregate {
        aggregate {
          count(columns: id)
        }
      }
      gallery_images {
        url
        id
      }
    }
    about(order_by: { sequence: desc }) {
      id
      name
      sequence
      url
    }
  }
`;

export const GET_GALLERY_COLLECTION = gql`
  query getCollection($id: String) {
    gallery_collection(order_by: { sequence: desc }) {
      name
      sequence
      id
    }
  }
`;

export const GET_GALLERY_IMAGE = gql`
  query getImages($id: Int!) {
    gallery_collection_by_pk(id: $id) {
      id
      name
    }
    gallery_image(
      where: { collection: { _eq: $id } }
      order_by: { sequence: desc }
    ) {
      url
      name
      id
      type
      created_at
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($id: Int) {
    products(where: { id: { _eq: $id } }) {
      id
      product_img
      name
      description
    }
  }
`;

export const GET_PRODUCTS_ONE = gql`
  query getProducts($id: Int) {
    products(where: { id: { _eq: $id } }) {
      id
      product_img
      name
      description
      details
    }
  }
`;
