import cn from "../languages/cn";
import en from "../languages/en";

export const dictionaryList = {
  cn,
  en,
};

export const languageOptions = [
  { id: "en", text: "English", code: "EN" },
  { id: "cn", text: "中文", code: "CN" },
];
