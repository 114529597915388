import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { GET_COMPANY_CULTURE } from "../query/Users";
import Loader from "../components/Loader";
import parse, { domToReact } from "html-react-parser";
import Button from "../components/Button";
import { auth } from "firebase";
import Back from "../components/Back";
import ShareLink from "../components/ShareLink";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
    width: 100%;
    height: 100%:
    padding: 20px;
    padding-bottom: 20vh;
    max-width: 470px;
    object-fit: contain;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: 24px;
        width: 100%;
        font-weight: 800;
    }
    p {
        font-size: 14px;
        width: 100%;
    }

`;

const TopHolder = styled.div`
  width: 100%;
  height: 20px;
`;
const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  padding: 20px;
  width: 100%;
  z-index: 10;
  position: sticky;
  bottom: 0;
`;

const BackWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  z-index: 10;
  align-items: center;
  background-color: white;
`;

const PageTitle = styled.h1`
  text-align: right;
  color: ${(props) => props.theme.darkBlueColor};
  font-family: ${(props) => props.theme.mainFont};
  font-weight: 800;
  font-size: 20px;
`;
const BackContainer = styled.div`
  width: 100%;
`;
export default ({ history }) => {
  const { dictionary } = useContext(LanguageStore);
  const [actualData, setActualData] = useState("Loading...");
  const { data, loading } = useQuery(GET_COMPANY_CULTURE);

  if (data) {
    fetch(data.master[0].brand_culture).then((blob) => {
      blob.text().then((data) => {
        setActualData(data);
      });
    });
  }
  const options = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) return;

      if (name === "h1") {
        return (
          <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
            <h1 style={{ fontSize: 18 }}>{domToReact(children, options)}</h1>
          </div>
        );
      }
      if (name === "p" && children[0].name !== "img") {
        return (
          <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
            <p style={{ fontSize: 14 }}>{domToReact(children, options)}</p>
          </div>
        );
      }
      if (name === "iframe") {
        return (
          <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
            <iframe
              frameBorder={attribs.frameBorder}
              allowfullscreen
              src={attribs.src}
              style={{ width: "100%", height: "250px" }}
            >
              {domToReact(children, options)}
            </iframe>
          </div>
        );
      }
      if (name === "img") {
        return (
          <img
            width={450}
            style={{ maxWidth: 470, width: "100%" }}
            src={attribs.src}
          />
        );
      }
    },
  };
  return loading ? (
    <Wrapper>
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    </Wrapper>
  ) : (
    <Wrapper>
      {auth().currentUser && (
        <BackWrapper>
          <BackContainer>
            <Back
              onClick={() => {
                history.goBack();
              }}
            />
          </BackContainer>
          <PageTitle>{dictionary.culture}</PageTitle>
        </BackWrapper>
      )}
      <TopHolder />
      {actualData && parse(actualData, options)}
      {!auth().currentUser && (
        <ButtonWrapper>
          <Button
            label={"Sign In/Sign Up"}
            onClick={() => {
              history.push("/");
            }}
          />
        </ButtonWrapper>
      )}
      <ShareLink url={window.location.href} />
    </Wrapper>
  );
};
