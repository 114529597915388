import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Back from "../components/Back";
import ProductForm from "../components/ProductForm";
import PageTitle from "../components/PageTitle";
import { GET_STOCKS } from "../query/Bonus";
import { auth } from "firebase";
import Button from "../components/Button";
import Input from "../components/Input";
import { INSERT_PRODUCT_ORDER } from "../mutations/Orders";
import { toast } from "react-toastify";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBG from "../components/Images/ThemeBG.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  min-height: 100vh;
  overflow-y: auto;
  padding-bottom: 230px;
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notification = styled.div`
  width: 100%;
  z-index: 80;
  max-width: 470px;
  background-color: ${(props) => props.theme.mainColor};
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 16px;
  position: sticky;
`;

const ErrorNotification = styled.div`
  width: 100%;
  z-index: 80;
  max-width: 470px;
  background-color: ${(props) => props.theme.errorRedColor};
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 16px;
  position: sticky;
`;

const Summary = styled.div`
  padding: 12px 0px;
`;

const PageWrapper = styled.div`
  padding: 0px 20px;
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
`;

const SectionBackground = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.lightGreyColor};
  height: 100%;
`;

const Bank = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;
const BankDetail = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;
const Quantity = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.lightBlackColor};
  line-height: 22px;
`;

const Section = styled.div`
  padding: 16px 20px;
  margin-bottom: 12px;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;
const Error = styled.p`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 12px;
  margin-top: 6px;
`;

const Total = styled.h1`
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0px;
`;

const Price = styled.span`
  font-weight: 600;
  font-color: ${(props) => props.theme.darkBlueColor};
`;

export default ({ history }) => {
  const { dictionary } = useContext(LanguageStore);
  const [quantity, setQuantity] = useState();
  const [product, setProduct] = useState();
  const [address, setAddress] = useState("default");
  const [recipient, setRecipient] = useState("");
  const [phone, setPhone] = useState("");
  const [secret, setSecret] = useState("");
  const [addressSet, setAddressSet] = useState({});
  const [shipping, setShipping] = useState();
  const [isLoading, setIsLoading] = useState();
  const [shippingSet, setShippingSet] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [change, setChange] = useState(false);
  const [pickUp, setPickUp] = useState(false);
  const [remark, setRemark] = useState("");
  const [newProductOrder] = useMutation(INSERT_PRODUCT_ORDER);
  const { data, loading, refetch, error } = useQuery(GET_STOCKS, {
    variables: { id: auth().currentUser.uid },
    fetchPolicy: "network-only",
  });
  const submitOrder = (e) => {
    e.preventDefault();
    if (secret !== data.users_by_pk.secret_key) {
      return toast.error(dictionary.secondPasswordIncorrec);
    }
    if (
      selectedProducts.reduce((a, b) => {
        return a + b.quantity;
      }, 0) > data.users_by_pk.stock_balance ||
      data.users_by_pk.stock_balance <= 0
    ) {
      return toast.error(dictionary.notEnoughStock);
    }
    let emptyArrayIndex = selectedProducts.findIndex(
      (a) => isNaN(a.quantity) === true || a.quantity === "" || a.quantity <= 0
    );
    if (emptyArrayIndex !== -1) {
      return toast.error(
        selectedProducts[emptyArrayIndex].name + " has no selected quantity!"
      );
    }
    setIsLoading(true);
    toast.warning(dictionary.submittingOrder);
    let productsString = JSON.stringify(selectedProducts);
    newProductOrder({
      variables: {
        user_id: auth().currentUser.uid,
        address: addressSet.line,
        state: addressSet.state,
        city: addressSet.city,
        postcode: addressSet.postcode,
        recipient_name: recipient,
        contact_no: phone,
        quantity: selectedProducts.reduce((a, b) => {
          return a + b.quantity;
        }, 0),
        shipping: shipping,
        pick_up: false,
        remark: remark,
        delivery_rate: shippingSet.rate,
        multiproduct: productsString,
      },
    }).then((result) => {
      toast.dismiss();
      toast.success(dictionary.orderPlaced);
      refetch();
      history.push(
        "/shipment_details?order_id=" +
          result.data.insert_product_order.returning[0].id
      );
    });
  };

  const addProductToList = (pp) => {
    let products = selectedProducts;
    let productIndex = products.findIndex((a) => a.id === pp.id);
    if (productIndex !== -1) {
      products.splice(productIndex, 1);
    } else {
      products.push({
        id: pp.id,
        name: pp.name,
        img: pp.product_img,
        quantity: 1,
      });
    }
    setSelectedProducts(products);
    setChange(!change);
  };

  const changeProductQuantity = (id, quantity) => {
    if (parseInt(quantity) <= 0) {
      let productList = selectedProducts;
      productList.splice(
        productList.findIndex((pro) => pro.id === id),
        1
      );

      setSelectedProducts(productList);
      return setChange(!change);
    }
    setSelectedProducts(
      selectedProducts.map((pro) =>
        pro.id === id ? { ...pro, quantity: parseInt(quantity) } : pro
      )
    );
  };
  return (
    <Wrapper>
      <PageTitle title={dictionary.reqShipping} />
      {!loading && data && data.users_by_pk.stock_balance === 0 ? (
        <ErrorNotification>{dictionary.notEnoughStock}</ErrorNotification>
      ) : (
        <Notification>
          {dictionary.stockB}: {data && data.users_by_pk.stock_balance}
        </Notification>
      )}
      <PageWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
        <Section>
          <ProductForm
            limit={true}
            changeProductQuantity={changeProductQuantity}
            addProductToList={addProductToList}
            addressSet={addressSet}
            setAddressSet={setAddressSet}
            quantity={quantity}
            setQuantity={setQuantity}
            selectedProduct={selectedProducts}
            setProduct={setSelectedProducts}
            address={address}
            setAddress={setAddress}
            phone={phone}
            setPhone={setPhone}
            change={change}
            recipient={recipient}
            setRecipient={setRecipient}
            shipping={shipping}
            setShipping={setShipping}
            shippingSet={shippingSet}
            setShippingSet={setShippingSet}
            remark={remark}
            setRemark={setRemark}
          />
        </Section>
        {!loading &&
          selectedProducts.length > 0 &&
          address !== "default" &&
          recipient !== "" &&
          phone !== "" &&
          !isNaN(shippingSet.rate) && (
            <Section>
              <Summary>
                <Title>{dictionary.summary}</Title>
                <Total>
                  {dictionary.total}:{" "}
                  <Price>
                    {data.users_by_pk.user_country.currency +
                      " " +
                      shippingSet.rate}
                  </Price>
                  <InputWrapper>
                    <Input
                      label={dictionary.secondPassword}
                      type="password"
                      placeholder="******"
                      value={secret}
                      onChange={(e) => {
                        setSecret(e.target.value);
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Button
                      label={dictionary.submitOrder}
                      onClick={submitOrder}
                    />
                  </InputWrapper>
                </Total>
              </Summary>
            </Section>
          )}
      </PageWrapper>
    </Wrapper>
  );
};
