import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { GET_PACKAGES_BY_COUNTRY } from "../query/Packages";
import Row from "../components/RepeatOrder/Row";
import Back from "../components/Back";
import Input from "../components/Input";
import MainButton from "../components/Button";
import { CommaFormatted } from "../utilities";
import { RESTOCK } from "../mutations/Orders";
import { toast } from "react-toastify";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBG from "../components/Images/ThemeBG.png";
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  padding: 20px;
  padding-top: 70px;
  padding-bottom: 120px;
  overflow-y: auto;
  background-image: url(${ThemeBG});
  background-size: cover;
`;
const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageTable = styled.div`
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.subColor};
  overflow: hidden;
  background-color: white;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.subColor};
  justify-content: space-around;
  padding: 12px 0px;
  align-items: center;
`;

const TableItem = styled.h1`
  width: 100px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
`;

const QuantityWrapper = styled.div`
  padding: 20px 0px;
`;
const Subtitle = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
`;

const SelectedSummary = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalRow = styled.div`
  padding-top: 16px;
  border-style: solid;
  border-top-width: 1px;
  border-color: ${(props) => props.theme.darkGreyColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const TotalText = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  font-weight: 600;
`;

const ErrorMessage = styled.p`
  ${(props) => props.theme.title};
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.errorRedColor};
  text-align: center;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 12px;
  box-shadow: ${(props) => props.theme.boxShadow};
  overflow: hidden;
  background-color: white;
  border-radius: 12px;
`;
const SummaryText = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
export default ({ history }) => {
  const scrollHeight = useRef();
  const [quantity, setQuantity] = useState("");
  const { dictionary } = useContext(LanguageStore);
  const [secret, setSecret] = useState("");
  const [bgColor, setBgColor] = useState("none");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [restock] = useMutation(RESTOCK);
  const { data, loading, error, refetch } = useQuery(GET_PACKAGES_BY_COUNTRY, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    console.log(scrollHeight);
    if (scrollHeight.current) {
      document.getElementById("repeat-order-wrapper").onscroll = () => {
        console.log(scrollHeight.current.scrollTop);
        if (scrollHeight.current.scrollTop === 0) {
          setBgColor("none");
        } else {
          setBgColor("white");
        }
      };
    }
  });

  const submitForm = async (e, upline_package, key) => {
    e.preventDefault();
    if (isNaN(quantity)) {
      return toast.error("Order quantity must be a number!");
    }
    if (key !== secret) {
      return toast.error("Secret key incorrect!");
    }
    toast.warning("Placing order...", { autoClose: false });
    try {
      await restock({
        variables: {
          buyer_id: auth().currentUser.uid,
          package_id: selectedPackage.id,
          quantity: quantity,
          seller_id: data.users_by_pk.users_upline.id,
          cost_price: data.users_by_pk.users_upline.current_rank_price
            ? data.users_by_pk.users_upline.current_rank_price
            : data.users_by_pk.users_upline.joining_rank_price,
          selling_price: selectedPackage.unit_price,
          country: data.users_by_pk.user_country.id,
        },
      }).then(() => {
        toast.dismiss();
        refetch();
        toast.success("Order placed!", { autoClose: 1500 });
        setTimeout(() => {
          history.push("/orders");
        }, 1500);
      });
    } catch (error) {
      toast.dismiss();
      toast.error(upline_package);
      toast.error(error.message);
    }
  };
  return (
    <Wrapper id="repeat-order-wrapper" ref={scrollHeight}>
      <PageTitle
        bgColor={bgColor}
        title={dictionary.repeatOrder + "|" + dictionary.upgrade}
      />
      <Back
        onClick={() => {
          history.goBack();
        }}
      />
      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {!loading && (
        <>
          <PackageTable>
            <TableHeader>
              <TableItem>{dictionary.package}</TableItem>
              <TableItem>{dictionary.minOrder}</TableItem>
              <TableItem>
                {dictionary.unitP} (
                {data &&
                  data.users_by_pk &&
                  data.users_by_pk.user_country.currency}
                )
              </TableItem>
            </TableHeader>
            {data &&
              data.users_by_pk &&
              data.users_by_pk.user_country.country_packages.map(
                (packageI, index) => {
                  if (
                    data.package_order.length > 0 &&
                    data.package_order[0].order_package.min === packageI.min &&
                    data.package_order[0].order_package.unit_price <
                      packageI.unit_price
                  ) {
                    return;
                  } else {
                    return (
                      <Row
                        key={index}
                        name={
                          <>
                            <p>{packageI.name}</p>
                            <br />
                            <p>{packageI.chinese_name}</p>
                          </>
                        }
                        price={packageI.unit_price}
                        min_order={
                          (data.package_order.length > 0 &&
                            data.package_order[0].order_package.weight ===
                              packageI.weight &&
                            data.package_order[0].order_package.min) ||
                          packageI.min
                        }
                      />
                    );
                  }
                }
              )}
          </PackageTable>
          <QuantityWrapper>
            <Subtitle>{dictionary.orderQ}</Subtitle>
            <InputWrapper>
              <Input
                label={dictionary.qtt}
                type="number"
                placeholder={"0"}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setQuantity("");
                  } else if (isNaN(parseInt(e.target.value))) {
                    setQuantity(quantity);
                  }
                  setQuantity(e.target.value);
                  let packageList =
                    data.users_by_pk.user_country.country_packages;
                  let maxOrder = packageList[0].min;
                  let orderQuantity = e.target.value;
                  if (orderQuantity < packageList[packageList.length - 1].min) {
                    return setSelectedPackage("min");
                  } else if (orderQuantity > maxOrder) {
                    return setSelectedPackage("max");
                  }
                  if (orderQuantity === maxOrder) {
                    return setSelectedPackage(
                      packageList[packageList.length - 1]
                    );
                  }
                  if (
                    data.package_order.length > 0 &&
                    orderQuantity >= data.package_order[0].order_package.min
                  ) {
                    return setSelectedPackage(packageList[0]);
                  }
                  for (var i = 0; i <= packageList.length - 1; i++) {
                    if (orderQuantity >= packageList[i].min) {
                      return setSelectedPackage(packageList[i]);
                    }
                  }
                }}
                value={quantity}
              />
            </InputWrapper>
            {/* {data &&
              data.users_by_pk &&
              calculatePackage(
                quantity,
                data.users_by_pk.countryByCountry.packages
              )} */}
            {data &&
              selectedPackage &&
              selectedPackage !== "max" &&
              selectedPackage !== "min" && (
                <SelectedSummary>
                  <Subtitle>{dictionary.summary}</Subtitle>
                  <Row
                    name={dictionary.package}
                    min_order={dictionary.quantity}
                    price={
                      dictionary.unitP +
                      " (" +
                      data.users_by_pk.user_country.currency +
                      ")"
                    }
                  />
                  <Row
                    name={selectedPackage.name}
                    min_order={quantity}
                    price={selectedPackage.unit_price}
                  />
                  <TotalRow>
                    <TotalText>{dictionary.total}: </TotalText>
                    <TotalText>
                      {data.users_by_pk.user_country.currency +
                        " " +
                        CommaFormatted(quantity * selectedPackage.unit_price)}
                    </TotalText>
                  </TotalRow>
                </SelectedSummary>
              )}
          </QuantityWrapper>
        </>
      )}
      {data &&
        selectedPackage &&
        selectedPackage !== "max" &&
        selectedPackage !== "min" &&
        data.users_by_pk.package_orders_aggregate.aggregate.count <= 0 && (
          <>
            <InputWrapper>
              <Input
                label={dictionary.secondPassword}
                placeholder="******"
                type="password"
                value={secret}
                onChange={(e) => {
                  setSecret(e.target.value);
                }}
              />
            </InputWrapper>
            <MainButton
              label="Place Order"
              onClick={(e) => {
                submitForm(
                  e,
                  data.users_by_pk.users_upline.current_rank_price,
                  data.users_by_pk.secret_key
                );
              }}
            />
          </>
        )}
      {data && data.users_by_pk.package_orders_aggregate.aggregate.count > 0 && (
        <ErrorMessage
          onClick={() => {
            history.push("/orders");
          }}
        >
          {dictionary.stillHavePendingOrder}
        </ErrorMessage>
      )}
      {data &&
        selectedPackage &&
        selectedPackage !== "max" &&
        selectedPackage !== "min" &&
        (selectedPackage.pay_company ? (
          <QuantityWrapper>
            <Subtitle>Payment</Subtitle>
            <SummaryText>Bank Name: {data.master[0].bank_name}</SummaryText>
            <SummaryText>
              Bank Account No: {data.master[0].bank_acc_no}
            </SummaryText>
            <SummaryText>
              Bank Account Name: {data.master[0].bank_acc_name}
            </SummaryText>
          </QuantityWrapper>
        ) : (
          <QuantityWrapper>
            <Subtitle>Payment</Subtitle>
            <SummaryText>
              Bank Name: {data.users_by_pk.users_upline.user_banks[0].bank_name}
            </SummaryText>
            <SummaryText>
              Bank Account No:{" "}
              {data.users_by_pk.users_upline.user_banks[0].bank_acc_no}
            </SummaryText>
            <SummaryText>
              Bank Account Name:{" "}
              {data.users_by_pk.users_upline.user_banks[0].bank_acc_name}
            </SummaryText>
          </QuantityWrapper>
        ))}
    </Wrapper>
  );
};
