import React, { useContext } from "react";
import styled from "styled-components";
import PageTitle from "../../components/PageTitle";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Back from "../../components/Back";
import { MY_ORDER_DETAIL } from "../../query/Orders";
import Loader from "../../components/Loader";
import { auth, storage } from "firebase";
import WaitingApprovalStatus from "../../components/WaitingApprovalStatus";
import ApprovedStatus from "../../components/ApprovedStatus";
import { FormatTime, CommaFormatted } from "../../utilities";
import PaymentPending from "../../components/PaymentPending";
import PaymentPaid from "../../components/PaymentPaid";
import Button from "../../components/Button";
import ImageUploader from "../../components/ImageUploader";
import { toast } from "react-toastify";
import { UPDATE_SLIP } from "../../mutations/Orders";
import { LanguageStore } from "../../context/LanguageStore";
import CancelledStatus from "../../components/CancelledStatus";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 20vh;
`;
const BackWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 470px;
  left: 50%;
  padding: 12px 20px;
  background-color: rgba(256, 256, 256, 0.6);

  transform: translate(-50%, 0%);
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.lightGreyColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
`;

const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;
const Container = styled.div`
  background-color: white;
  margin-bottom: 8px;
  padding: 20px;
`;

const DetailsWrapper = styled.div`
  padding: 12px 0px;
`;

const Slip = styled.img`
  object-fit: contain;
`;
export default ({ history, location }) => {
  let orderId = location.search.split("=")[1];
  let order = {};
  const { dictionary } = useContext(LanguageStore);
  const [updateSlip] = useMutation(UPDATE_SLIP);
  const { data, loading, refetch, error } = useQuery(MY_ORDER_DETAIL, {
    variables: {
      id: orderId,
      uid: auth().currentUser.uid,
    },
  });
  if (data) {
    order = data.package_order[0];
  }
  const checkStatus = () => {
    switch (data.package_order[0].status) {
      case "Pending":
        return <WaitingApprovalStatus />;
      case "Approved":
        return <ApprovedStatus />;
      default:
        return <CancelledStatus />;
    }
  };

  const checkPayment = () => {
    switch (data.package_order[0].payment_slip) {
      case null:
        return <PaymentPending />;
      default:
        return <PaymentPaid />;
    }
  };

  const submitSlip = (e) => {
    toast.warning(dictionary.uploadingSlip, { autoClose: false });
    let imgRef = storage().ref(
      "payment_slip/" +
        auth().currentUser.uid +
        "/package_order/" +
        order.id +
        "/slip.png"
    );
    let storingImg = imgRef.put(e.target.files[0]);
    storingImg.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      () => {
        storingImg.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          updateSlip({
            variables: {
              id: data && order.id,
              url: downloadUrl,
            },
          })
            .then(() => {
              toast.dismiss();
              refetch();
              toast.success(dictionary.slipUpdated);
            })
            .catch((e) => {
              toast.dismiss();
              toast.error(e.message);
            });
        });
      }
    );
  };
  return (
    <Wrapper>
      <BackWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </BackWrapper>
      {!loading && data ? (
        <PageWrapper>
          <Container>
            <StatusContainer>{data && checkStatus()}</StatusContainer>
          </Container>
          <Container>
            <Header>
              <Time>
                {dictionary.orderID}: {data && order.id}
              </Time>
              <Time>{data && FormatTime(data && order.created_at)}</Time>
            </Header>
            <DetailsWrapper>
              <Name>
                {data && order.order_package.name} (
                {data && order.order_package.chinese_name})
              </Name>
              {!(
                (data && order.order_package.big_offer === true) ||
                (data && order.order_package.half === true)
              ) ? (
                <Quantity>
                  {data && order.quantity} x{" "}
                  {data && order.order_package.package_country.currency}{" "}
                  {data && order.selling_price} ={" "}
                  <Price>
                    {data && order.order_package.package_country.currency}{" "}
                    {CommaFormatted(order.quantity * order.selling_price)}
                  </Price>
                </Quantity>
              ) : (
                <Quantity>
                  {data && order.system_offer + order.quantity} x{" "}
                  {data && order.order_package.package_country.currency}{" "}
                  {data && order.selling_price} ={" "}
                  <Price>
                    {data && order.order_package.package_country.currency}{" "}
                    {CommaFormatted(
                      (order.system_offer + order.quantity) *
                        order.selling_price
                    )}
                  </Price>
                </Quantity>
              )}

              {data &&
                order.order_package.system_offer !== 0 &&
                !(
                  (data && order.order_package.big_offer === true) ||
                  (data && order.order_package.half === true)
                ) && (
                  <Price>
                    - Free {data && order.order_package.system_offer} unit(s)
                  </Price>
                )}
            </DetailsWrapper>
          </Container>
          <Container>
            <Header>
              <Title>{dictionary.payment}</Title>
              {data && checkPayment()}
            </Header>
            <DetailsWrapper>
              <Row>
                <Left>
                  <Name>
                    {data &&
                    order.order_package.pay_company &&
                    !data &&
                    order.order_package.half
                      ? data.master[0].bank_acc_name
                      : data &&
                        order.user.users_upline.user_banks[0].bank_acc_name}
                  </Name>
                  <Quantity>
                    {dictionary.bankAccNo}:{" "}
                    <Price>
                      {data &&
                      order.order_package.pay_company &&
                      !data &&
                      order.order_package.half
                        ? data.master[0].bank_acc_no
                        : data &&
                          order.user.users_upline.user_banks[0].bank_acc_no}
                    </Price>
                  </Quantity>
                  <Quantity>
                    {dictionary.bankName}:{" "}
                    <Price>
                      {data &&
                      order.order_package.pay_company &&
                      !data &&
                      order.order_package.half
                        ? data.master[0].bank_name
                        : data &&
                          order.user.users_upline.user_banks[0].bank_name}
                    </Price>
                  </Quantity>
                </Left>
                {data && data.package_order[0].payment_slip ? (
                  <Right>
                    <Slip
                      src={data && data.package_order[0].payment_slip}
                      height={60}
                      width={60}
                    />
                  </Right>
                ) : (
                  <></>
                )}
              </Row>
              <ImageUploader
                placeholder={
                  data && data.package_order[0].payment_slip
                    ? dictionary.resubmitSlip
                    : dictionary.uploadSlip
                }
                onChange={submitSlip}
              />
            </DetailsWrapper>
          </Container>
        </PageWrapper>
      ) : (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};
