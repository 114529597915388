import React from "react";
import styled, { withTheme } from "styled-components";

const Wrapper = styled.div`
  color: ${(props) => props.theme.subColor};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
  margin: 8px;
  font-weight: 600;
`;

const Text = styled.div``;

export default withTheme(({ onClick, color, theme, ref }) => {
  return (
    <Wrapper
      ref={ref}
      textColor={color || theme.darkBlueColor}
      onClick={onClick}
    >
      <Text>{`<`} Back</Text>
    </Wrapper>
  );
});
