import React, { useState, useContext } from "react";
import styled from "styled-components";
import Back from "../components/Back";
import { BsQuestionCircle } from "react-icons/bs";
import Help from "../components/Help";
import { useQuery } from "@apollo/react-hooks";
import { GET_CB, GET_STOCKS } from "../query/Bonus";
import { auth } from "firebase";
import { FormatTime } from "../utilities";
import Loader from "../components/Loader";
import { LanguageStore } from "../context/LanguageStore";
import CheckOrderStatus from "../components/CheckOrderStatus";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15vh;
`;
const Header = styled.div`
  background: linear-gradient(
    to right,
    ${(props) => props.theme.mainShade},
    ${(props) => props.theme.mainColor}
  );
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 120px;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: center;
`;

const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
  padding-bottom: 0px;
`;

const Title = styled.div`
  color: white;
  font-weight: 800;
  font-size: 18px;
`;

const Touchable = styled.div`
  color: transparent;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Figure = styled.h1`
  font-weight: 800;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: white;
`;

const Prefix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;
const Suffix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;

const Description = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: white;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 20px;
`;
const SmallButton = styled.div`
  border-radius: 12px;
  background-color: white;
  padding: 6px 12px;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
  box-shadow: ${(props) => props.theme.boxShadow};
  cursor: pointer;
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow}
      ${(props) => props.theme.boxShadow};
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const RecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const CBRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const Date = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
`;
const RowName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;

const EmptyText = styled.h2`
  ${(props) => props.theme.title};
  font-size: 16px;
  text-align: center;
`;
export default ({ history }) => {
  const [showHelp, setShowHelp] = useState(false);
  const { dictionary } = useContext(LanguageStore);
  const { loading, data } = useQuery(GET_STOCKS, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });
  return (
    <Wrapper>
      <Header>
        <BackWrapper>
          <Back
            color={"white"}
            onClick={() => {
              history.goBack();
            }}
          />
          <Title>{dictionary.stockB}</Title>
          <Touchable
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <BsQuestionCircle size={18} />
          </Touchable>
        </BackWrapper>
        <Figure>
          <Prefix></Prefix>
          {((data && data.users_by_pk.stock_balance) || 0) + " "}
          <Suffix></Suffix>
        </Figure>
        <Description></Description>
        <SmallButtonWrapper>
          <SmallButton
            onClick={() => {
              history.push("/shipment_history");
            }}
          >
            {dictionary.viewShipment}
          </SmallButton>
        </SmallButtonWrapper>
      </Header>
      <RecordWrapper>
        {loading && data === undefined && <Loader />}
        {data && data.stock_record.length <= 0 && (
          <EmptyText>{dictionary.noRecord}</EmptyText>
        )}
        {data &&
          data.stock_record.length > 0 &&
          data.stock_record.map((cb, index) => {
            return (
              <CBRow
                onClick={() => {
                  if (cb.item === "package") {
                    history.push("/my_order?order_id=" + cb.order_id);
                  } else if (cb.item === "downline") {
                    history.push("/approve_orders?order_id=" + cb.order_id);
                  } else if (cb.item === "product") {
                    history.push("/shipment_details?order_id=" + cb.order_id);
                  } else if (cb.item === "retail") {
                    history.push(`/order_tracking?order_id=${cb.order_id}`);
                  }
                }}
                key={index}
              >
                <Row>
                  <Date>{FormatTime(cb.created_at)}</Date>
                  <Date>
                    <CheckOrderStatus status={cb.status} />
                    {dictionary.orderID}: {cb.order_id}
                  </Date>
                </Row>
                <Row>
                  <RowName>
                    {cb.item === "package" &&
                      dictionary.repeatOrder + "/" + dictionary.upgrade}
                    {cb.item === "downline" && "Downline Order"}
                    {cb.item === "product" && "Product Shipment"}
                    {cb.item === "retail" && "Retail Order"}
                  </RowName>
                  <RowName>
                    {cb.status !== "Cancelled" && (cb.add ? "+" : "-")}{" "}
                    {cb.quantity}
                  </RowName>
                </Row>
              </CBRow>
            );
          })}
      </RecordWrapper>
      {/* {showHelp && (
        <Help
          close={() => {
            setShowHelp(false);
          }}
          title={"Cash Back (CB)"}
          content={
            <p>
              CB(s) are rewarded when you purchase stocks as an agent.
              <br />
              <br />
              20CBs are rewarded for each unit you purchase. (1CB = RM1)
              <br />
              <br />
              You may use CB to shop from our mall!
              <br />
              (Coming soon)
            </p>
          }
        />
      )} */}
    </Wrapper>
  );
};
