import React from "react";
import styled from "styled-components";

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 12px 0px;
  align-items: center;
  border-style: solid;
  border-color: ${(props) => props.theme.subColor};
  border-top-width: 1px;
`;

const TableItem = styled.p`
  width: 100px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 8px;
  color: ${(props) => props.theme.subColor};
`;

export default ({ name, price, min_order }) => {
  return (
    <TableHeader>
      <TableItem>{name}</TableItem>
      <TableItem>{min_order}</TableItem>
      <TableItem>{price}</TableItem>
    </TableHeader>
  );
};
