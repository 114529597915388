import React, { useState, useRef, useEffect, useContext } from "react";
import styled, { withTheme, keyframes } from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { USER_INFO } from "../query/Users";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { Redirect } from "react-router-dom";
import PWAPopup from "../components/PWAPopup";
import { AiFillPhone } from "react-icons/ai";
import { BsArrowRepeat } from "react-icons/bs";
import { FaCartPlus, FaShuttleVan } from "react-icons/fa";
import { MdStoreMallDirectory } from "react-icons/md";
import UplineContactCard from "../components/UplineContactCard";
import { CommaFormatted } from "../utilities";
import { LanguageStore } from "../context/LanguageStore";
import LanguageSelector from "../components/LanguageSelector";
import DashboardSlideshow from "../components/DashboardSlideshow";
import ThemeBG from "../components/Images/ThemeBG.png";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding-bottom: 10vh;
  height: 100vh;
  overflow-y: auto;
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const LoaderWrapper = styled.div`
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 999;
  position: fixed;
`;

const ContactUpline = styled.div`
  position: fixed;
  bottom: 0;
  flex-direction: column;
  cursor: pointer;
  right: 50%;
  transform: translate(400%, 0%);
  margin-left: ${(props) => props.marginLeft};
  margin-bottom: 90px;
  margin-rigth: 12px;
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  height: 60px;
  z-index: 30;
  background-color: ${(props) => props.theme.mainShade};
  @media (max-width: 470px) {
    right: 0;
    transform: none;
    margin-right: 12px;
  }
`;

const UplineContactText = styled.p`
  font-size: 11px;
  color: white;
  margin-top: 4px;
`;

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const NameHeader = styled.div`
  padding: 20px;
  height: 150px;
  padding-top: 30px;
  background-color: ${(props) => props.theme.mainShade};
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 50%;
`;

const NameLanguage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const RankName = styled.span`
  font-weight: 400;
`;
const Name = styled.h1`
  ${(props) => props.theme.title};
  color: white;
  padding-right: 12px;
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
`;
const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 12px;
  padding-top: 0px;
`;
const StatsContainer = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 42px;
  background-color: ${(props) => props.theme.white};
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  padding-top: 8px;
  overflow: hidden;
`;

const StatsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const StatsBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 32px;
  cursor: pointer;
  margin: 0px 12px;
  background-color: white;
  :last-child {
    margin-right: 0px;
  }
  :first-child {
    margin-left: 0px;
  }
  box-shadow: ${(props) => props.theme.boxShadow};
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow},
      ${(props) => props.theme.boxShadow};
  }
`;

const Figure = styled.h4`
  ${(props) => props.theme.title};
  font-size: 18px;
  text-align: center;
  margin: 6px 0px;
  text-align: center;
  color: ${(props) => props.textcolor};
`;

const FigureText = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: ${(props) => props.theme.lightBlackColor};
`;

const Glow = keyframes`
  0% {
    box-shadow: 0px 4px 4px rgb(255, 96, 96), 0px 4px 4px rgb(255, 96, 96), 0px 4px 4px rgb(255, 96, 96);
  }
  50% {
    box-shadow: 0px 4px 4px rgb(255, 195, 30), 0px 4px 4px rgb(255, 195, 30), 0px 4px 4px rgb(255, 195, 30);
  }
  100% {
    box-shadow: 0px 4px 4px rgb(255, 96, 96), 0px 4px 4px rgb(255, 96, 96), 0px 4px 4px rgb(255, 96, 96);
  }
`;

const Notification = styled.div`
  width: 100%;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 12px;
  transition: 0.2s;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  // animation: ${Glow} 1s infinite linear;
`;

const MenuWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 100%;
`;
const BoldText = styled.span`
  color: rgb(255, 96, 96);
  font-weight: 800;
`;
const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const MenuButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  cursor: pointer;
  width: ${(props) => props.width}px;
  transition: 0.4s;
  border-radius: 20px;
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow};
  }
`;

const MenuText = styled.p`
  ${(props) => props.theme.title}
  font-weight: 400;
  text-align: center;
  font-size: 11px;
  margin-top: 4px;
  line-height: 18px;
`;

const ComingSoon = styled.div`
  background-color: ${(props) => props.theme.orangeColor};
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  font-size: 11px;
  color: white;
  font-weight: 600;
`;
const MenuButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.mainColor};
`;

const SliderWrapper = styled.div`
  padding: 8px 0px;
  width: 100%;
  margin-top: 60px;
`;
export default withTheme(({ history, theme }) => {
  let date = new Date();
  const [menuWidth, setMenuWidth] = useState(0);
  const [showPWA, setShowPWA] = useState(
    localStorage.getItem("pwa") === "show"
  );
  const { dictionary } = useContext(LanguageStore);
  const [showContact, setShowContact] = useState(false);
  const { data, loading, error } = useQuery(USER_INFO, {
    variables: {
      id: auth().currentUser.uid,
    },
  });

  console.log(data, error, loading);
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef.current) {
      setMenuWidth(menuRef.current.offsetWidth);
    }
  });
  if (loading || data === undefined) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }
  if (data && data.users_by_pk === null) {
    return <Redirect to="/account_set_up" />;
  }

  const checkColor = (figure) => {
    switch (true) {
      case figure === 0:
        return theme.errorRedColor;
      case figure < 31:
        return theme.orangeColor;
      default:
        return "black";
    }
  };

  return (
    <Wrapper>
      <PageWrapper>
        <NameHeader>
          <NameLanguage>
            <Name>
              {dictionary.welcomeHome} {data.users_by_pk.name}
              <RankName>
                {" "}
                (
                {data.users_by_pk.current_rank_name
                  ? data.users_by_pk.current_rank_name
                  : data.users_by_pk.joining_rank_name}
                )
              </RankName>
            </Name>
            <LanguageSelector />
          </NameLanguage>
        </NameHeader>
        <ContentWrapper>
          <StatsContainer>
            <StatsWrapper>
              <StatsBox
                onClick={() => {
                  history.push("/stock_balance");
                }}
              >
                <Figure
                  textcolor={
                    data && checkColor(data.users_by_pk.stock_balance || 0)
                  }
                >
                  {data && (data.users_by_pk.stock_balance || 0)}
                </Figure>
                <FigureText>{dictionary.stocks}</FigureText>
              </StatsBox>
              <StatsBox
                onClick={() => {
                  history.push("/cash_wallet");
                }}
              >
                <Figure>
                  {data && data.cash_wallet_records_aggregate
                    ? data.cash_wallet_records_aggregate.aggregate.sum.amount
                      ? CommaFormatted(
                          data.cash_wallet_records_aggregate.aggregate.sum
                            .amount
                        )
                      : 0
                    : "N/A"}
                </Figure>
                <FigureText>{dictionary.cashWallet}</FigureText>
              </StatsBox>
              <StatsBox
                onClick={() => {
                  history.push("/network");
                }}
              >
                <Figure>
                  {data && (data.users_by_pk.downline_count || 0)}
                </Figure>
                <FigureText>{dictionary.downline}</FigureText>
              </StatsBox>
            </StatsWrapper>
          </StatsContainer>
          {data && data.users_by_pk.pending_approval_orders_count > 0 && (
            <Notification
              onClick={() => {
                history.push("/orders?action=approval");
              }}
            >
              <BoldText>
                {data.users_by_pk.pending_approval_orders_count}{" "}
                {dictionary.orders}
              </BoldText>{" "}
              {dictionary.pendingApproval}
            </Notification>
          )}
          <MenuWrapper>
            <MenuRow ref={menuRef}>
              <MenuButtonWrapper
                width={menuWidth * 0.25}
                onClick={() => {
                  history.push("/repeat_order");
                }}
              >
                <MenuButton>
                  <BsArrowRepeat size={30} />
                </MenuButton>
                <MenuText>
                  {dictionary.repeatOrder} <br />/ {dictionary.upgrade}
                </MenuText>
              </MenuButtonWrapper>
              {/* <MenuButtonWrapper
                width={menuWidth * 0.25}
                onClick={() => {
                  history.push("/monthly_maintenance");
                }}
              >
                <MenuButton>
                  <FaCartPlus size={30} />
                </MenuButton>
                <MenuText>{dictionary.mmsOrder}</MenuText>
              </MenuButtonWrapper> */}
              <MenuButtonWrapper
                width={menuWidth * 0.25}
                onClick={() => {
                  history.push("/request_shipping");
                }}
              >
                <MenuButton>
                  <FaShuttleVan size={30} />
                </MenuButton>
                <MenuText>{dictionary.reqShipping}</MenuText>
              </MenuButtonWrapper>
              {/* <MenuButtonWrapper width={menuWidth * 0.25}>
                <MenuButton>
                  <MdStoreMallDirectory size={30} />
                </MenuButton>
                <MenuText>{dictionary.mall}</MenuText>
                <ComingSoon>{dictionary.comingSoon}</ComingSoon>
              </MenuButtonWrapper> */}
            </MenuRow>
          </MenuWrapper>
          {!loading && data && data.news && data.news.length > 0 && (
            <SliderWrapper>
              <DashboardSlideshow data={data.news} />
            </SliderWrapper>
          )}
        </ContentWrapper>
      </PageWrapper>
      {showPWA && (
        <PWAPopup
          close={() => {
            localStorage.setItem("pwa", "no");
            setShowPWA(false);
          }}
        />
      )}
      <ContactUpline
        onClick={() => {
          setShowContact(true);
        }}
      >
        <AiFillPhone size={24} color={"white"} />
        <UplineContactText>Upline</UplineContactText>
      </ContactUpline>
      {!loading && showContact && data && data.users_by_pk && (
        <UplineContactCard
          close={() => {
            setShowContact(false);
          }}
          contact={data.users_by_pk.users_upline}
        />
      )}
    </Wrapper>
  );
});
