import React, { useContext, useState } from "react";
import styled from "styled-components";
import { auth } from "firebase";
import ProfileStore from "../context/ProfileContext";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROFILE_DETAILS } from "../query/Users";
import Input from "../components/Input";
import Loader from "../components/Loader";
import ProfileSettingForm from "../components/ProfileSettingForm";
import { Redirect } from "react-router-dom";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15vh;
  z-index: 55;
`;

const SignOutButton = styled.h1`
  ${(props) => props.theme.title}
  width: 100%;
  text-align: center;
  margin-top: 12px;
  color: ${(props) => props.theme.errorRedColor};
`;

const Section = styled.div`
  padding: 12px 20px;
  padding-bottom: 40px;
`;

const SectionTitleWrapper = styled.div`
  width: 100%;
  padding: 12px 20px;
  background-color: white;
  position: sticky;
  top: 0;
  padding-top: 24px;
`;
const SectionTitle = styled.h1`
  ${(props) => props.theme.title};
`;

const InputWrapper = styled.div`
  padding: 12px 0px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default ({ history }) => {
  const { setIsLoggedIn } = useContext(ProfileStore);
  const { data, loading, error, refetch } = useQuery(GET_PROFILE_DETAILS, {
    variables: {
      id: auth().currentUser.uid,
    },
  });
  if (data && data.users_by_pk === null) {
    return <Redirect to="/" />;
  }
  if (data && data.users_by_pk) {
    return (
      <ProfileSettingForm
        refetch={refetch}
        user={data.users_by_pk}
        history={history}
      />
    );
  } else {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    );
  }
};
