import React, { useState, useContext } from "react";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import { useQuery } from "@apollo/react-hooks";
import { GET_COLLECTIONS, GET_GALLERY_IMAGE } from "../query/Gallery";
import Loader from "../components/Loader";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBG from "../components/Images/ThemeBG.png";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 15vh;
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const Box = styled.div`
  width: 100%:
  height: 120px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.orangeColor};
  color: white;
  padding: 16px;
  margin-bottom: 12px;
  transition: 0.4s;
  cursor: pointer;
  :hover{
    background-color: ${(props) => props.theme.errorRedColor}
  }
`;

const BoxTitle = styled.h1`
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 16px;
`;

const BigText = styled.h1`
  font-size: 22px;
  font-weight: 800;
  width: 100%;
  margin-top: 12px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const Suffix = styled.span`
  font-size: 18px;
  font-weight: 400;
`;

const GalleryRow = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 12px 0px;
  width: 100%;
  max-height: ${(props) => props.max};
  transition: 0.4s;
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
  min-height: 56px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const GalleryPreview = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 40px;
  overflow: hidden;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.lightGreyColor};
`;

const GalleryText = styled.p`
  ${(props) => props.theme.title};
  font-weight: 400;
  font-size: 16px;
  margin-left: 8px;
  text-align: left;
`;

const ViewMore = styled.p`
  margin: 12px 0px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.darkBlueColor};
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;
const Clickable = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  border-radius: 16px;
  color: ${(props) => props.theme.darkBlueColor};
  :hover {
    color: white;
    background-color: ${(props) => props.theme.mainShade};
  }
`;
const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default ({ history }) => {
  const { data, loading, error } = useQuery(GET_COLLECTIONS);
  const { dictionary } = useContext(LanguageStore);
  const [viewMore, setViewMore] = useState(false);
  return loading ? (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      <PageTitle title={dictionary.sharingMaterial} />
      <BigText>{dictionary.gallery}</BigText>
      <GalleryRow max={viewMore ? "auto" : "228px"}>
        {data && data.gallery_collection.length > 0 ? (
          data.gallery_collection.map((gallery, index) => {
            return (
              <GalleryWrapper
                key={index}
                onClick={() => {
                  history.push("/gallery_images?collection_id=" + gallery.id);
                }}
              >
                {/* <GalleryPreview>
                  {gallery.gallery_images.length > 0 ? (
                    <img
                      src={gallery.gallery_images[0].url}
                      height={100}
                      width={100}
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <p style={{ fontSize: 12, textAlign: "center" }}>
                      No image yet.
                    </p>
                  )}
                </GalleryPreview> */}
                <GalleryText>{gallery.name}</GalleryText>
              </GalleryWrapper>
            );
          })
        ) : (
          <p>No gallery yet.</p>
        )}
      </GalleryRow>
      <ViewMore
        onClick={() => {
          setViewMore(!viewMore);
        }}
      >
        {viewMore ? dictionary.showLess : dictionary.viewMore}
      </ViewMore>
      <BigText>{dictionary.retailSharing}</BigText>
      <Clickable
        onClick={() => {
          // toast.warning("Coming soon!");
          history.push("/product_menu");
        }}
      >
        {dictionary.gotoRetail}
      </Clickable>
      {/* <Clickable
        onClick={() => {
          copy(window.location.origin + "/#/product_menu");
          toast.success("Copied to clipboard!", { autoClose: 1200 });
        }}
      >
        Share link
      </Clickable> */}
      {!loading && data && data.about.length > 0 && (
        <BigText>{dictionary.aboutUs}</BigText>
      )}
      {!loading &&
        data &&
        data.about.length > 0 &&
        data.about.map((about, index) => {
          return (
            <Clickable
              onClick={() => {
                history.push("/about?page_id=" + about.id);
              }}
            >
              {about.name}
            </Clickable>
          );
        })}
      {/* <BigText>{dictionary.aboutUs}</BigText>
      <Clickable
        onClick={() => {
          history.push("/about");
        }}
      >
        {dictionary.brandStory}
      </Clickable> */}
      {/* <Clickable
        onClick={() => {
          history.push("/team_culture");
        }}
      >
        {dictionary.culture}
      </Clickable>
      <Clickable
        onClick={() => {
          history.push("/brand_activities");
        }}
      >
        品牌活动
      </Clickable> */}
    </Wrapper>
  );
};
