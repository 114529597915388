import React, { useContext } from "react";
import styled from "styled-components";
import { AiFillFacebook, AiOutlineWhatsApp } from "react-icons/ai";
import { IoLogoWhatsapp, IoMdCopy } from "react-icons/io";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  pointer-events: none;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const ScreenWrapper = styled.div`
  width: 100%;
  max-width: 470px;
  height: 100vh;
  display: flex;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
`;

const Box = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 8px;
  width: 32px;
  pointer-events: auto;
  box-shadow: ${(props) => props.theme.boxShadow};
`;
const Container = styled.div`
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;
export default ({ url }) => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper>
      <ScreenWrapper>
        <Box>
          <Container
            onClick={() => {
              window.open(
                "https://www.facebook.com/v3.3/dialog/share?app_id=196681948428929&href=" +
                  url
              );
            }}
          >
            <AiFillFacebook size={18} color={"#39579A"} />
          </Container>
          <Container
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?text=" + encodeURIComponent(url)
              );
            }}
          >
            <IoLogoWhatsapp size={18} color={"#06D253"} />
          </Container>
          <Container>
            <IoMdCopy
              size={18}
              onClick={() => {
                copy(url);
                toast.success(dictionary.copyClipB);
              }}
            />
          </Container>
        </Box>
      </ScreenWrapper>
    </Wrapper>
  );
};
