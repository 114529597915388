import React, { useContext } from "react";
import styled from "styled-components";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { LanguageStore } from "../../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 12px;
  background-color: white;
  border-radius: 12px;
  width: 100%;
`;

const Description = styled.p`
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0px;
  padding: 0px 12px;
  padding-bottom: 8px;
`;

const ErrorMessage = styled.div`
  width: 100%;
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  border-style: solid 2px ${(props) => props.theme.errorDarkRedColor};
  background-color: ${(props) => props.theme.errorRedColor};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 470px;
  bottom: 0;
  left: 50%;
  padding: 20px;
  padding-bottom: 30px;
  transform: translate(-50%, 0%);
  z-index: 8;
`;

const Back = styled.p`
  width: 100%;
  text-decoration: underline;
  text-align: center;
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  font-size: 14px;
  margin-top: 8px;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;
const AddressInfo = ({
  setAction,
  bankName,
  bankAccNo,
  bankAccName,
  setBankName,
  setBankAccNo,
  setBankAccName,
}) => {
  const { dictionary } = useContext(LanguageStore);
  const submitForm = () => {
    if (bankName === "") {
      return toast.error("Please enter your bank name!", { autoClose: 1500 });
    }
    if (bankAccNo === "") {
      return toast.error("Please enter bank account no!", { autoClose: 1500 });
    }
    if (bankAccName === "") {
      return toast.error("Please enter your bank account name!", {
        autoClose: 1500,
      });
    }
    setAction(5);
  };
  return (
    <Wrapper>
      <InputWrapper>
        <Input
          label={dictionary.bankName}
          value={bankName}
          onChange={(e) => {
            setBankName(e.target.value);
          }}
          placeholder="e.g. Maybank"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={dictionary.bankAccNo}
          value={bankAccNo}
          onChange={(e) => {
            setBankAccNo(e.target.value);
          }}
          placeholder="e.g. 15xxxxxxxxxx"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={dictionary.bankAccName}
          value={bankAccName}
          onChange={(e) => {
            setBankAccName(e.target.value);
          }}
          placeholder={dictionary.yourName}
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button label={dictionary.next} onClick={submitForm} />
        <Back
          onClick={() => {
            setAction(3);
          }}
        >
          {dictionary.backToDelivery}
        </Back>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default AddressInfo;
