import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import Input from "../../components/Input";
import { auth } from "firebase";
import { CHECK_IC_USERNAME } from "../../query/Users";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { LanguageStore } from "../../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 12px;
  background-color: white;
  border-radius: 12px;
  width: 100%;
`;

const Description = styled.p`
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0px;
  padding: 0px 12px;
  padding-bottom: 8px;
`;

const ErrorMessage = styled.div`
  width: 100%;
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  border-style: solid 2px ${(props) => props.theme.errorDarkRedColor};
  background-color: ${(props) => props.theme.errorRedColor};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 470px;
  bottom: 0;
  left: 50%;
  padding: 20px;
  padding-bottom: 30px;
  transform: translate(-50%, 0%);
  z-index: 8;
`;

const Back = styled.p`
  width: 100%;
  text-decoration: underline;
  text-align: center;
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  font-size: 14px;
  margin-top: 8px;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;
export default ({
  name,
  username,
  phone,
  ic,
  secret,
  setAction,
  setName,
  setUsername,
  setPhone,
  setIc,
  setSecret,
}) => {
  const suggestUsername = auth().currentUser.email.split("@")[0];
  const [checkingIc, setCheckingIc] = useState(false);
  const { dictionary } = useContext(LanguageStore);
  const { data, loading } = useQuery(CHECK_IC_USERNAME, {
    variables: {
      ic: checkingIc ? ic : null,
      username: checkingIc ? null : username,
    },
  });

  const submitForm = (e) => {
    e.preventDefault();
    if (name === "") {
      return toast.error("Please enter your name", { autoClose: 1400 });
    }
    if (username === "") {
      return toast.error("Please enter your username", { autoClose: 1400 });
    }
    if (phone === "") {
      return toast.error("Please enter your phone no", { autoClose: 1400 });
    }
    if (ic === "") {
      return toast.error("Please enter your ic no", { autoClose: 1400 });
    }
    if (secret === "") {
      return toast.error("Please enter your secret key", { autoClose: 1400 });
    }
    setAction(3);
  };
  return (
    <Wrapper>
      <InputWrapper>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          label={dictionary.fullName}
          placeholder="as per IC"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          value={username}
          onChange={(e) => {
            var letters = /^[A-Za-z0-9]+$/;
            if (e.target.value.match(letters) || e.target.value === "") {
              setCheckingIc(false);
              setUsername(e.target.value);
            }
          }}
          label={dictionary.userName}
          placeholder={suggestUsername}
        />
        <Description>{dictionary.usernameTip}</Description>
        {!checkingIc && data && data.users.length > 0 && (
          <ErrorMessage>{dictionary.usernameTaken}</ErrorMessage>
        )}
      </InputWrapper>
      <InputWrapper>
        <Input
          value={phone}
          onChange={(e) => {
            if (!isNaN(e.target.value)) {
              setPhone(e.target.value);
            }
          }}
          label={dictionary.phoneNo}
          placeholder="01xxxxxxxx"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          value={ic}
          onChange={(e) => {
            setCheckingIc(true);
            setIc(e.target.value);
          }}
          label="IC No."
          placeholder="90xxxx08xxxx"
        />
        <Description>{dictionary.icWarning}</Description>
        {checkingIc && data && data.users.length > 0 && (
          <ErrorMessage>{dictionary.icTaken}</ErrorMessage>
        )}
      </InputWrapper>
      <InputWrapper>
        <Input
          type="password"
          value={secret}
          onChange={(e) => {
            setSecret(e.target.value);
          }}
          placeholder="******"
          label={dictionary.secondPassword}
        />
        <Description>{dictionary.secondPasswordTip}</Description>
      </InputWrapper>
      <ButtonWrapper>
        <Button label={dictionary.next} onClick={submitForm} />
        <Back
          onClick={() => {
            setAction(1);
          }}
        >
          {dictionary.backToSelectPackage}
        </Back>
      </ButtonWrapper>
    </Wrapper>
  );
};
