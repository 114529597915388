import React from "react";
import styled from "styled-components";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Container = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.darkBlueColor};
  :hover {
    opacity: 0.7;
  }
`;

export default ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <IoIosCloseCircleOutline size={22} />
    </Container>
  );
};
