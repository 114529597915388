import React, { useState, useContext } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import MainButton from "../components/Button";
import { LanguageStore } from "../context/LanguageStore";
import { auth } from "firebase";
import { GET_USERNAME } from "../query/Users";
import Loader from "../components/Loader";
import { useQuery } from "@apollo/react-hooks";
import ShareOptions from "../components/ShareOptions";
import ThemeBG from "../components/Images/ThemeBG.png";
import { FaPlus } from "react-icons/fa";
import NewHalfTicketForm from "../components/NewHalfTicketForm";
const Wrapper = styled.div`
  padding: 80px 12px;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 15vh;
  font-family: ${(props) => props.theme.mainFont};
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const Title = styled.h1`
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 22px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const Subtitle = styled.h4`
  font-weight: 400;
  font-size: 18px;
`;

const LinkBox = styled.div`
  text-align: left;
  padding: 8px 12px;
  border-radius: 8px;
  word-wrap: break-word;
  background-color: ${(props) => props.theme.lightGreyColor};
  cursor: pointer;
  margin: 8px 0px;
  margin-bottom: 24px;
  :hover {
    opacity: 0.7;
  }
`;

const ButtonWrapper = styled.div`
  padding: 8px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const PromoWrapper = styled.div`
  border-radius: 16px;
  width: 100%;
  background-color: ${(props) => props.theme.subColor};
  padding: 16px;
`;

const PromoTitle = styled.h1`
  ${(props) => props.theme.title};
  margin-bottom: 12px;
  color: white;
`;

const VoucherRow = styled.div`
  border-radius: 8px;
  padding: 8px;
  background-color: white;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const Claimed = styled.p`
  ${(props) => props.theme.title};
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.greenColor};
`;
const Unclaimed = styled.p`
  ${(props) => props.theme.title};
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.orangeColor};
`;
const PackageName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Code = styled.h4`
  color: ${(props) => props.theme.lightBlackColor};
  ${(props) => props.theme.title};
  font-weight: 600;
  font-size: 12px;
  margin-top: 8px;
`;

const AddVoucherButton = styled.div`
  height: 28px;
  width: 28px;
  background-color: ${(props) => props.theme.mainColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 8px;
  :hover {
    opacity: 0.6;
  }
`;

export default withRouter(({ history, location }) => {
  const [showShare, setShowShare] = useState(false);
  const [createVoucher, setCreateVoucher] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState("");
  let profile = {
    id: auth().currentUser.uid,
  };
  const { dictionary } = useContext(LanguageStore);
  const { data, loading, refetch, error } = useQuery(GET_USERNAME, {
    variables: {
      id: auth().currentUser.uid,
    },
  });
  const copyToClipboard = () => {
    copy(window.location.origin + "/#/?referral=" + data.users_by_pk.username);
    toast.success(dictionary.copyClipB, { autoClose: 1200 });
  };

  const copyVoucher = (thecode, name) => {
    copy(
      `Join me on E+ at: \n ${
        window.location.origin + "/#/?referral=" + data.users_by_pk.username
      } \n \nUse my voucher code to join ${name} with only 50% entry requirement: \n\n${thecode}`
    );
    toast.success(dictionary.copyClipB, { autoClose: 2000 });
  };

  console.log(data, error);
  return loading ? (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      <Title>{dictionary.regBusinessP}</Title>
      <Subtitle>{dictionary.refLink}</Subtitle>
      <LinkBox onClick={copyToClipboard}>
        {window.location.origin}/#/?referral={data.users_by_pk.username}
      </LinkBox>
      <ButtonWrapper>
        <MainButton
          label={dictionary.copyLink}
          onClick={() => {
            setShowShare(true);
            setSelectedUrl(
              window.location.origin +
                "/#/?referral=" +
                data.users_by_pk.username
            );
          }}
        />
      </ButtonWrapper>
      {!loading && data && data.package_order.length > 0 && (
        <SectionWrapper>
          <PromoWrapper>
            <Header>
              <PromoTitle>招募金卷</PromoTitle>
              <AddVoucherButton
                onClick={() => {
                  setCreateVoucher(
                    data.package_order[0].order_package.promo__purchase_package
                  );
                }}
              >
                <FaPlus size={18} color="white" />
              </AddVoucherButton>
            </Header>
            {!loading &&
              data &&
              (data.half_ticket.length > 0
                ? data.half_ticket.map((ht, index) => {
                    return (
                      <VoucherRow
                        key={index}
                        onClick={() => {
                          setShowShare(true);
                          setSelectedUrl(
                            window.location.origin +
                              "/#/?referral=" +
                              data.users_by_pk.username +
                              "=half=" +
                              ht.code
                          );
                          // copyVoucher(ht.code, ht.half_package.chinese_name);
                        }}
                      >
                        <Header>
                          <PackageName>
                            {ht.half_package.chinese_name} -{" "}
                            {ht.half_package.name}
                          </PackageName>
                          {ht.claimed_by === null ? (
                            <Unclaimed>Unclaimed</Unclaimed>
                          ) : (
                            <Claimed>Claimed</Claimed>
                          )}
                        </Header>
                        <Code>Voucher: {ht.code}</Code>
                      </VoucherRow>
                    );
                  })
                : "No voucher")}
          </PromoWrapper>
        </SectionWrapper>
      )}

      {createVoucher !== false && (
        <NewHalfTicketForm
          packages={data.packages}
          refetch={refetch}
          country={data.users_by_pk.country}
          voucher={createVoucher}
          close={() => {
            setCreateVoucher(false);
          }}
        />
      )}
      {showShare && (
        <ShareOptions
          url={selectedUrl}
          close={() => {
            setShowShare(false);
          }}
        />
      )}
    </Wrapper>
  );
});
