import gql from "graphql-tag";

export const GET_PACKAGES_FOR_SETUP = gql`
  query getPackagesForSetup($id: String) {
    packages(
      where: {
        status: { _eq: "active" }
        big_offer: { _eq: false }
        half: { _eq: false }
      }
      order_by: { unit_price: asc }
    ) {
      business_partner_reward
      chinese_name
      country
      drb_reward
      for_new_user
      id
      min
      name
      pay_company
      system_offer
      unit
      unit_price
      package_country {
        currency
      }
    }
    country(where: { status: { _eq: true } }) {
      id
      name
    }
  }
`;

export const GET_PACKAGES_BY_COUNTRY = gql`
  query getPackagesByCountry($id: String!) {
    users_by_pk(id: $id) {
      package_orders_aggregate(
        where: { status: { _eq: "Pending" }, order_type: { _eq: "normal" } }
      ) {
        aggregate {
          count(columns: status)
        }
      }
      user_country {
        country_packages(
          order_by: { min: desc }
          where: {
            for_new_user: { _eq: false }
            status: { _eq: "active" }
            big_offer: { _eq: false }
            half: { _eq: false }
          }
        ) {
          chinese_name
          min
          id
          name
          weight
          unit
          unit_price
          system_offer
          pay_company
        }
        currency
        id
      }
      users_upline {
        id
        current_rank_price
        joining_rank_price
        user_banks {
          bank_acc_name
          bank_acc_no
          bank_name
          id
        }
      }
      secret_key
      id
    }
    master {
      id
      bank_acc_name
      bank_acc_no
      bank_name
    }
    package_order(
      where: {
        buyer_id: { _eq: $id }
        _or: [
          { order_package: { half: { _eq: true } } }
          { order_package: { big_offer: { _eq: true } } }
        ]
        order_package: { break_away: { _eq: true } }
      }
    ) {
      quantity
      package_id
      id
      selling_price
      order_package {
        weight
        id
        unit_price
        min
        system_offer
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($id: String!) {
    products {
      max_order_quantity
      name
      id
      product_img
      status
      unit_measurement
    }
    users_by_pk(id: $id) {
      id
      user_country {
        id
        currency
        country_shipping_rates(where: { status: { _eq: "active" } }) {
          id
          name
          rate
        }
      }
      user_addresses {
        name
        line
        state
        postcode
        city
        id
      }
    }
  }
`;

export const GET_RETAIL_PRODUCTS = gql`
  query getRetailProduct($id: String!) {
    users_by_pk(id: $id) {
      id
      name
      stock_balance
    }
    products {
      max_order_quantity
      name
      id
      product_img
      status
      unit_measurement
    }
    country {
      status
      retail_price
      name
      language
      currency
      id
      country_shipping_rates(where: { status: { _eq: "active" } }) {
        id
        rate
        name
      }
    }
  }
`;

export const SEARCH_BIG_OFFER = gql`
  query findOffer($code: String, $half_code: uuid) {
    big_offer_tickets(where: { code: { _ilike: $code } }) {
      code
      claimed_by
      offer_package {
        chinese_name
        name
        system_offer
        min
        unit_price
        id
        package_country {
          id
          currency
        }
      }
    }
    half_ticket(where: { code: { _eq: $half_code } }) {
      id
      code
      user_id
      claimed_by
      package
      half_package {
        chinese_name
        name
        system_offer
        min
        unit_price
        id
        package_country {
          id
          currency
        }
      }
    }
  }
`;
