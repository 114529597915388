import React, { useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Back from "../../components/Back";
import { PRODUCT_ORDER_DETAIL } from "../../query/Orders";
import Loader from "../../components/Loader";
import { auth, storage } from "firebase";
import WaitingApprovalStatus from "../../components/WaitingApprovalStatus";
import ApprovedStatus from "../../components/ApprovedStatus";
import { FormatTime, CommaFormatted } from "../../utilities";
import PaymentPending from "../../components/PaymentPending";
import PaymentPaid from "../../components/PaymentPaid";
import Button from "../../components/Button";
import ImageUploader from "../../components/ImageUploader";
import { toast } from "react-toastify";
import {
  UPDATE_SLIP,
  UPDATE_PRODUCT_ORDER_SLIP,
  CANCEL_PRODUCT_ORDER,
} from "../../mutations/Orders";
import TrackingInfo from "../../components/TrackingInfo";
import { LanguageStore } from "../../context/LanguageStore";
import ThemeBG from "../../components/Images/ThemeBG.png";
import CancelButton from "../../components/CancelButton";
import CancelledStatus from "../../components/CancelledStatus";
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 15vh;
  overflow-y: auto;
  background-image: url(${ThemeBG});
  background-size: cover;
`;
const BackWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 470px;
  left: 50%;
  padding: 12px 20px;
  background-color: rgba(256, 256, 256, 0.6);

  transform: translate(-50%, 0%);
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px;
  width: 50%;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
  padding-top: 10px;
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
`;

const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;
const Container = styled.div`
  margin-bottom: 8px;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const DetailsWrapper = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CancelDescription = styled.p`
  font-size: 14px;
`;
const Slip = styled.img`
  object-fit: contain;
`;
export default ({ history, location }) => {
  let orderId = location.search.split("=")[1];
  let order = {};
  const { dictionary } = useContext(LanguageStore);
  const [updateSlip] = useMutation(UPDATE_PRODUCT_ORDER_SLIP);
  const [cancelOrder] = useMutation(CANCEL_PRODUCT_ORDER);
  const { data, loading, refetch, error } = useQuery(PRODUCT_ORDER_DETAIL, {
    variables: {
      order_id: orderId,
      uid: auth().currentUser.uid,
    },
  });
  if (data) {
    order = data.product_order[0];
  }
  const checkStatus = () => {
    switch (data.product_order[0].status) {
      case "Pending":
        return <WaitingApprovalStatus />;
      case "Approved":
        return <ApprovedStatus />;
      default:
        return <CancelledStatus />;
    }
  };

  const checkPayment = () => {
    switch (data.product_order[0].payment_slip) {
      case null:
        return <PaymentPending />;
      default:
        return <PaymentPaid />;
    }
  };

  console.log(data);
  const submitSlip = (e) => {
    toast.warning(dictionary.uploadingSlip);
    let imgRef = storage().ref(
      "payment_slip/" +
        auth().currentUser.uid +
        "/product_order/" +
        order.id +
        "/slip.png"
    );
    let storingImg = imgRef.put(e.target.files[0]);
    storingImg.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      () => {
        storingImg.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          updateSlip({
            variables: {
              id: order.id,
              url: downloadUrl,
            },
          })
            .then(() => {
              toast.dismiss();
              refetch();
              toast.success(dictionary.slipUpdated);
            })
            .catch((e) => {
              toast.dismiss();
              toast.error(e.message);
            });
        });
      }
    );
  };

  return (
    <Wrapper>
      <BackWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </BackWrapper>
      {loading && data === undefined && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {data &&
        (data.product_order.length > 0 ? (
          <PageWrapper>
            <Container>
              <StatusContainer>{data && checkStatus()}</StatusContainer>
            </Container>
            <Container>
              <Header>
                <Time>
                  {dictionary.orderID}: {order.id}
                </Time>
                <Time>{data && FormatTime(order.created_at)}</Time>
              </Header>
              {order.products ? (
                <Row>
                  <img
                    src={order.products.product_img}
                    height={60}
                    width={60}
                    style={{
                      borderRadius: 24,
                      marginRight: 8,
                      marginTop: 12,
                      marginBottom: 12,
                    }}
                  />
                  <DetailsWrapper>
                    <Name>{data && order.products.name}</Name>
                    <Quantity>
                      <Price>x {data && order.quantity}</Price>
                    </Quantity>
                  </DetailsWrapper>
                </Row>
              ) : (
                JSON.parse(order.multiproduct).map((pro) => {
                  return (
                    <Row>
                      <img
                        src={pro.img}
                        height={60}
                        width={60}
                        style={{
                          borderRadius: 24,
                          marginRight: 8,
                          marginTop: 12,
                          marginBottom: 12,
                        }}
                      />
                      <DetailsWrapper>
                        <Name>{pro.name}</Name>
                        <Quantity>
                          <Price>x {pro.quantity}</Price>
                        </Quantity>
                      </DetailsWrapper>
                    </Row>
                  );
                })
              )}
            </Container>
            <Container>
              <Header>
                <Title>{dictionary.deliveryInformation}</Title>
              </Header>
              <DetailsWrapper>
                <Row>
                  <Left>
                    <Name>{order.product_order_shipping.name}</Name>
                    <Quantity>
                      <Price>{order.address}</Price>
                    </Quantity>
                    <Quantity>
                      {dictionary.city}: <Price>{order.city}</Price>
                    </Quantity>
                    <Quantity>
                      {dictionary.state}: <Price>{order.state}</Price>
                    </Quantity>
                    <Quantity>
                      {dictionary.postcode}: <Price>{order.postcode}</Price>
                    </Quantity>
                  </Left>
                  <Right>
                    <Name>
                      {
                        order.product_order_shipping.shipping_rate_country
                          .currency
                      }{" "}
                      {order.delivery_rate}
                    </Name>
                  </Right>
                </Row>
              </DetailsWrapper>
            </Container>
            {!loading && order.delivery_rate > 0 && (
              <Container>
                <Header>
                  <Title>{dictionary.payment}</Title>
                  {checkPayment()}
                </Header>
                <DetailsWrapper>
                  <Row>
                    <Left>
                      <Name>{data.master[0].bank_acc_name}</Name>
                      <Quantity>
                        {dictionary.bankAccName}:{" "}
                        <Price>{data.master[0].bank_acc_no}</Price>
                      </Quantity>
                      <Quantity>
                        {dictionary.bankName}:{" "}
                        <Price>{data.master[0].bank_name}</Price>
                      </Quantity>
                    </Left>
                    {order.payment_slip ? (
                      <Right>
                        <Slip src={order.payment_slip} height={60} width={60} />
                      </Right>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <ImageUploader
                    placeholder={
                      order.payment_slip
                        ? dictionary.resubmitSlip
                        : dictionary.uploadSlip
                    }
                    onChange={submitSlip}
                  />
                </DetailsWrapper>
              </Container>
            )}
            <TrackingInfo
              shipped_at={data && order.shipped_at}
              shipped={data && order.shipped}
              tracking_id={data && order.tracking_id}
              courier={data && order.courier}
              tracking_link={data && order.tracking_link}
            />
            {data.product_order[0].status === "Pending" && (
              <Container>
                <Header>
                  <Title>{dictionary.cancelOrder}</Title>
                </Header>
                <DetailsWrapper>
                  <CancelDescription>
                    {dictionary.cancelOrderDescription}
                  </CancelDescription>
                </DetailsWrapper>
                <CancelButton
                  label={dictionary.cancelOrder}
                  onClick={() => {
                    toast.warning("Cancelling order...", { autoClose: false });
                    cancelOrder({
                      variables: {
                        id: order.id,
                      },
                    })
                      .then(() => {
                        refetch().then(() => {
                          toast.dismiss();
                          toast.success("Order cancelled!");
                        });
                      })
                      .catch((e) => {
                        toast.error(e.message);
                      });
                  }}
                />
              </Container>
            )}
          </PageWrapper>
        ) : (
          <LoadingWrapper>
            <Quantity>Can't find anything here</Quantity>
          </LoadingWrapper>
        ))}
    </Wrapper>
  );
};
