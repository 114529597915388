import React, { useState, useContext } from "react";
import styled from "styled-components";
import CloseButton from "./CloseButton";
import BlueHead from "./Images/BlueHead.png";
import { LanguageStore } from "../context/LanguageStore";
import { checkFullMonth } from "../utilities";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER_PERFORMANCE } from "../query/Users";
import Loader from "./Loader";

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px 20px;
  z-index: 80;
`;

const Container = styled.div`
  background-color: white;
  width: 100%;
  max-width: 430px;
  border-radius: 16px;
  height: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  padding-bottom: 6px;
  border-bottom: solid 1px ${(props) => props.theme.lightGreyColor};
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  ${(props) => props.theme.title};
  font-size: 18px;
`;

const SubTitle = styled.div`
  ${(props) => props.theme.title};
  font-size: 16px;
  margin: 8px 0px;
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding: 16px;
  padding-top: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const AvatarWrapper = styled.div`
  margin-right: 16px;
`;

const Avatar = styled.img`
  width: 60px;
  border-radius: 24px;
  height: 60px;
`;

const Info = styled.p`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const ContactInfo = styled.div`
  padding-left: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0px;
`;

const BonusHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  box-shadow: 0 4px 12px -8px rgba(0, 0, 0, 0.3);
`;

const MonthSelector = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 80px;
  justify-content: center;
  align-items: center;
`;

const Clickable = styled.div`
  cursor: pointer;
  padding: 0px 12px;
  color: ${(props) => props.theme.darkBlueColor};
  :hover {
    opacity: 0.7;
  }
`;

const MonthYear = styled.p`
  text-align: center;
  min-width: 160px;
`;

const PerformanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  overflow-y: auto;
`;

const StatsContainer = styled.div`
  width: 100%;
  padding: 12px;
  :first-child {
    border-right: solid 1px ${(props) => props.theme.darkGreyColor};
  }
`;

const Stats = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const ThinWord = styled.span`
  font-weight: 400;
`;
export default ({ user, close }) => {
  const { dictionary } = useContext(LanguageStore);
  let now = new Date();

  const [checkDate, setCheckDate] = useState({
    month: now.getMonth(),
    year: now.getFullYear(),
  });

  const { data, loading, error } = useQuery(GET_USER_PERFORMANCE, {
    variables: {
      id: user.id,
      month: checkDate.month + 1,
      year: checkDate.year,
    },
  });
  const monthBack = () => {
    if (checkDate.month === 0) {
      setCheckDate({
        month: 11,
        year: checkDate.year - 1,
      });
    } else {
      setCheckDate({
        month: checkDate.month - 1,
        year: checkDate.year,
      });
    }
  };

  const monthNext = () => {
    if (checkDate.month === 11) {
      setCheckDate({
        month: 0,
        year: checkDate.year + 1,
      });
    } else {
      setCheckDate({
        month: checkDate.month + 1,
        year: checkDate.year,
      });
    }
  };

  console.log(data, error);
  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>
            {user.name} (
            {user.current_rank_name || user.joining_rank.chinese_name})
          </Title>
          <CloseButton onClick={close} />
        </Header>
        <InfoWrapper>
          <Row>
            <AvatarWrapper>
              <Avatar src={user.avatar || BlueHead} />
            </AvatarWrapper>
            <ContactInfo>
              <Info>
                Username: <Bold>{user.username}</Bold>
              </Info>
              <Info>
                Phone: <Bold>{user.phone}</Bold>
              </Info>
              <Info>
                E-mail: <Bold>{user.email}</Bold>
              </Info>
            </ContactInfo>
          </Row>
        </InfoWrapper>
        <BonusHeader>
          <Title>{dictionary.performance}</Title>
          <MonthSelector>
            <Clickable onClick={monthBack}>
              <AiOutlineCaretLeft size={16} />
            </Clickable>
            <MonthYear>
              <Bold>
                {checkFullMonth(checkDate.month)} {checkDate.year}
              </Bold>
            </MonthYear>
            <Clickable onClick={monthNext}>
              <AiOutlineCaretRight
                size={16}
                display={
                  checkDate.month >= now.getMonth() &&
                  checkDate.year >= now.getFullYear()
                    ? "none"
                    : "block"
                }
              />
            </Clickable>
          </MonthSelector>
        </BonusHeader>
        {loading ? (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        ) : (
          <PerformanceWrapper>
            <SubTitle>{dictionary.monthlyPSR}</SubTitle>
            <Row>
              <StatsContainer>
                <Stats>
                  <Bold>{dictionary.groupSales}</Bold>
                  <br />
                  {data &&
                    data.mds_bonus_v3_net &&
                    (data.mds_bonus_v3_net.length > 0
                      ? data.mds_bonus_v3_net[0].quantity
                      : 0)}
                  <br />
                  {dictionary.boxes}
                </Stats>
              </StatsContainer>
              <StatsContainer>
                <Stats>
                  <Bold>{dictionary.downlineBonus}</Bold>
                  <br />
                  {data &&
                    data.mds_bonus_v3_net &&
                    (data.mds_bonus_v3_net.length > 0
                      ? data.mds_bonus_v3_net[0].downline_bonus
                      : 0)}
                  <br />
                  PV
                </Stats>
              </StatsContainer>
            </Row>
            <Info>
              Bonus:{" "}
              {data &&
                data.mds_bonus_v3_net &&
                (data.mds_bonus_v3_net.length > 0
                  ? data.mds_bonus_v3_net[0].personal_bonus
                  : 0)}
              PV
            </Info>
            <SubTitle>{dictionary.breakAwayBonus}</SubTitle>
            <Row>
              <StatsContainer>
                <Stats>
                  <Bold>{dictionary.breakAwayLine}</Bold>
                  <br />
                  {data &&
                    data.break_away_v2_qualified_users &&
                    (data.break_away_v2_qualified_users.length > 0
                      ? data.break_away_v2_qualified_users[0].block
                      : 0)}
                  <br />(
                  {data &&
                    data.break_away_v2_qualified_users &&
                    (data.break_away_v2_qualified_users.length > 0
                      ? data.break_away_v2_qualified_users[0].group_sales
                      : 0)}
                  boxes)
                </Stats>
              </StatsContainer>
              <StatsContainer>
                <Stats>
                  <Bold>{dictionary.personalGroupSales}</Bold>
                  <br />
                  {data &&
                    data.break_away_v2_qualified_users &&
                    (data.break_away_v2_qualified_users.length > 0
                      ? data.break_away_v2_qualified_users[0].personal_sales
                      : 0)}
                  <br />
                  {dictionary.boxes}
                </Stats>
              </StatsContainer>
            </Row>
            <Info>
              {dictionary.personalBonus} (
              {data &&
                data.break_away_v2_passup_record_aggregate &&
                (data.break_away_v2_passup_record_aggregate.aggregate
                  ? data.break_away_v2_passup_record_aggregate.aggregate.sum
                      .quantity
                  : 0)}
              boxes):{" "}
              {data &&
                data.break_away_v2_passup_record_aggregate &&
                (data.break_away_v2_passup_record_aggregate.aggregate.sum.bonus
                  ? data.break_away_v2_passup_record_aggregate.aggregate.sum
                      .bonus
                  : 0)}
              PV
            </Info>
            <Info>
              {dictionary.passupBonus}:{" "}
              {data &&
                data.break_away_v2_deduct_bonus_aggregate &&
                data.break_away_v2_deduct_bonus_aggregate.aggregate.sum &&
                (data.break_away_v2_deduct_bonus_aggregate.aggregate.sum.bonus
                  ? data.break_away_v2_deduct_bonus_aggregate.aggregate.sum
                      .bonus
                  : 0)}
              PV
            </Info>
            <SubTitle>{dictionary.overrideBonus}</SubTitle>
            <Info>
              Bonus:{" "}
              {data &&
                data.cross_level_bonus_aggregate &&
                data.cross_level_bonus_aggregate.aggregate.sum &&
                (data.cross_level_bonus_aggregate.aggregate.sum.bonus
                  ? data.cross_level_bonus_aggregate.aggregate.sum.bonus
                  : 0)}
              PV
            </Info>
          </PerformanceWrapper>
        )}
      </Container>
    </Wrapper>
  );
};
