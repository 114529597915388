import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import AnimatedContainer from "../AnimatedContainer";
import { LanguageStore } from "../../context/LanguageStore";
import Button from "../Button";

const Wrapper = styled.div`
  padding: 16px;
`;

const ErrorMessage = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0px;
  color: ${(props) => props.theme.errorRedColor};
`;
const Message = styled.h1`
  font-size: 16px;
  font-weight: 600;
  font-color: ${(props) => props.theme.lightBlackColor};
  margin: 12px 0px;
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
`;
export default withTheme(({ slip, approveOrder, close, theme }) => {
  const { dictionary } = useContext(LanguageStore);

  return (
    <AnimatedContainer
      close={close}
      title="Approve Order"
      content={
        <Wrapper>
          {slip ? (
            <Message>{dictionary.checkPaymentFirst}</Message>
          ) : (
            <ErrorMessage>{dictionary.noPaymentYet}</ErrorMessage>
          )}
          <ButtonWrapper>
            <Button label="Approve Order" onClick={approveOrder} />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              label="Back"
              onClick={close}
              backgroundColor={theme.darkGreyColor}
            />
          </ButtonWrapper>
        </Wrapper>
      }
    />
  );
});
