import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageStore } from "../context/LanguageStore";

const Approved = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.mainColor};
  margin-right: 8px;
`;
const Pending = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.orangeColor};
  margin-right: 8px;
`;
const Cancelled = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.errorRedColor};
  margin-right: 8px;
`;

export default ({ status }) => {
  const { dictionary } = useContext(LanguageStore);

  switch (status) {
    case "Approved":
      return <Approved>{dictionary.approved}</Approved>;
    case "Pending":
      return <Pending>{dictionary.pending}</Pending>;
    default:
      return <Cancelled>{dictionary.cancelled}</Cancelled>;
  }
};
