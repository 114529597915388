import React, { useContext } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import { auth } from "firebase";
import ProfileStore from "../../context/ProfileContext";
import { LanguageStore } from "../../context/LanguageStore";
import SetUp from "../../components/Images/SetUp.JPG";
import AuthOutLanguage from "../../components/AuthOutLanguage";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 28px;
  padding-bottom: 12px;
  background-color: white;
  justify-content: space-between;
  max-width: 470px;
  overflow: hidden;
  background: url(${SetUp});
  background-size: cover;
  background-position: top;
`;

const ButtonWrapper = styled.div`
  padding: 20px 0px;
  width: 100%;
  max-width: 160px;
`;

const BigTitle = styled.h1`
  ${(props) => props.theme.title};
  font-weight: 400;
  color: ${(props) => props.theme.darkBlueColor};
  font-size: 38px;
`;

const SmallTitle = styled.p`
  ${(props) => props.theme.title};
  font-weight: 400;
  color: ${(props) => props.theme.darkBlueColor};
  font-size: 20px;
  max-width: 250px;
  margin-top: 8px;
  line-height: 26px;
`;

const Content = styled.div`
  padding: 20px 0px;
`;
const SignOutLink = styled.p`
  width: 100%;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 16px;
  color: ${(props) => props.theme.lightBlackColor};
  :hover {
    color: ${(props) => props.theme.mainColor};
  }
`;

const DownWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default ({ setAction }) => {
  const { setIsLoggedIn } = useContext(ProfileStore);
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper>
      <Content>
        {/* <img
          style={{ marginLeft: -15, marginBottom: 8 }}
          src={logo}
          width={120}
          height="auto"
        /> */}
        {/* <BigTitle>{dictionary.readyToJoin}</BigTitle>
        <SmallTitle>{dictionary.setupAcc}</SmallTitle> */}
      </Content>
      <DownWrapper>
        <ButtonWrapper>
          <Button
            label={dictionary.letsGo}
            onClick={() => {
              setAction(1);
            }}
          />
          <SignOutLink
            onClick={() => {
              auth()
                .signOut()
                .then(() => {
                  localStorage.clear();
                  setIsLoggedIn(false);
                });
            }}
          >
            {dictionary.backToSignIn}
          </SignOutLink>
        </ButtonWrapper>
        <AuthOutLanguage />
      </DownWrapper>
    </Wrapper>
  );
};
