import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import { auth, storage } from "firebase";
import ProductForm from "../components/ProductForm";
import Loader from "../components/Loader";
import { INSERT_RB_ORDER } from "../mutations/Orders";
import { toast } from "react-toastify";
import Input from "../components/Input";
import { BsQuestionCircle } from "react-icons/bs";
import Button from "../components/Button";
import Help from "../components/Help";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { GET_RB_ORDER_INFO } from "../query/Orders";
import { CommaFormatted } from "../utilities";
import { LanguageStore } from "../context/LanguageStore";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  min-height: 100vh;
  padding-bottom: 230px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notification = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 80;
  cursor: pointer;
  max-width: 470px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.mainColor};
  color: white;
  padding: 6px 16px;
  position: sticky;
  :hover {
    background-color: ${(props) => props.theme.mainShade};
  }
  top: 70px;
`;

const BalanceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0px;
`;

const Name = styled.h1`
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.darkBlueColor};
`;

const SmallText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.lightBlackColor};
`;
const ErrorNotification = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 80;
  cursor: pointer;
  border-radius: 12px;
  max-width: 470px;
  background-color: ${(props) => props.theme.errorRedColor};
  color: white;
  padding: 6px 16px;
  position: sticky;
  top: 70px;
`;
const NotiText = styled.p`
  color: white;
  font-weight: 600;
  font-size: 14px;
`;

const Summary = styled.div`
  padding: 12px 0px;
`;

const PageWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
`;
const Error = styled.p`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  width: 100%;
`;

const Total = styled.h1`
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0px;
`;

const Price = styled.span`
  font-weight: 600;
  font-color: ${(props) => props.theme.darkBlueColor};
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;

const SectionBackground = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.lightGreyColor};
  height: 100%;
`;

const Section = styled.div`
  background-color: white;
  padding: 12px 20px;
  margin-bottom: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RBBalanceWrapper = styled.div`
  overflow: hidden;
  background-color: ${(props) => props.theme.lightGreyColor};
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 8px;
  width: 100%;
  max-width: 470px;
  height: ${(props) => props.height};
  transition: 0.4s;
  margin-top: 8px;
  border-radius: 12px;
  z-index: 50;
`;
const RBBalanceRow = styled.div`
  padding: 8px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  cursor: pointer;
  border-color: ${(props) => props.theme.darkGreyColor};
  :last-child {
    border-bottom-width: 0px;
  }
  :hover {
    opacity: 0.8;
  }
`;
export default ({ history }) => {
  let now = new Date();
  const { dictionary } = useContext(LanguageStore);
  const [quantity, setQuantity] = useState();
  const [showRb, setShowRb] = useState(false);
  const [product, setProduct] = useState();
  const [address, setAddress] = useState("default");
  const [recipient, setRecipient] = useState("");
  const [phone, setPhone] = useState("");
  const [voucher, setVoucher] = useState(null);
  const [secret, setSecret] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [addressSet, setAddressSet] = useState({});
  const [shipping, setShipping] = useState();
  const [shippingSet, setShippingSet] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [change, setChange] = useState(false);
  const [newProductOrder] = useMutation(INSERT_RB_ORDER);
  const { data, loading, refetch } = useQuery(GET_RB_ORDER_INFO, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });

  const submitOrder = (e) => {
    e.preventDefault();
    if (secret !== data.users_by_pk.secret_key) {
      return toast.error(dictionary.secondPasswordIncorrec);
    }
    if (
      selectedProducts.reduce((a, b) => {
        return a + b.quantity;
      }, 0) > voucher.quantity ||
      voucher.quantity <= 0
    ) {
      return toast.error("RB Voucher insufficient!");
    }
    toast.warning(dictionary.submittingOrder);
    let productsString = JSON.stringify(selectedProducts);
    newProductOrder({
      variables: {
        user_id: auth().currentUser.uid,
        quantity: selectedProducts.reduce((a, b) => {
          return a + b.quantity;
        }, 0),
        address: addressSet.line,
        state: addressSet.state,
        city: addressSet.city,
        postcode: addressSet.postcode,
        recipient_name: recipient,
        contact_no: phone,
        shipping: shipping,
        replenish_price: voucher.unit_price * 0.7,
        shipping_rate:
          shippingSet.rate *
          selectedProducts.reduce((a, b) => {
            return a + b.quantity;
          }, 0),
        package: voucher.package_id,
        multiproduct: productsString,
      },
    }).then(() => {
      toast.dismiss();
      toast.success(dictionary.orderPlaced);
      refetch();
      history.goBack();
    });
  };

  const addProductToList = (pp) => {
    let products = selectedProducts;
    let productIndex = products.findIndex((a) => a.id === pp.id);
    if (productIndex !== -1) {
      products.splice(productIndex, 1);
    } else {
      products.push({
        id: pp.id,
        name: pp.name,
        img: pp.product_img,
        quantity: 1,
      });
    }
    setSelectedProducts(products);
    setChange(!change);
  };

  const changeProductQuantity = (id, quantity) => {
    if (parseInt(quantity) <= 0) {
      let productList = selectedProducts;
      productList.splice(
        productList.findIndex((pro) => pro.id === id),
        1
      );

      setSelectedProducts(productList);
      return setChange(!change);
    }
    setSelectedProducts(
      selectedProducts.map((pro) =>
        pro.id === id ? { ...pro, quantity: parseInt(quantity) } : pro
      )
    );
  };
  return (
    <Wrapper>
      <PageTitle
        title={
          <p>
            <span
              onClick={() => {
                setShowHelp(true);
              }}
              style={{
                marginRight: 6,
                cursor: "pointer",
              }}
            >
              <BsQuestionCircle size={14} />
            </span>
            {dictionary.redeemV}
          </p>
        }
      />
      {!loading &&
        data &&
        (data.check_rb_balance.length > 0 ? (
          <Notification
            onClick={() => {
              setShowRb(!showRb);
            }}
          >
            <NotiText>
              {voucher === null
                ? dictionary.selectAVoucher
                : voucher.package_name +
                  " (" +
                  voucher.package_chinese_name +
                  ")"}
            </NotiText>
            {showRb ? (
              <MdKeyboardArrowUp size={18} />
            ) : (
              <MdKeyboardArrowDown size={18} />
            )}
          </Notification>
        ) : (
          <ErrorNotification>
            <NotiText>{dictionary.noRbVoucher}</NotiText>
          </ErrorNotification>
        ))}
      <RBBalanceWrapper height={showRb ? "auto" : "0"}>
        {!loading &&
          data &&
          data.check_rb_balance.map.length > 0 &&
          data.check_rb_balance.map((bal, index) => {
            return (
              <RBBalanceRow
                onClick={() => {
                  setShowRb(false);
                  setVoucher(bal);
                }}
              >
                <BalanceHeader>
                  <SmallText>
                    {dictionary.value}: {(bal.unit_price * 0.3).toFixed(2)}
                  </SmallText>
                  <SmallText>
                    {dictionary.replenishPrice}:{" "}
                    {!loading &&
                      data &&
                      data.users_by_pk.user_country.currency +
                        " " +
                        (bal.unit_price * 0.7).toFixed(2)}
                  </SmallText>
                </BalanceHeader>
                <BalanceHeader>
                  <Name>
                    {bal.package_name + " (" + bal.package_chinese_name + ")"}
                  </Name>
                  <Name>x {bal.quantity}</Name>
                </BalanceHeader>
              </RBBalanceRow>
            );
          })}
      </RBBalanceWrapper>
      <PageWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
        {loading && (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        )}
        <SectionBackground>
          {!loading && data && data.check_rb_balance.length > 0 && (
            <Section>
              <ProductForm
                limit={false}
                changeProductQuantity={changeProductQuantity}
                addProductToList={addProductToList}
                addressSet={addressSet}
                setAddressSet={setAddressSet}
                quantity={quantity}
                setQuantity={setQuantity}
                selectedProduct={selectedProducts}
                setProduct={setSelectedProducts}
                address={address}
                setAddress={setAddress}
                phone={phone}
                setPhone={setPhone}
                recipient={recipient}
                setRecipient={setRecipient}
                shipping={shipping}
                setShipping={setShipping}
                shippingSet={shippingSet}
                setShippingSet={setShippingSet}
              />
            </Section>
          )}
          {!loading &&
            data.check_rb_balance.length > 0 &&
            voucher &&
            selectedProducts.length > 0 &&
            address !== "default" &&
            recipient !== "" &&
            phone !== "" &&
            selectedProducts.reduce((a, b) => {
              return a + b.quantity;
            }, 0) > 0 &&
            !isNaN(shippingSet.rate) && (
              <Section>
                <Summary>
                  <Title>{dictionary.summary}</Title>
                  {voucher && quantity > voucher.quantity ? (
                    <Error>{dictionary.notEnoughVoucher}</Error>
                  ) : (
                    <>
                      <Total>
                        {dictionary.total}:{" "}
                        {"(" +
                          data.users_by_pk.user_country.currency +
                          " " +
                          (voucher.unit_price * 0.7).toFixed(2) +
                          " x " +
                          selectedProducts.reduce((a, b) => {
                            return a + b.quantity;
                          }, 0) +
                          " + " +
                          (
                            shippingSet.rate *
                            selectedProducts.reduce((a, b) => {
                              return a + b.quantity;
                            }, 0)
                          ).toFixed(2) +
                          ")" +
                          " = "}
                        <Price>
                          {data.users_by_pk.user_country.currency}
                          {CommaFormatted(
                            selectedProducts.reduce((a, b) => {
                              return a + b.quantity;
                            }, 0) *
                              voucher.unit_price *
                              0.7 +
                              shippingSet.rate *
                                selectedProducts.reduce((a, b) => {
                                  return a + b.quantity;
                                }, 0)
                          )}
                        </Price>{" "}
                        <InputWrapper>
                          <Input
                            label={dictionary.secondPassword}
                            type="password"
                            placeholder="******"
                            value={secret}
                            onChange={(e) => {
                              setSecret(e.target.value);
                            }}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Button
                            label={dictionary.submitOrder}
                            onClick={submitOrder}
                          />
                        </InputWrapper>
                      </Total>{" "}
                    </>
                  )}
                </Summary>
              </Section>
            )}
        </SectionBackground>
      </PageWrapper>
      {showHelp && (
        <Help
          title="RB Vouchers"
          close={() => {
            setShowHelp(false);
          }}
          content={
            <Summary>
              <Total>
                {dictionary.RBDesc2}: <br />
                <br />
                {dictionary.RBDesc3}
                <br />
                <br />
                {dictionary.RBDesc4}
              </Total>
            </Summary>
          }
        />
      )}
    </Wrapper>
  );
};
