import React, { useContext } from "react";
import styled from "styled-components";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { LanguageStore } from "../../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 12px;
  background-color: white;
  border-radius: 12px;
  width: 100%;
`;

const Description = styled.p`
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0px;
  padding: 0px 12px;
  padding-bottom: 8px;
`;

const ErrorMessage = styled.div`
  width: 100%;
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  border-style: solid 2px ${(props) => props.theme.errorDarkRedColor};
  background-color: ${(props) => props.theme.errorRedColor};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 470px;
  bottom: 0;
  left: 50%;
  padding: 20px;
  padding-bottom: 30px;
  transform: translate(-50%, 0%);
  z-index: 8;
`;

const Back = styled.p`
  width: 100%;
  text-decoration: underline;
  text-align: center;
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  font-size: 14px;
  margin-top: 8px;
  :hover {
    color: ${(props) => props.theme.mainShade};
  }
`;
const AddressInfo = ({
  setAction,
  lineOne,
  setLineOne,
  city,
  setCity,
  aState,
  setAState,
  postcode,
  setPostcode,
}) => {
  const { dictionary } = useContext(LanguageStore);
  const submitForm = () => {
    if (lineOne === "") {
      return toast.error("Please enter address line 1!", { autoClose: 1500 });
    }
    if (city === "") {
      return toast.error("Please enter city!", { autoClose: 1500 });
    }
    if (aState === "") {
      return toast.error("Please enter state!", { autoClose: 1500 });
    }
    if (postcode === "") {
      return toast.error("Please enter postcode!", { autoClose: 1500 });
    }
    setAction(4);
  };
  return (
    <Wrapper>
      <InputWrapper>
        <Input
          label={dictionary.deliveryAddress}
          value={lineOne}
          onChange={(e) => {
            setLineOne(e.target.value);
          }}
          placeholder="Address (1st line)"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={dictionary.city}
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          placeholder="City"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={dictionary.state}
          value={aState}
          onChange={(e) => {
            setAState(e.target.value);
          }}
          placeholder="State"
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={dictionary.postcode}
          value={postcode}
          onChange={(e) => {
            setPostcode(e.target.value);
          }}
          placeholder="Postcode"
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button label={dictionary.next} onClick={submitForm} />
        <Back
          onClick={() => {
            setAction(2);
          }}
        >
          {dictionary.backToContact}
        </Back>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default AddressInfo;
