import gql from "graphql-tag";

export const BIND_ACCOUNT = gql`
  mutation bindAccount($upline: String!, $downline: String!) {
    insert_relation(objects: { downline: $downline, upline: $upline }) {
      returning {
        upline
      }
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation updateAvatar($id: String!, $url: String!) {
    update_users(_set: { avatar: $url }, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_HALF_TICKET = gql`
  mutation insertHalf($id: String!, $pid: Int!) {
    insert_half_ticket_one(object: { package: $pid, user_id: $id }) {
      id
    }
  }
`;
export const UPDATE_BANK_INFO = gql`
  mutation updateBank(
    $id: Int
    $bank_name: String
    $bank_acc_no: String
    $bank_acc_name: String
  ) {
    update_user_bank(
      _set: {
        bank_acc_name: $bank_acc_name
        bank_acc_no: $bank_acc_no
        bank_name: $bank_name
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const WITHDRAW_CASH = gql`
  mutation withdrawCash($amount: float8!, $uid: String!) {
    insert_cash_balance(
      objects: {
        add: false
        amount: $amount
        description: "Cash withdrawal"
        user_id: $uid
        type: "withdrawal"
      }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $id: Int!
    $city: String
    $country: Int
    $address: String
    $name: String
    $postcode: String
    $state: String
  ) {
    update_address(
      where: { id: { _eq: $id } }
      _set: {
        city: $city
        country: $country
        line: $address
        name: $name
        postcode: $postcode
        state: $state
      }
    ) {
      affected_rows
    }
  }
`;
export const INSERT_ADDRESS = gql`
  mutation newAddress(
    $city: String
    $country: Int
    $line: String
    $name: String
    $postcode: String
    $state: String
    $uid: String
  ) {
    insert_address(
      objects: {
        city: $city
        country: $country
        line: $line
        name: $name
        postcode: $postcode
        state: $state
        user_id: $uid
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const UPDATE_PERSONAL_INFO = gql`
  mutation updatePersonalInfo(
    $id: String!
    $name: String!
    $wechat: String
    $whatsapp: String
    $phone: String
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        phone: $phone
        wechat_id: $wechat
        whatsapp: $whatsapp
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_USER = gql`
  mutation newUser(
    $id: String!
    $avatar: String
    $country: Int!
    $email: String!
    $ic_no: String!
    $name: String!
    $phone: String!
    $secret_key: String!
    $upline: String!
    $username: String!
    $cost_price: Int!
    $package_id: Int!
    $quantity: Int!
    $selling_price: Int!
    $city: String!
    $line: String!
    $postcode: String!
    $state: String!
    $bankName: String!
    $bankAccName: String!
    $bankAccNo: String!
    $bigOfferCode: String
    $system_offer: Int
    $ticketCode: uuid
  ) {
    insert_users(
      objects: {
        avatar: $avatar
        country: $country
        email: $email
        ic_no: $ic_no
        id: $id
        name: $name
        phone: $phone
        secret_key: $secret_key
        upline: $upline
        username: $username
        joining_package: $package_id
      }
    ) {
      returning {
        id
      }
    }
    insert_address(
      objects: {
        city: $city
        country: $country
        line: $line
        name: "My Address"
        postcode: $postcode
        state: $state
        user_id: $id
      }
    ) {
      returning {
        id
      }
    }
    insert_package_order(
      objects: {
        buyer_id: $id
        cost_price: $cost_price
        country: $country
        package_id: $package_id
        quantity: $quantity
        seller_id: $upline
        selling_price: $selling_price
        system_offer: $system_offer
      }
    ) {
      returning {
        id
      }
    }
    insert_user_bank_one(
      object: {
        user_id: $id
        bank_name: $bankName
        bank_acc_name: $bankAccName
        bank_acc_no: $bankAccNo
      }
    ) {
      id
    }
    update_big_offer_tickets(
      where: { code: { _ilike: $bigOfferCode } }
      _set: { claimed_by: $id }
    ) {
      returning {
        id
      }
    }
    update_half_ticket(
      where: { code: { _eq: $ticketCode } }
      _set: { claimed_by: $id }
    ) {
      returning {
        id
      }
    }
  }
`;
