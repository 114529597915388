import React, { useContext } from "react";
import styled from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { LanguageStore } from "../context/LanguageStore";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.orangeColor};
  min-width: 50px;
`;

const Icon = styled.div`
  margin-right: 8px;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 600;
`;

export default () => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Container>
      <Text>{dictionary.pending}</Text>
    </Container>
  );
};
