import React, { useState, useContext } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import Input from "./Input";
import Button from "./Button";
import { LanguageStore } from "../context/LanguageStore";
import { firebaseConfig } from "../utilities";
import firebase, { auth } from "firebase";
import { toast } from "react-toastify";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
const Wrapper = styled.form`
  width: 100%;
  padding: 12px;
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;

const UPDATE_PASSWORD = gql`
  mutation changePassword($id: String!, $newPassword: String!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { secret_key: $newPassword }
    ) {
      id
    }
  }
`;
export default ({ close }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { dictionary } = useContext(LanguageStore);
  const [loading, setLoading] = useState(false);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const resetPassword = (e) => {
    e.preventDefault();
    if (newPassword === "") {
      return toast.error("Please enter a new password");
    }
    toast.warning("Authenticating...", { autoClose: false });
    setLoading(true);
    let checkAuthentication = firebase.initializeApp(
      firebaseConfig,
      "Check Password"
    );

    checkAuthentication
      .auth()
      .signInWithEmailAndPassword(auth().currentUser.email, oldPassword)
      .then((user) => {
        toast.dismiss();
        toast.warning("Resetting password...");
        updatePassword({
          variables: {
            newPassword,
            id: auth().currentUser.uid,
          },
        }).then(() => {
          toast.dismiss();
          toast.success("Second password updated!");
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        });
      })
      .catch((e) => {
        toast.dismiss();
        setLoading(false);
        console.log(e);
        if (e.code === "auth/wrong-password") {
          toast.error("Login Password Incorrect!");
        } else {
          toast.error(e.message);
        }
      })
      .finally(() => {
        checkAuthentication.delete();
      });
  };
  return (
    <AnimatedContainer
      loading={loading}
      title={"Change Second Password"}
      close={close}
      content={
        <Wrapper onSubmit={resetPassword}>
          <InputWrapper>
            <Input
              label="Login Password"
              value={oldPassword}
              placeholder="******"
              type="password"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label="New Second Password"
              value={newPassword}
              placeholder="******"
              type="password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Button label="Change Password" onClick={resetPassword} />
          </InputWrapper>
        </Wrapper>
      }
    />
  );
};
