import React, { useContext, useState } from "react";
import styled from "styled-components";
import PageTitle from "../../components/PageTitle";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Back from "../../components/Back";
import { MY_ORDER_DETAIL, DOWNLINE_ORDER_DETAIL } from "../../query/Orders";
import Loader from "../../components/Loader";
import { auth, storage } from "firebase";
import WaitingApprovalStatus from "../../components/WaitingApprovalStatus";
import ApprovedStatus from "../../components/ApprovedStatus";
import { FormatTime, CommaFormatted } from "../../utilities";
import PaymentPending from "../../components/PaymentPending";
import PaymentPaid from "../../components/PaymentPaid";
import Button from "../../components/Button";
import ImageUploader from "../../components/ImageUploader";
import { toast } from "react-toastify";
import { UPDATE_SLIP, APPROVE_ORDER } from "../../mutations/Orders";
import BlueHead from "../../components/Images/BlueHead.png";
import { LanguageStore } from "../../context/LanguageStore";
import CancelledStatus from "../../components/CancelledStatus";
import Warning from "../../components/Orders/Warning";
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 20vh;
`;
const BackWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 470px;
  left: 50%;
  padding: 12px 20px;
  background-color: rgba(256, 256, 256, 0.6);

  transform: translate(-50%, 0%);
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.lightGreyColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;

const Name = styled.h1`
  ${(props) => props.theme.title};
  font-size: 16px;
`;
const Title = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
`;

const Quantity = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 400;
`;

const Container = styled.div`
  background-color: white;
  margin-bottom: 8px;
  padding: 20px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px;
`;
const Price = styled.span`
  ${(props) => props.theme.title};
  font-size: 12px;
  font-weight: 600;
`;
const DetailsWrapper = styled.div`
  padding: 12px 8px;
`;

const Slip = styled.img`
  object-fit: contain;
`;

const ContactInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  ${(props) => props.theme.darkBlueColor};
`;

const UserInfoWrapper = styled.div`
  display: flex;
  padding-top: 12px;
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
`;

const CompanyApprove = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.orangeColor};
`;
export default ({ history, location }) => {
  let orderId = location.search.split("=")[1];
  let order = {};
  const { dictionary } = useContext(LanguageStore);
  const [approveOrder] = useMutation(APPROVE_ORDER);
  const [showWarning, setShowWarning] = useState(false);
  const { data, loading, refetch, error } = useQuery(DOWNLINE_ORDER_DETAIL, {
    variables: {
      id: orderId,
      uid: auth().currentUser.uid,
    },
  });
  if (data) {
    order = data.package_order[0];
  }
  let smth = true;
  const checkStatus = () => {
    switch (data.package_order[0].status) {
      case "Pending":
        return data.package_order[0].order_package.pay_company ? (
          <CompanyApprove>{dictionary.waitingCompanyApproval}</CompanyApprove>
        ) : (
          <Button
            label={dictionary.approveOrder}
            onClick={() => {
              setShowWarning(true);
            }}
          />
        );
      case "Approved":
        return <ApprovedStatus />;
      default:
        return <CancelledStatus />;
    }
  };

  const checkPayment = () => {
    switch (data.package_order[0].payment_slip) {
      case null:
        return <PaymentPending />;
      default:
        return <PaymentPaid />;
    }
  };

  const updateStatus = (e) => {
    e.preventDefault();
    if (data.users_by_pk.stock_balance < data.package_order[0].quantity) {
      return toast.error(dictionary.notEnoughStock);
    }
    toast.warning("Hold on..", { autoClose: false });
    let time = new Date();
    approveOrder({
      variables: {
        id: order.id,
        time: time.toISOString(),
        cost_price: data.users_by_pk.current_rank_price,
      },
    })
      .then(async () => {
        toast.dismiss();
        await refetch();
        setShowWarning(false);
        toast.success(dictionary.orderApproved);
      })
      .catch((e) => {
        toast.dismiss();
        toast.error(e.message);
      });
  };
  return (
    <Wrapper>
      <BackWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
      </BackWrapper>
      {loading && data === undefined ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : (
        <PageWrapper>
          <Container>
            <StatusContainer>{data && checkStatus()}</StatusContainer>
          </Container>
          <Container>
            <Header>
              <Time>
                {dictionary.orderID}: {order.id}
              </Time>
              <Time>{data && FormatTime(order.created_at)}</Time>
            </Header>
            <UserInfoWrapper>
              <AvatarWrapper>
                <img
                  height={90}
                  width={90}
                  style={{ borderRadius: 36 }}
                  src={order.user.avatar ? order.user.avatar : BlueHead}
                />
                <UserInfo>
                  <Name>{order.user.name}</Name>
                  <ContactInfo>{order.user.phone}</ContactInfo>
                  <ContactInfo>{order.user.email}</ContactInfo>
                </UserInfo>
              </AvatarWrapper>
            </UserInfoWrapper>
            <DetailsWrapper>
              <Name>
                {order.order_package.name} ({order.order_package.chinese_name})
              </Name>
              {!(order.order_package.big_offer || order.order_package.half) ? (
                <Quantity>
                  {order.quantity} x{" "}
                  {order.order_package.package_country.currency}{" "}
                  {order.selling_price} ={" "}
                  <Price>
                    {order.order_package.package_country.currency}{" "}
                    {CommaFormatted(order.quantity * order.selling_price)}
                  </Price>
                </Quantity>
              ) : (
                <Quantity>
                  {order.system_offer + order.quantity} x{" "}
                  {order.order_package.package_country.currency}{" "}
                  {order.selling_price} ={" "}
                  <Price>
                    {order.order_package.package_country.currency}{" "}
                    {CommaFormatted(
                      (order.system_offer + order.quantity) *
                        order.selling_price
                    )}
                  </Price>
                </Quantity>
              )}
            </DetailsWrapper>
          </Container>
          {!order.order_package.half && (
            <Container>
              <Header>
                <Title>{dictionary.payment}</Title>
                {checkPayment()}
              </Header>
              <DetailsWrapper>
                <Row>
                  {data.package_order[0].payment_slip ? (
                    <Right>
                      <Slip
                        src={data.package_order[0].payment_slip}
                        height={"auto"}
                        width={"100%"}
                        style={{
                          borderRadius: 12,
                        }}
                      />
                    </Right>
                  ) : (
                    <></>
                  )}
                </Row>
              </DetailsWrapper>
            </Container>
          )}
        </PageWrapper>
      )}
      {showWarning && (
        <Warning
          approveOrder={updateStatus}
          slip={data.package_order[0].payment_slip}
          close={() => {
            setShowWarning(false);
          }}
        />
      )}
    </Wrapper>
  );
};
