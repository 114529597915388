import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "./Images/logo512.png";

const Animation = keyframes`
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  animation: ${Animation} 1s linear infinite;
`;

export default () => {
  return (
    <Page>
      <Wrapper>
        <img src={logo} height={60} width={"auto"} />
      </Wrapper>
    </Page>
  );
};
