import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageStore } from "../context/LanguageStore";
import { languageOptions } from "./Languages";

const Wrapper = styled.select`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  background-color: ${(props) => props.theme.lightGreyColor};
  outline: none;
  border-style: none;
  padding: 6px;
  justify-content: space-around;
  align-items: center;
  max-width: 60px;
`;

const Container = styled.option`
  color: ${(props) => props.theme.darkGreyColor};
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => props.theme.lightGreyColor};
  border-radius: 12px;
  padding: 12px 24px;
  :hover {
    color: white;
    font-weight: 600;
    background-color: ${(props) => props.theme.subColor};
  }
`;
const Selected = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 24px;
  color: white;
  background-color: ${(props) => props.theme.subColor};
  border-radius: 12px;
  :hover {
    color: white;
    font-weight: 600;
  }
`;

export default ({}) => {
  const { language, setLanguage } = useContext(LanguageStore);

  return (
    <Wrapper
      onChange={(e) => {
        const selectedLanguage = languageOptions.find(
          (item) => item.id === e.target.value
        );
        setLanguage(selectedLanguage);
      }}
    >
      {languageOptions.map((lan, index) => {
        return <Container value={lan.id}>{lan.code}</Container>;
      })}
    </Wrapper>
  );
};
