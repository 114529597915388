import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import BlueHead from "./Images/BlueHead.png";
import { IoMdClose } from "react-icons/io";
import Button from "./Button";
import { LanguageStore } from "../context/LanguageStore";

const SlideUp = keyframes`
    0%{
        background-color: rgba(0,0,0,0);
    }
    100%{
        background-color: rgba(0,0,0,0.4);
    }
`;

const Up = keyframes`
  0%{
      transform: translate(0%, 100%);
  }
  100%{
    transform: translate(0%, 0%);
}
`;

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 60;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 100vh;
  max-width: 470px;
  display: flex;
  justify-content: center;
  animation: ${SlideUp} 0.4s linear;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
`;

const Container = styled.div`
  padding: 16px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  animation: ${Up} 0.4s ease-out;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 8px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.h1`
  ${(props) => props.theme.title}
  margin-top: 8px;
`;

const Subtext = styled.span`
  ${(props) => props.theme.title};
  font-weight: 400;
`;

const ContactText = styled.h1`
  ${(props) => props.theme.title}
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0px;
  line-height: 22px;
`;

const ContactSubtext = styled.span`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const AvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

const Rank = styled.p`
  ${(props) => props.theme.title};
  font-weight: 600;
  margin-top: 4px;
`;

const ContactInfo = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 12px 20px;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.lightGreyColor};
`;
const Touchable = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
export default ({ contact, close }) => {
  const { dictionary } = useContext(LanguageStore);
  return (
    <Wrapper>
      <Container>
        <Header>
          <Touchable>
            <IoMdClose onClick={close} size={24} />
          </Touchable>
        </Header>
        <AvatarWrapper>
          <Avatar src={(contact && contact.avatar) || BlueHead} />
          <Title>{contact && contact.name}</Title>
          <Rank>
            {(contact && contact.current_rank_name) ||
              (contact && contact.joining_rank_name)}
          </Rank>
        </AvatarWrapper>
        <ContactInfo>
          <ContactText>
            {dictionary.email}:{" "}
            <ContactSubtext>{contact && contact.email}</ContactSubtext>
          </ContactText>
          <ContactText>
            {dictionary.phoneNo}:{" "}
            <ContactSubtext>{contact && contact.phone} </ContactSubtext>
          </ContactText>
          {contact && contact.wechat && (
            <ContactText>
              {dictionary.weChat}:
              <ContactSubtext> {contact && contact.wechat}</ContactSubtext>
            </ContactText>
          )}
          {contact && contact.whatsapp && (
            <ContactText>
              {dictionary.wa}:
              <ContactSubtext>{contact.whatsapp}</ContactSubtext>
            </ContactText>
          )}
        </ContactInfo>
        <ButtonWrapper>
          {contact && (
            <Button
              label={dictionary.call + " " + contact.phone}
              onClick={() => {
                window.location.href = "tel:" + contact.phone;
              }}
            />
          )}
        </ButtonWrapper>
        {contact && (
          <ButtonWrapper>
            <Button
              label={dictionary.email + " " + contact && contact.email}
              onClick={() => {
                window.location.href = "mailto:" + contact && contact.email;
              }}
            />
          </ButtonWrapper>
        )}
        <ButtonWrapper>
          <Button
            label={dictionary.email + " " + contact.email}
            onClick={() => {
              window.location.href = "mailto:" + contact.email;
            }}
          />
        </ButtonWrapper>
        {contact.whatsapp && (
          <ButtonWrapper>
            <Button
              label={"Whatsapp " + contact.whatsapp}
              onClick={() => {
                window.open(
                  "https://wa.me/" + encodeURIComponent(contact.whatsapp)
                );
              }}
            />
          </ButtonWrapper>
        )}
      </Container>
    </Wrapper>
  );
};
