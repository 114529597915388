import React, { useState, useContext } from "react";
import styled from "styled-components";
import Back from "../components/Back";
import { BsQuestionCircle } from "react-icons/bs";
import Help from "../components/Help";
import { useQuery } from "@apollo/react-hooks";
import { GET_CASH } from "../query/Bonus";
import { auth } from "firebase";
import { CommaFormatted, FormatTime } from "../utilities";
import CashWithdrawal from "../components/CashWithdrawal";
import { LanguageStore } from "../context/LanguageStore";
import Loader from "../components/Loader";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-bottom: 12vh;
  overflow-y: auto;
`;
const Header = styled.div`
  background: linear-gradient(
    to left,
    ${(props) => props.theme.mainShade},
    ${(props) => props.theme.mainColor}
  );
  border-bottom-right-radius: 50%;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 50%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  align-items: center;
`;

const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
  padding-bottom: 0px;
`;

const Title = styled.div`
  color: white;
  font-weight: 800;
  font-size: 18px;
`;

const Touchable = styled.div`
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: transparent;
`;

const Figure = styled.h1`
  font-weight: 800;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: white;
`;

const Prefix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;
const Suffix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;

const Description = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: white;
  line-height: 18px;
  max-width: 70vw;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 12px;
`;
const SmallButton = styled.div`
  border-radius: 12px;
  background-color: white;
  padding: 6px 12px;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
  box-shadow: ${(props) => props.theme.boxShadow};
  cursor: pointer;
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow}
      ${(props) => props.theme.boxShadow};
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const RecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const CBRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const EmptyText = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: ${(props) => props.theme.darkBlueColor};
`;
const Date = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
`;
const RowName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;

const GreenText = styled.p`
  font-size: 0.8rem;
  color: ${(props) => props.theme.greenColor};
  font-weight: 600;
`;

const OrangeText = styled.p`
  font-size: 0.8rem;
  color: ${(props) => props.theme.orangeColor};
  font-weight: 600;
`;

export default ({ history }) => {
  const [showHelp, setShowHelp] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const { dictionary } = useContext(LanguageStore);
  const { data, loading, refetch, error } = useQuery(GET_CASH, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });

  const checkStatus = (status) => {
    switch (status) {
      case "Approved":
        return <GreenText>{status}</GreenText>;
      default:
        return <OrangeText>{status}</OrangeText>;
    }
  };
  return (
    <Wrapper>
      <Header>
        <BackWrapper>
          <Back
            color={"white"}
            onClick={() => {
              history.goBack();
            }}
          />
          <Title>{dictionary.cashW}</Title>
          <Touchable
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <BsQuestionCircle size={18} />
          </Touchable>
        </BackWrapper>
        <Figure>
          <Prefix>BV(RM) </Prefix>
          {(data &&
            data.cash_wallet_records_aggregate.aggregate.sum.amount !== null &&
            CommaFormatted(
              data.cash_wallet_records_aggregate.aggregate.sum.amount
            )) ||
            0}{" "}
          <Suffix></Suffix>
        </Figure>
        <Description>{dictionary.minimumBalance}</Description>
        <SmallButtonWrapper>
          <SmallButton
            onClick={() => {
              setShowRequest(true);
            }}
          >
            {dictionary.withdrawCash}
          </SmallButton>
        </SmallButtonWrapper>
      </Header>
      <RecordWrapper>
        {loading && <Loader />}
        {data &&
          (data.cash_wallet_records.length > 0 ? (
            data.cash_wallet_records.map((cash, index) => {
              return (
                <CBRow key={index}>
                  <Row>
                    <Date>{FormatTime(cash.created_at)}</Date>
                    <RowName>
                      {cash.type !== "transaction_fee" &&
                        checkStatus(cash.status)}
                    </RowName>
                  </Row>
                  <Row>
                    <RowName>{cash.details}</RowName>
                    <RowName>
                      {cash.amount > 0 && "+"}
                      {cash.amount ? CommaFormatted(cash.amount) : 0}
                    </RowName>
                  </Row>
                </CBRow>
              );
            })
          ) : (
            <EmptyText>{dictionary.noRecord}</EmptyText>
          ))}
      </RecordWrapper>
      {/* {showHelp && (
        <Help
          close={() => {
            setShowHelp(false);
          }}
          title={"Cash Back (CB)"}
          content={
            <p>
              CB(s) are rewarded when you purchase stocks as an agent.
              <br />
              <br />
              20CBs are rewarded for each unit you purchase. (1CB = RM1)
              <br />
              <br />
              You may use CB to shop from our mall!
              <br />
              (Coming soon)
            </p>
          }
        />
      )} */}
      {showRequest && (
        <CashWithdrawal
          refetch={refetch}
          secret={data && data.users_by_pk.secret_key}
          balance={
            (data && data.cash_wallet_records_aggregate.aggregate.sum.amount) ||
            0
          }
          close={() => {
            setShowRequest(false);
          }}
        />
      )}
    </Wrapper>
  );
};
