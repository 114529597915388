import React, { useState, useContext } from "react";
import styled from "styled-components";
import Back from "../components/Back";
import { BsQuestionCircle } from "react-icons/bs";
import Help from "../components/Help";
import { useQuery } from "@apollo/react-hooks";
import { GET_CB, GET_RB } from "../query/Bonus";
import { auth } from "firebase";
import { FormatTime, CommaFormatted } from "../utilities";
import Loader from "../components/Loader";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
const Header = styled.div`
  background: linear-gradient(
    to left,
    ${(props) => props.theme.subColor},
    ${(props) => props.theme.mainColor}
  );
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 120px;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: center;
`;

const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
  padding-bottom: 0px;
`;

const Title = styled.div`
  color: white;
  font-weight: 800;
  font-size: 18px;
`;

const Touchable = styled.div`
  color: white;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const Figure = styled.h1`
  font-weight: 800;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: white;
`;

const Prefix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;
const Suffix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;

const Description = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: white;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 20px;
`;
const SmallButton = styled.div`
  border-radius: 12px;
  background-color: white;
  padding: 6px 12px;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
  box-shadow: ${(props) => props.theme.boxShadow};
  cursor: pointer;
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow}
      ${(props) => props.theme.boxShadow};
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const RecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const CBRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const Date = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
`;
const RowName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;

const NewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const EmptyText = styled.h4`
  ${(props) => props.theme.title};
  text-align: center;
  font-size: 16px;
`;
export default ({ history }) => {
  const [showHelp, setShowHelp] = useState(false);

  const { dictionary } = useContext(LanguageStore);
  const { loading, data } = useQuery(GET_RB, {
    variables: {
      id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });
  return (
    <Wrapper>
      <Header>
        <BackWrapper>
          <Back
            color={"white"}
            onClick={() => {
              history.push("/profile");
            }}
          />
          <Title>{dictionary.RBVoucher}</Title>
          <Touchable
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <BsQuestionCircle size={18} />
          </Touchable>
        </BackWrapper>
        <Figure>
          <Prefix></Prefix>
          {((data &&
            data.check_rb_balance_aggregate.aggregate.sum.total_value !==
              null &&
            CommaFormatted(
              data.check_rb_balance_aggregate.aggregate.sum.total_value
            )) ||
            0) + " "}
          <Suffix>RB</Suffix>
        </Figure>
        <Description>{dictionary.RBDesc}</Description>
        <SmallButtonWrapper>
          <SmallButton
            onClick={() => {
              history.push("/rb_history");
            }}
          >
            {dictionary.redeemV}
          </SmallButton>
        </SmallButtonWrapper>
      </Header>

      <RecordWrapper>
        {loading && data === undefined && <Loader />}
        {data && data.check_rb_balance.length <= 0 && (
          <EmptyText>{dictionary.noRecord}</EmptyText>
        )}
        {data &&
          data.check_rb_balance.length > 0 &&
          data.check_rb_balance.map((cb, index) => {
            if (cb.quantity <= 0 && data.check_rb_balance.length < 2) {
              return <EmptyText>{dictionary.noRecord}</EmptyText>;
            }
            if (cb.quantity <= 0) {
              return;
            }
            return (
              <CBRow key={index}>
                <NewRow>
                  <Date>
                    {dictionary.value}: {(cb.unit_price * 0.3).toFixed(2)}
                  </Date>
                  <Date>
                    {dictionary.totalValue}:{" "}
                    {CommaFormatted(cb.unit_price * 0.3 * cb.quantity)}
                  </Date>
                </NewRow>
                <Row>
                  <RowName>
                    {cb.package_name + " (" + cb.package_chinese_name + ")"}
                  </RowName>
                  <RowName>x {cb.quantity}</RowName>
                </Row>
              </CBRow>
            );
          })}
      </RecordWrapper>
      {showHelp && (
        <Help
          close={() => {
            setShowHelp(false);
          }}
          title={dictionary.RBVoucher}
          content={
            <p>
              {dictionary.RBDesc2} <br />
              <br />
              {dictionary.RBDesc3}
              <br />
              <br />
              {dictionary.RBDesc4}
            </p>
          }
        />
      )}
    </Wrapper>
  );
};
