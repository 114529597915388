const MONTSERRAT = `'Montserrat', sans-serif;`;

export default {
  lightBlackColor: "#878787",
  blueColor: "#597dff",
  lightGreyColor: "#f1f3f6",
  darkGreyColor: "#C7C7C7",
  mainFont: MONTSERRAT,
  yellowColor: "#FEEA46",
  orangeColor: "#ffc31e",
  greenColor: "#26d681",
  darkBlueColor: "#393C4B",
  mainColor: "#657d9a",
  mainGreenColor: "#66DE62",
  greenColor: "#83C794",
  aquaColor: "#C5EDAC",
  shitGreen: "#2A4D20",
  mainShade: "#2c3553",
  subColor: "#2c3553",
  errorRedColor: "#ff6060",
  mangoColor: "#638761",
  errorDarkRedColor: "#DF2935",
  boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.1)",
  title: `
    font-family: ${MONTSERRAT};
    font-weight: 900;
    font-size: 18px;
    color: #393C4B;
  `,
};
