import React, { useState, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Intro from "./AccountSetup/Intro";
import PersonalInfo from "./AccountSetup/PersonalInfo";
import Delivery from "./AccountSetup/Delivery";
import logo from "../components/Images/logo512.png";
import ChoosePackages from "./AccountSetup/ChoosePackages";
import Bank from "./AccountSetup/Bank";
import Confirm from "./AccountSetup/Confirm";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBg from "../components/Images/ThemeBG.png";
import { GET_USERNAME, GET_USER_BY_ID } from "../query/Users";
import { auth } from "firebase";
import { Redirect } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import { BIND_ACCOUNT } from "../mutations/Users";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 40;
  background-color: white;
  position: relative;
`;

const PageBG = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 470px;
  height: 50vh;
  z-index: -1;
  transform: translate(-50%, 0%);
  background-color: ${(props) => props.theme.subColor};
`;
const TitleWrapper = styled.div`
  height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.mainColor};
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
  color: white;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
`;
const Container = styled.div`
  min-height: calc(100vh - 100px);
  width: 100%;
  background-color: white;
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const SlideUp = keyframes`
  0%{
    transform: translate(-50%, 1000%);
  }
  100%{
    transform: translate(-50%, -50%);
  }
`;

const FormWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  z-index: 100;
  width: 100%;
  flex-direction: column;
  max-width: 768px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem;
  border-radius: 0.8rem;
  box-shadow: ${(props) => props.theme.boxShadow};
  animation: ${SlideUp} 0.3s ease-out;
`;

const FormBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 98;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
`;

const FormTitle = styled.h1`
  ${(props) => props.theme.title}
  color: ${(props) => props.theme.darkBlueColor};
`;
const FormText = styled.div`
  padding: 1rem;
`;

export default () => {
  const [action, setAction] = useState(0);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [ic, setIc] = useState("");
  const [secret, setSecret] = useState("");
  const [country, setCountry] = useState("default");
  const [selectedPackage, setPackage] = useState("none");
  const [line, setLine] = useState("");
  const [city, setCity] = useState("");
  const [aState, setAState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccNo, setBankAccNo] = useState("");
  const [bankAccName, setBankAccName] = useState("");
  const { dictionary } = useContext(LanguageStore);

  const [isLoading, setIsLoading] = useState(false);
  const [bindAccounts] = useMutation(BIND_ACCOUNT);
  const [searchUsername, setSearchUsername] = useState("");
  const [uplineForm, setShowUplineForm] = useState(false);
  const { data, loading, error, refetch } = useQuery(GET_USER_BY_ID, {
    variables: {
      id: auth().currentUser.uid,
      username: searchUsername,
    },
  });

  useEffect(() => {
    if (!loading && data && data.relation && data.relation.length <= 0) {
      setShowUplineForm(true);
    } else if (!loading && data && data.relation && data.relation.length > 0) {
      setShowUplineForm(false);
    }
  }, [loading, error, data]);
  const checkAction = () => {
    switch (action) {
      case 0:
        return <Intro setAction={setAction} />;
      case 1:
        return (
          <ChoosePackages
            selectedPackage={selectedPackage}
            country={country}
            setCountry={setCountry}
            setPackage={setPackage}
            setAction={setAction}
          />
        );
      case 2:
        return (
          <PersonalInfo
            name={name}
            username={username}
            phone={phone}
            ic={ic}
            secret={secret}
            setAction={setAction}
            setName={setName}
            setUsername={setUsername}
            setPhone={setPhone}
            setIc={setIc}
            setSecret={setSecret}
          />
        );
      case 3:
        return (
          <Delivery
            setAction={setAction}
            lineOne={line}
            city={city}
            aState={aState}
            postcode={postcode}
            setLineOne={setLine}
            setCity={setCity}
            setAState={setAState}
            setPostcode={setPostcode}
          />
        );
      case 4:
        return (
          <Bank
            setAction={setAction}
            bankName={bankName}
            bankAccNo={bankAccNo}
            bankAccName={bankAccName}
            setBankName={setBankName}
            setBankAccNo={setBankAccNo}
            setBankAccName={setBankAccName}
          />
        );
      default:
        return (
          <Confirm
            action={action}
            setAction={setAction}
            selectedPackage={selectedPackage}
            country={country}
            username={username}
            name={name}
            ic={ic}
            phone={phone}
            secret={secret}
            lineOne={line}
            city={city}
            aState={aState}
            postcode={postcode}
            bankName={bankName}
            bankAccNo={bankAccNo}
            bankAccName={bankAccName}
          />
        );
    }
  };

  const CheckTitle = () => {
    switch (action) {
      case 0:
        return (
          <TitleWrapper>
            <Title>Welcome to E+!</Title>
            <Title>Let's set you up in 5-mins!</Title>
          </TitleWrapper>
        );
      case 1:
        return (
          <TitleWrapper>
            <Title>Step 1 of 4</Title>
            <Title>{dictionary.selectPackage}</Title>
          </TitleWrapper>
        );
      case 2:
        return (
          <TitleWrapper>
            <Title>Step 2 of 4:</Title>
            <Title>{dictionary.contactDetails}</Title>
          </TitleWrapper>
        );
      case 3:
        return (
          <TitleWrapper>
            <Title>Step 3 of 4:</Title>
            <Title>{dictionary.deliveryAddress}</Title>
          </TitleWrapper>
        );
      case 4:
        return (
          <TitleWrapper>
            <Title>Step 4 of 4:</Title>
            <Title>{dictionary.bankDetails}</Title>
          </TitleWrapper>
        );
      default:
        return (
          <TitleWrapper>
            <Title>Finally!</Title>
            <Title>Review your information</Title>
          </TitleWrapper>
        );
    }
  };

  if (loading && !data) {
    return <h1>Loading...</h1>;
  }

  if (data && data.users_by_pk && data.users_by_pk.id) {
    return <Redirect to="/" />;
  }
  return action === 0 ? (
    <Wrapper>
      <Intro setAction={setAction} />
    </Wrapper>
  ) : (
    <>
      <Wrapper>
        {CheckTitle()}
        <Container>{checkAction()}</Container>
        <PageBG />
      </Wrapper>
      {uplineForm && (
        <FormBackground>
          <FormWrapper>
            <FormTitle>
              An error occured while we were trying to create your account!
              Please enter your upline's username again:{" "}
            </FormTitle>
            <Input
              label="Username (Case sensitive)"
              value={searchUsername}
              placeholder="CaseSeNsiTiVe"
              onChange={(e) => {
                setSearchUsername(e.target.value);
              }}
            />
            <FormText>
              {searchUsername !== "" &&
                !loading &&
                data &&
                data.users &&
                (data.users.length > 0
                  ? "Upline: " + data.users[0].name
                  : "No user found!")}
            </FormText>
            <Button
              disabled={
                (data && data.users && data.users.length <= 0) || isLoading
              }
              label="Save"
              onClick={() => {
                setIsLoading(true);
                bindAccounts({
                  variables: {
                    upline: data.users[0].id,
                    downline: auth().currentUser.uid,
                  },
                })
                  .then(() => {
                    toast.success("Upline saved! Please proceed.");
                    refetch().then(() => {
                      setIsLoading(false);
                    });
                  })
                  .catch((e) => {
                    toast.error(e.message);
                    setIsLoading(false);
                  });
              }}
            />
          </FormWrapper>
        </FormBackground>
      )}
    </>
  );
};
