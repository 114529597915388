import React, { useState, useContext } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import Input from "./Input";
import Button from "./Button";
import { LanguageStore } from "../context/LanguageStore";
import { firebaseConfig } from "../utilities";
import firebase, { auth } from "firebase";
import { toast } from "react-toastify";

const Wrapper = styled.form`
  width: 100%;
  padding: 12px;
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;
export default ({ close }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { dictionary } = useContext(LanguageStore);
  const [loading, setLoading] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();
    if (newPassword === "") {
      return toast.error("Please enter a password");
    } else if (newPassword !== confirmPassword) {
      return toast.error("Confirm password must be same as new password!");
    }
    toast.warning("Saving changes...", { autoClose: false });
    setLoading(true);
    let checkAuthentication = firebase.initializeApp(
      firebaseConfig,
      "Check Password"
    );

    checkAuthentication
      .auth()
      .signInWithEmailAndPassword(auth().currentUser.email, oldPassword)
      .then((user) => {
        console.log(user);
        checkAuthentication
          .auth()
          .currentUser.updatePassword(newPassword)
          .then(() => {
            setLoading(false);
            toast.dismiss();
            close();
            toast.success("Password updated!", { autoClose: false });
          })
          .catch((e) => {
            console.log(e);
            toast.dismiss();
            setLoading(false);
            toast.error(e.message);
          });
      })
      .catch((e) => {
        toast.dismiss();
        setLoading(false);
        console.log(e);
        if (e.code === "auth/wrong-password") {
          toast.error("Old Password Incorrect!");
        } else {
          toast.error(e.message);
        }
      })
      .finally(() => {
        checkAuthentication.delete();
      });
  };
  return (
    <AnimatedContainer
      loading={loading}
      title={dictionary.changePassword}
      close={close}
      content={
        <Wrapper onSubmit={resetPassword}>
          <InputWrapper>
            <Input
              label={dictionary.oldPassword}
              value={oldPassword}
              placeholder="******"
              type="password"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.newPassword}
              value={newPassword}
              placeholder="******"
              type="password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={dictionary.confirmNewPassword}
              value={confirmPassword}
              placeholder="******"
              type="password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Button label="Change Password" onClick={resetPassword} />
          </InputWrapper>
        </Wrapper>
      }
    />
  );
};
