import React, { useState, useContext, useRef, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PageTitle from "../components/PageTitle";
import Back from "../components/Back";
import { CHECK_MMS } from "../query/Bonus";
import { auth, storage } from "firebase";
import ProductForm from "../components/ProductForm";
import Loader from "../components/Loader";
import { INSERT_RETAIL_ORDER, DELETE_RETAIL_ORDER } from "../mutations/Orders";
import { UPLOAD_RETAIL_ORDER_SLIP } from "../mutations/Orders";
import { toast } from "react-toastify";
import Input from "../components/Input";
import { BsQuestionCircle } from "react-icons/bs";
import Button from "../components/Button";
import { FormatTime, CommaFormatted } from "../utilities";
import ApprovedStatus from "../components/ApprovedStatus";
import WaitingApprovalStatus from "../components/WaitingApprovalStatus";
import PaymentPaid from "../components/PaymentPaid";
import PaymentPending from "../components/PaymentPending";
import ImageUploader from "../components/ImageUploader";
import Help from "../components/Help";
import TrackingInfo from "../components/TrackingInfo";
import { LanguageStore } from "../context/LanguageStore";
import ThemeBG from "../components/Images/ThemeBG.png";
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 70px;
  min-height: 100vh;
  padding-bottom: 230px;
  background-image: url(${ThemeBG});
  background-size: cover;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notification = styled.div`
  width: 100%;
  z-index: 80;
  max-width: 470px;
  background-color: ${(props) => props.theme.errorRedColor};
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 16px;
  position: sticky;
  top: 0px;
`;
const Warning = styled.div`
  width: 100%;
  z-index: 80;
  max-width: 470px;
  background-color: #ffe66d;
  color: #f17300;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 16px;
  position: sticky;
  top: 0px;
`;

const Success = styled.div`
  width: 100%;
  z-index: 80;
  max-width: 470px;
  background-color: ${(props) => props.theme.aquaColor};
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 16px;
  position: sticky;
  top: 0px;
  text-decoration: underline;
  cursor: pointer;
`;

const Summary = styled.div`
  padding: 12px 0px;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  ${(props) => props.theme.title};
`;
const Error = styled.p`
  color: ${(props) => props.theme.errorRedColor};
  font-size: 12px;
  margin-top: 6px;
`;

const Total = styled.h1`
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0px;
`;

const Price = styled.span`
  font-weight: 600;
  font-color: ${(props) => props.theme.darkBlueColor};
`;

const InputWrapper = styled.div`
  padding: 8px 0px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.lightBlackColor};
  font-size: 12px;
`;

const ProductInfo = styled.div`
  padding: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const ProductNameWrapper = styled.div`
  padding: 0xp 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const SectionBackground = styled.div`
  width: 100%;
  height: auto;
  padding: 12px;
  padding-bottom: 15vh;
`;

const Bank = styled.p`
  margin-top: 8px;
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 400;
`;

const BankDetail = styled.span`
  ${(props) => props.theme.title};
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
`;
const Quantity = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.lightBlackColor};
  line-height: 22px;
`;

const Section = styled.div`
  padding: 16px 20px;
  margin-bottom: 12px;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 0px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px;
`;

const Slip = styled.img`
  object-fit: contain;
`;
export default withTheme(({ history, theme }) => {
  let now = new Date();
  const scrollHeight = useRef();
  const [quantity, setQuantity] = useState();
  const [product, setProduct] = useState();
  const [address, setAddress] = useState("default");
  const [recipient, setRecipient] = useState("");
  const [phone, setPhone] = useState("");
  const [secret, setSecret] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [addressSet, setAddressSet] = useState({});
  const [shipping, setShipping] = useState();
  const [shippingSet, setShippingSet] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [change, setChange] = useState(false);
  const [updateSlip] = useMutation(UPLOAD_RETAIL_ORDER_SLIP);
  const [retailOrder] = useMutation(INSERT_RETAIL_ORDER);
  const [deleteOrder] = useMutation(DELETE_RETAIL_ORDER);
  const { data, loading, refetch } = useQuery(CHECK_MMS, {
    variables: {
      id: auth().currentUser.uid,
      month: now.getMonth() + 1,
    },
    fetchPolicy: "network-only",
  });

  const { dictionary } = useContext(LanguageStore);
  const submitOrder = (e) => {
    e.preventDefault();
    if (secret !== data.users_by_pk.secret_key) {
      return toast.error(dictionary.secondPassword);
    }
    toast.warning(dictionary.submittingOrder);
    let productsString = JSON.stringify(selectedProducts);
    retailOrder({
      variables: {
        uid: auth().currentUser.uid,
        quantity: selectedProducts.reduce((a, b) => {
          return a + b.quantity;
        }, 0),
        mms: true,
        address: addressSet.line,
        state: addressSet.state,
        city: addressSet.city,
        postcode: addressSet.postcode,
        recipient_name: recipient,
        phone: phone,
        shipping: shipping,
        shipping_rate:
          shippingSet.rate *
          selectedProducts.reduce((a, b) => {
            return a + b.quantity;
          }, 0),
        seller_id: data.users_by_pk.upline,
        multiproduct: productsString,
        unit_price:
          data.users_by_pk.user_country.country_packages[0].unit_price,
      },
    })
      .then(() => {
        toast.dismiss();
        toast.success(dictionary.orderPlaced);
        refetch();
      })
      .catch((e) => {
        toast.dismiss();
        toast.error(e.message);
      });
  };

  console.log(data);
  const checkPayment = () => {
    switch (data.mms_by_month[0].payment_slip) {
      case null:
        return <PaymentPending />;
      default:
        return <PaymentPaid />;
    }
  };

  const addProductToList = (pp) => {
    let products = selectedProducts;
    let productIndex = products.findIndex((a) => a.id === pp.id);
    if (productIndex !== -1) {
      products.splice(productIndex, 1);
    } else {
      products.push({
        id: pp.id,
        name: pp.name,
        img: pp.product_img,
        quantity: 1,
      });
    }
    setSelectedProducts(products);
    setChange(!change);
  };

  const changeProductQuantity = (id, quantity) => {
    if (parseInt(quantity) <= 0) {
      let productList = selectedProducts;
      productList.splice(
        productList.findIndex((pro) => pro.id === id),
        1
      );

      setSelectedProducts(productList);
      return setChange(!change);
    }
    setSelectedProducts(
      selectedProducts.map((pro) =>
        pro.id === id ? { ...pro, quantity: parseInt(quantity) } : pro
      )
    );
  };

  const submitSlip = (e) => {
    toast.warning(dictionary.uploadingSlip);
    let imgRef = storage().ref(
      "payment_slip/" +
        auth().currentUser.uid +
        "/mms_order/" +
        data.mms_by_month[0].order_id +
        "/slip.png"
    );
    let storingImg = imgRef.put(e.target.files[0]);
    storingImg.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast.dismiss();
        toast.error(error.message);
      },
      () => {
        storingImg.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          updateSlip({
            variables: {
              id: data.mms_by_month[0].order_id,
              url: downloadUrl,
            },
          })
            .then(() => {
              toast.dismiss();
              refetch();
              toast.success(dictionary.slipUpdated);
            })
            .catch((e) => {
              toast.dismiss();
              toast.error(e.message);
            });
        });
      }
    );
  };

  return (
    <Wrapper id="repeat-order-wrapper" ref={scrollHeight}>
      <PageTitle
        title={
          <p>
            <span
              onClick={() => {
                setShowHelp(true);
              }}
              style={{
                marginRight: 6,
                cursor: "pointer",
              }}
            >
              <BsQuestionCircle size={14} />
            </span>
            {dictionary.monthlyM}
          </p>
        }
      />
      {!loading && data && data.mms_by_month.length <= 0 && (
        <Notification>{dictionary.monthlyMalert}</Notification>
      )}
      {!loading &&
        data &&
        data.mms_by_month.length > 0 &&
        data.mms_by_month[0].status === "Pending" && (
          <Warning>{dictionary.mmsWarning}</Warning>
        )}
      {!loading &&
        data &&
        data.mms_by_month.length > 0 &&
        data.mms_by_month[0].status === "Approved" && (
          <Success
            onClick={() => {
              history.push("/mms_record");
            }}
          >
            {dictionary.mmsSuccess}
          </Success>
        )}
      <PageWrapper>
        <Back
          onClick={() => {
            history.goBack();
          }}
        />
        {loading && (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        )}

        {!loading && data && data.mms_by_month.length > 0 && (
          <SectionBackground>
            <Section>
              {data.mms_by_month[0].status === "Approved" && <ApprovedStatus />}
              {data.mms_by_month[0].status === "Pending" && (
                <WaitingApprovalStatus />
              )}
            </Section>
            <Section>
              <Header>
                <Time>
                  {dictionary.orderID}: {data.mms_by_month[0].order_id}
                </Time>
                <Time>{FormatTime(data.mms_by_month[0].created_at)}</Time>
              </Header>
              {data.mms_by_month[0].retail_order_product ? (
                <ProductInfo>
                  <img
                    src={data.mms_by_month[0].retail_order_product.product_img}
                    height={60}
                    width={60}
                    style={{ borderRadius: 24, marginRight: 16 }}
                  />
                  <ProductNameWrapper>
                    <Title>
                      {data.mms_by_month[0].retail_order_product.name}
                    </Title>
                    <Quantity>x{data.mms_by_month[0].quantity}</Quantity>
                  </ProductNameWrapper>
                </ProductInfo>
              ) : (
                JSON.parse(data.mms_by_month[0].multiproduct).map(
                  (pro, index) => (
                    <ProductInfo key={index}>
                      <img
                        src={pro.img}
                        height={60}
                        width={60}
                        style={{ borderRadius: 24, marginRight: 16 }}
                      />
                      <ProductNameWrapper>
                        <Title>{pro.name}</Title>
                        <Quantity>x{pro.quantity}</Quantity>
                      </ProductNameWrapper>
                    </ProductInfo>
                  )
                )
              )}
            </Section>
            <Section>
              <Summary>
                <Title>{dictionary.deliveryAddress}</Title>
                <Bank>
                  {dictionary.address}:{" "}
                  <BankDetail>{data.mms_by_month[0].address}</BankDetail>
                </Bank>
                <Header>
                  <Bank>
                    {dictionary.recipientName}:{" "}
                    <BankDetail>
                      {data.mms_by_month[0].recipient_name}
                    </BankDetail>
                  </Bank>
                  <Bank>
                    {dictionary.phoneNo}:{" "}
                    <BankDetail>{data.mms_by_month[0].phone}</BankDetail>
                  </Bank>
                </Header>
              </Summary>
            </Section>
            <Section>
              <Header>
                <Title>{dictionary.payment}</Title>
                {checkPayment()}
              </Header>
              <Row>
                <Left>
                  <BankDetail>{data.master[0].bank_acc_name}</BankDetail>
                  <Bank>
                    {dictionary.bankAccName}:{" "}
                    <BankDetail>{data.master[0].bank_acc_no}</BankDetail>
                  </Bank>
                  <Bank>
                    {dictionary.bankName}:{" "}
                    <BankDetail>{data.master[0].bank_name}</BankDetail>
                  </Bank>
                  <BankDetail>
                    {dictionary.totalAmount}:{" "}
                    {data &&
                      data.users_by_pk.user_country.currency +
                        " " +
                        CommaFormatted(
                          data.mms_by_month[0].quantity *
                            data.users_by_pk.user_country.country_packages[0]
                              .unit_price +
                            data.mms_by_month[0].shipping_rate
                        )}
                  </BankDetail>
                </Left>
                <Right>
                  {data.mms_by_month[0].payment_slip ? (
                    <Slip
                      src={data.mms_by_month[0].payment_slip}
                      height={60}
                      width={60}
                    />
                  ) : (
                    <></>
                  )}
                </Right>
              </Row>
              <ImageUploader
                placeholder={
                  data.mms_by_month[0].payment_slip
                    ? "Resubmit Payment Slip"
                    : "Upload Slip"
                }
                onChange={submitSlip}
              />
            </Section>
            <TrackingInfo
              tracking_id={!loading && data && data.mms_by_month[0].tracking_id}
              tracking_link={
                !loading && data && data.mms_by_month[0].tracking_link
              }
              courier={!loading && data && data.mms_by_month[0].courier}
              shipped={!loading && data && data.mms_by_month[0].shipped}
              shipped_at={!loading && data && data.mms_by_month[0].shipped_at}
            />
            {data && data.mms_by_month[0].status !== "Approved" && (
              <Section>
                <Header>
                  <Title>{dictionary.cancelOrder}</Title>
                </Header>
                <Left>
                  <BankDetail>{dictionary.mmsOrderMistake}</BankDetail>
                </Left>
                <InputWrapper>
                  <Button
                    label={dictionary.cancelOrder}
                    backgroundColor={theme.errorRedColor}
                    onClick={() => {
                      toast.warning("Deleting order...");
                      deleteOrder({
                        variables: {
                          order_id: data.mms_by_month[0].order_id,
                        },
                      }).then(() => {
                        setAddressSet({});
                        setQuantity("");
                        setProduct("");
                        setAddress("default");
                        setPhone("");
                        setRecipient("");
                        setShipping("");
                        setShippingSet("");
                        refetch().then(() => {
                          toast.dismiss();
                          toast.success("Order deleted!");
                        });
                      });
                    }}
                  />
                </InputWrapper>
              </Section>
            )}
          </SectionBackground>
        )}
        <SectionBackground>
          {!loading && data && data.mms_by_month.length <= 0 && (
            <Section>
              <ProductForm
                limit={false}
                changeProductQuantity={changeProductQuantity}
                addProductToList={addProductToList}
                addressSet={addressSet}
                setAddressSet={setAddressSet}
                quantity={quantity}
                setQuantity={setQuantity}
                selectedProduct={selectedProducts}
                setProduct={setSelectedProducts}
                address={address}
                setAddress={setAddress}
                phone={phone}
                setPhone={setPhone}
                recipient={recipient}
                setRecipient={setRecipient}
                shipping={shipping}
                setShipping={setShipping}
                shippingSet={shippingSet}
                setShippingSet={setShippingSet}
              />
            </Section>
          )}
          {!loading &&
            data &&
            data.mms_by_month.length <= 0 &&
            selectedProducts.length > 0 &&
            address !== "default" &&
            recipient !== "" &&
            phone !== "" &&
            !isNaN(shippingSet.rate) && (
              <Section>
                <Summary>
                  <Title>{dictionary.summary}</Title>
                  {selectedProducts.reduce((a, b) => {
                    return a + b.quantity;
                  }, 0) < 2 ? (
                    <Error>{dictionary.maintainSalesError}</Error>
                  ) : (
                    <>
                      <Total>
                        {dictionary.total}:{" "}
                        {"(" +
                          data.users_by_pk.user_country.currency +
                          " " +
                          data.users_by_pk.user_country.country_packages[0]
                            .unit_price +
                          " x " +
                          selectedProducts.reduce((a, b) => {
                            return a + b.quantity;
                          }, 0) +
                          ")" +
                          " + " +
                          data.users_by_pk.user_country.currency +
                          " " +
                          shippingSet.rate *
                            selectedProducts.reduce((a, b) => {
                              return a + b.quantity;
                            }, 0) +
                          " = "}
                        <Price>
                          {data.users_by_pk.user_country.currency}
                          {selectedProducts.reduce((a, b) => {
                            return a + b.quantity;
                          }, 0) *
                            data.users_by_pk.user_country.country_packages[0]
                              .unit_price +
                            shippingSet.rate *
                              selectedProducts.reduce((a, b) => {
                                return a + b.quantity;
                              }, 0)}
                        </Price>{" "}
                        <InputWrapper>
                          <Input
                            label={dictionary.secondPassword}
                            type="password"
                            placeholder="******"
                            value={secret}
                            onChange={(e) => {
                              setSecret(e.target.value);
                            }}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Button
                            label={dictionary.submitOrder}
                            onClick={submitOrder}
                          />
                        </InputWrapper>
                      </Total>
                    </>
                  )}
                </Summary>
              </Section>
            )}
        </SectionBackground>
      </PageWrapper>
      {showHelp && (
        <Help
          title={dictionary.monthlyM}
          close={() => {
            setShowHelp(false);
          }}
          content={
            <Summary>
              <Total>{dictionary.mmsTips}</Total>
            </Summary>
          }
        />
      )}
    </Wrapper>
  );
});
