import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageStore } from "../context/LanguageStore";
import { languageOptions } from "./Languages";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: space-around;
  align-items: center;
`;

const Container = styled.div`
  color: ${(props) => props.theme.darkGreyColor};
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => props.theme.lightGreyColor};
  border-radius: 12px;
  padding: 12px 24px;
  :hover {
    color: white;
    font-weight: 600;
    background-color: ${(props) => props.theme.subColor};
  }
`;
const Selected = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 24px;
  color: white;
  background-color: ${(props) => props.theme.subColor};
  border-radius: 12px;
  :hover {
    color: white;
    font-weight: 600;
  }
`;

export default ({}) => {
  const { language, setLanguage } = useContext(LanguageStore);

  return (
    <Wrapper>
      {languageOptions.map((lan, index) => {
        return lan.id === language.id ? (
          <Selected>{lan.text}</Selected>
        ) : (
          <Container
            onClick={() => {
              const selectedLanguage = languageOptions.find(
                (item) => item.id === lan.id
              );
              setLanguage(selectedLanguage);
            }}
          >
            {lan.text}
          </Container>
        );
      })}
    </Wrapper>
  );
};
