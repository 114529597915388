import React, { useState, useContext } from "react";
import styled from "styled-components";
import AnimatedContainer from "./AnimatedContainer";
import Input from "./Input";
import Button from "./Button";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/react-hooks";
import { WITHDRAW_CASH } from "../mutations/Users";
import { auth } from "firebase";
import { LanguageStore } from "../context/LanguageStore";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const InputWrapper = styled.div`
  padding-bottom: 12px;
`;
export default ({ balance, close, secret, refetch }) => {
  const [amount, setAmount] = useState("");
  const { dictionary } = useContext(LanguageStore);
  const [secretInput, setSecretInput] = useState("");
  const [withdrawCash] = useMutation(WITHDRAW_CASH);

  return (
    <AnimatedContainer
      title={dictionary.cashWithdrawal}
      close={close}
      content={
        <Wrapper>
          <InputWrapper>
            <Input
              label={dictionary.amount}
              value={amount}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  return;
                }
                setAmount(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              label={dictionary.secondPassword}
              value={secretInput}
              onChange={(e) => {
                setSecretInput(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Button
              label={dictionary.withdrawCash}
              onClick={() => {
                if (amount === "" || parseInt(amount) === 0) {
                  return toast.error(dictionary.emptyAmount);
                }
                if (balance - parseFloat(amount) < 0) {
                  return toast.error("Balance insufficient");
                }
                if (balance <= 0) {
                  return toast.error("Balance insufficient!");
                }
                if (secretInput !== secret) {
                  return toast.error(dictionary.secondPasswordIncorrec);
                }
                toast.warning(dictionary.makingRequest, { autoClose: false });
                withdrawCash({
                  variables: {
                    uid: auth().currentUser.uid,
                    amount: -amount,
                  },
                })
                  .then(() => {
                    toast.dismiss();
                    refetch();
                    close();
                    toast.success(dictionary.withdrawalRequestMade);
                  })
                  .catch((e) => {
                    toast.dismiss();
                    toast.error(
                      e.message === "database query error"
                        ? "Balance insufficient! You may have pending transactions."
                        : e.message
                    );
                  });
              }}
            />
          </InputWrapper>
        </Wrapper>
      }
    />
  );
};
