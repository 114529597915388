import React, { useState } from "react";
import styled from "styled-components";
import Back from "../components/Back";
import { BsQuestionCircle } from "react-icons/bs";
import Help from "../components/Help";
import { useQuery } from "@apollo/react-hooks";
import { GET_CB, GET_DRB_RECORD } from "../query/Bonus";
import { auth } from "firebase";
import Loader from "../components/Loader";
import { FormatTime } from "../utilities";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  background: linear-gradient(
    to left,
    ${(props) => props.theme.subColor},
    ${(props) => props.theme.mainColor}
  );
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 120px;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: center;
`;

const BackWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
  padding-bottom: 0px;
`;

const Title = styled.div`
  color: white;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
`;

const Touchable = styled.div`
  color: white;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const Figure = styled.h1`
  font-weight: 800;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: white;
`;

const Prefix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;
const Suffix = styled.span`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: white;
`;

const Description = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: white;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 20px;
`;
const SmallButton = styled.div`
  border-radius: 12px;
  background-color: white;
  padding: 6px 12px;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
  box-shadow: ${(props) => props.theme.boxShadow};
  cursor: pointer;
  :hover {
    box-shadow: ${(props) => props.theme.boxShadow}
      ${(props) => props.theme.boxShadow};
    background-color: ${(props) => props.theme.lightGreyColor};
  }
`;

const RecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const CBRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.lightGreyColor};
`;

const EmptyText = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
const Date = styled.div`
  color: ${(props) => props.theme.lightBlackColor};
  font-weight: 600;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  justify-content: space-between;
`;
const RowName = styled.h1`
  ${(props) => props.theme.title};
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;

const Bold = styled.span`
  font-weight: 800;
`;
export default ({ history }) => {
  const [showHelp, setShowHelp] = useState(false);

  const { loading, data } = useQuery(GET_DRB_RECORD, {
    variables: {
      user_id: auth().currentUser.uid,
    },
    fetchPolicy: "network-only",
  });

  console.log(data);
  return (
    <Wrapper>
      <Header>
        <BackWrapper>
          <Back
            color={"white"}
            onClick={() => {
              history.goBack();
            }}
          />
          <Title>My Daily Rebate Bonus (DRB)</Title>
          <Touchable
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <BsQuestionCircle size={18} />
          </Touchable>
        </BackWrapper>
        <Figure>
          <Prefix></Prefix>
          {((data && data.drb_record_aggregate.aggregate.sum.value) || 0) + " "}
          <Suffix>DRB</Suffix>
        </Figure>
        <Description>Get 0.1% rebate everyday!</Description>
        <SmallButtonWrapper>
          <SmallButton>Restock with DRB</SmallButton>
        </SmallButtonWrapper>
      </Header>
      <RecordWrapper>
        {loading && data === undefined && <Loader />}
        {!loading &&
          data &&
          data.drb_record.length > 0 &&
          data.drb_record.map((cb, index) => {
            return (
              <CBRow key={index}>
                <Date>{FormatTime(cb.created_at)}</Date>
                <Row>
                  <RowName>Daily Rebate</RowName>
                  <RowName>+ {cb.value}DRB</RowName>
                </Row>
              </CBRow>
            );
          })}
        {!loading && data && data.drb_record.length <= 0 && (
          <EmptyText>No record yet! Try again tomorrow.</EmptyText>
        )}
      </RecordWrapper>
      {showHelp && (
        <Help
          close={() => {
            setShowHelp(false);
          }}
          title={"Dail Rebate Bonus (DRB)"}
          content={
            loading ? (
              <Loader />
            ) : (
              <p>
                Earn 1% DRB everyday from the amount you paid for your package,
                and claim products with DRB!
                <br />
                <Bold>
                  &nbsp;&nbsp;Eligible rank:{" "}
                  {data &&
                    data.users_by_pk.user_country.country_packages &&
                    (data.users_by_pk.user_country.country_packages.length > 0
                      ? data.users_by_pk.user_country.country_packages.map(
                          (rank, index) => {
                            if (
                              data.users_by_pk.user_country.country_packages
                                .length -
                                1 ===
                              index
                            ) {
                              return (
                                rank.chinese_name + " (" + rank.name + ") "
                              );
                            } else {
                              return (
                                rank.chinese_name + " (" + rank.name + "), "
                              );
                            }
                          }
                        )
                      : "No available package yet.")}
                </Bold>
                <br />
                <br />
                {data.users_by_pk.user_country.country_packages.length > 0 && (
                  <>
                    <Bold>
                      Example -{" "}
                      {data.users_by_pk.user_country.country_packages[0].name} (
                      {
                        data.users_by_pk.user_country.country_packages[0]
                          .chinese_name
                      }
                      )
                    </Bold>
                    <br />
                    <br />
                    Earn 1% of {data.users_by_pk.user_country.currency}
                    {(data.users_by_pk.user_country.country_packages[0]
                      .system_offer +
                      data.users_by_pk.user_country.country_packages[0].min) *
                      data.users_by_pk.user_country.country_packages[0]
                        .unit_price}{" "}
                    (
                    {data.users_by_pk.user_country.country_packages[0]
                      .system_offer +
                      data.users_by_pk.user_country.country_packages[0]
                        .min}{" "}
                    x{" "}
                    {
                      data.users_by_pk.user_country.country_packages[0]
                        .unit_price
                    }
                    ) everyday:{" "}
                    {(data.users_by_pk.user_country.country_packages[0]
                      .system_offer +
                      data.users_by_pk.user_country.country_packages[0].min) *
                      data.users_by_pk.user_country.country_packages[0]
                        .unit_price *
                      0.001}
                    DRB/day
                    <br />
                    Use DRB to save 50% next time you restock!
                    <br />
                    <br />
                    e.g. {data.users_by_pk.user_country.currency}
                    {(data.users_by_pk.user_country.country_packages[0]
                      .system_offer +
                      data.users_by_pk.user_country.country_packages[0].min) *
                      data.users_by_pk.user_country.country_packages[0]
                        .unit_price}{" "}
                    x 50% = {data.users_by_pk.user_country.currency}
                    {((data.users_by_pk.user_country.country_packages[0]
                      .system_offer +
                      data.users_by_pk.user_country.country_packages[0].min) *
                      data.users_by_pk.user_country.country_packages[0]
                        .unit_price *
                      0) /
                      5}{" "}
                    for {data.users_by_pk.user_country.country_packages[0].name}{" "}
                    (
                    {
                      data.users_by_pk.user_country.country_packages[0]
                        .chinese_name
                    }
                    )
                  </>
                )}
              </p>
            )
          }
        />
      )}
    </Wrapper>
  );
};
