export var firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

export const CommaFormatted = (amount) => {
  var delimiter = ","; // replace comma if desired
  var a = amount.toFixed(2).split(".", 2);
  var d = a[1];
  var i = parseInt(a[0]);
  if (isNaN(i)) {
    return "";
  }
  var minus = "";
  if (i < 0) {
    minus = "-";
  }
  i = Math.abs(i);
  var n = new String(i);
  var a = [];
  while (n.length > 3) {
    var nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  if (d.length < 1) {
    amount = n;
  } else {
    amount = n + "." + d;
  }
  amount = minus + amount;
  return amount;
};

const checkMonth = (month) => {
  switch (parseInt(month)) {
    case 0:
      return "JAN";
    case 1:
      return "FEB";
    case 2:
      return "MAR";
    case 3:
      return "APR";
    case 4:
      return "MAY";
    case 5:
      return "JUN";
    case 6:
      return "JUL";
    case 7:
      return "AUG";
    case 8:
      return "SEP";
    case 9:
      return "OCT";
    case 10:
      return "NOV";
    default:
      return "DEC";
  }
};

const checkMins = (min) => {
  switch (parseInt(min)) {
    case 0:
      return "00";
    case 1:
      return "01";
    case 2:
      return "02";
    case 3:
      return "03";
    case 4:
      return "04";
    case 5:
      return "05";
    case 6:
      return "06";
    case 7:
      return "07";
    case 8:
      return "08";
    case 9:
      return "09";
    default:
      return min;
  }
};
const checkHours = (hours) => {
  switch (parseInt(hours)) {
    case 0:
      return "00";
    case 1:
      return "01";
    case 2:
      return "02";
    case 3:
      return "03";
    case 4:
      return "04";
    case 5:
      return "05";
    case 6:
      return "06";
    case 7:
      return "07";
    case 8:
      return "08";
    case 9:
      return "09";
    default:
      return hours;
  }
};

export const FormatTime = (timestamp) => {
  let time = new Date(timestamp);

  let date = time.getDate();
  let year = time.getFullYear();
  let month = checkMonth(time.getMonth());
  let hours = checkHours(time.getHours());
  let mins = checkMins(time.getMinutes());
  let secs = checkMins(time.getSeconds());
  return (
    date + " " + month + " " + year + " | " + hours + ":" + mins + ":" + secs
  );
};

export const checkFullMonth = (month) => {
  switch (parseInt(month)) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    default:
      return "December";
  }
};
