import React from "react";
import styled, { withTheme } from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { IoMdClose } from "react-icons/io";
import { GET_TERMS_POLICY } from "../query/Users";
import Loader from "./Loader";
import parse, { domToReact } from "html-react-parser";
import { withRouter } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;
const Container = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 100%;
  max-width: 430px;
`;
const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Content = styled.div`
  height: 60vh;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: auto;
  h1 {
    font-weight: 600;
    font-size: 18px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
  }
  padding: 20px;
`;
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h1`
  ${(props) => props.theme.title}
`;
const Touchable = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
export default withRouter(
  withTheme(({ theme, close, history }) => {
    const { data, loading } = useQuery(GET_TERMS_POLICY);

    const options = {
      replace: ({ attribs, children, name }) => {
        if (!attribs) return;

        if (name === "h1") {
          return (
            <div style={{ paddingLeft: 12, paddingRight: 12, width: "100%" }}>
              <h1 style={{ fontSize: 18 }}>{domToReact(children, options)}</h1>
            </div>
          );
        }
        if (name === "p" && children[0].name !== "img") {
          return (
            <div style={{ paddingLeft: 12, paddingRight: 12, width: "100%" }}>
              <p style={{ fontSize: 14 }}>{domToReact(children, options)}</p>
            </div>
          );
        }
        if (name === "iframe") {
          return (
            <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
              <iframe
                frameBorder={attribs.frameBorder}
                allowfullscreen
                src={attribs.src}
                style={{ width: "100%", height: "250px" }}
              >
                {domToReact(children, options)}
              </iframe>
            </div>
          );
        }
        if (name === "img") {
          return (
            <img
              width={450}
              style={{ maxWidth: 470, width: "100%" }}
              src={attribs.src}
            />
          );
        }
      },
    };
    return (
      <Wrapper>
        <Container>
          <Header>
            <Title>Privacy Policy</Title>
            <Touchable
              onClick={() => {
                if (close) {
                  close();
                } else {
                  history.push("/");
                }
              }}
            >
              <IoMdClose size={18} color={theme.lightBlackColor} />
            </Touchable>
          </Header>
          {loading ? (
            <Content>
              <LoadingWrapper>
                <Loader />
              </LoadingWrapper>
            </Content>
          ) : (
            <Content>
              {data && parse(data.master[0].privacy_policy, options)}
            </Content>
          )}
        </Container>
      </Wrapper>
    );
  })
);
